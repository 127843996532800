$navigation-desktop: (
    height: 4rem,
    active: white,
    hover: getColor(theme, light),
    background: getColor(theme),
    border: 0.1rem solid getColor(theme, dark),
    text: white,
    font-size: getSize(p),
    font-weight: getWeight(regular),
    shadow: elevation(1)
);

$navigation-mobile: (
    active: getColor(theme),
    hover: getColor(bg),
    background: white,
    border: 0.1rem solid getColor(border),
    text: getColor(text),
    font-size: getSize(p),
    font-weight: getWeight(regular)
);
