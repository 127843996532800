.opc-wrapper .fieldset.delivery-date .field {
    margin-top: 1rem;

    label,
    input {
        width: 100%;
    }

    .control {
        max-width: 315px;

        &::after {
            @include fa-icon();
            content: $fa-var-calendar;
            font-size: getSize(p);
            color: getColor(theme);
            position: absolute;
            right: 1rem;
            top: 1rem;
            pointer-events: none;
        }
    }
}
