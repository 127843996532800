//
//  Variables
//  _____________________________________________

$checkout-payment-method-title__border: $checkout-shipping-method__border !default;
$checkout-payment-method-title__padding: $checkout-shipping-method__padding !default;
$checkout-payment-method-title-mobile__padding: 15px !default;

$checkout-payment-method-content__padding__xl: 40px !default;

$checkout-billing-address-details__line-height: 27px !default;
$checkout-billing-address-details__padding: 0 0 0 23px !default;
$checkout-billing-address-form__max-width: $checkout-shipping-address__max-width !default;

//
//  Common
//  _____________________________________________

.checkout-payment-method {
    .step-title {
        border-bottom: 0;
        margin-bottom: 0;
    }

    .payment-method {
        @include lib-css(border-bottom, $checkout-payment-method-title__border);

        &._active {
            .payment-method-content {
                display: block;
            }
        }
    }

    .payment-method-title {
        @include lib-css(padding, $checkout-payment-method-title__padding 0);
        margin: 0;

        .payment-icon {
            display: inline-block;
            margin-right: $indent__xs;
            vertical-align: middle;
        }

        .action-help {
            display: inline-block;
            margin-left: $indent__xs;
        }
    }

    .payment-method-content {
        @include clearfix;
        display: none;
        @include lib-css(padding, 0 0 $indent__base $checkout-payment-method-content__padding__xl);

        .fieldset {
            &:not(:last-child) {
                margin: 0 0 $indent__base;
            }
        }

        .checkout-agreements-block {
            @media #{mq(lg)} {
                display: inline-block;
                margin-right: 10px;
            }
        }

        .actions-toolbar {
            @media #{mq(md)} {
                margin-left: 0 !important;
            }
            @media #{mq(lg)} {
                display: inline-block;
                max-width: 160px;
                vertical-align: text-top;
            }
        }
    }

    .payment-group {
        & + .payment-group {
            .step-title {
                margin: $indent__base 0 0;
            }
        }
    }

    .field-select-billing,
    .billing-address-form {
        @include lib-css(max-width, $checkout-billing-address-form__max-width);
    }

    .billing-address-same-as-shipping-block {
        margin: 0 0 $indent__s;
    }

    .checkout-billing-address {
        margin: 0 0 $indent__base;

        .primary {
            .action-update {
                margin-right: 0;
            }
        }

        .action-cancel {
            @extend .abs-action-button-as-link;
        }

        .billing-address-details {
            @include lib-css(line-height, $checkout-billing-address-details__line-height);
            @include lib-css(padding, $checkout-billing-address-details__padding);
        }
    }

    .payment-method-note {
        & + .checkout-billing-address {
            margin-top: $indent__base;
        }
    }

    .field-select-billing {
        > .label {
            @extend .abs-visually-hidden;
        }
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: $indent__base 0;
    }

    .payments {
        .legend {
            @extend .abs-visually-hidden;
        }
    }
}

@include max-screen($screen__m) {
    .checkout-payment-method {
        .payment-methods {
            @include lib-css(margin, 0 -($checkout-payment-method-title-mobile__padding));
        }

        .payment-method-title {
            @include lib-css(padding, $checkout-payment-method-title-mobile__padding)
        }

        .payment-method-content {
            @include lib-css(padding, 0 $checkout-payment-method-title-mobile__padding $indent__base);
        }

        .checkout-billing-address {
            .action-cancel {
                margin-top: $indent__s;
            }
        }
    }
}

.checkout-payment-method .billing-address-same-as-shipping-block {
    margin: 0 0 24px;
}

.checkout-payment-method .checkout-billing-address .billing-address-details {
    padding: 0;
    line-height: 14px;
}

#block-discount-heading {
    span {
        font-size: 15px;
        font-weight: bold;
    }
}

.payment-method > .payment-method-title {
    padding-top: 10px;
    padding-bottom: 10px;

    > label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 26px;
        padding-left: ((17px + (2px * 2)) + 21px);

        &::before,
        &::after {
            top: auto;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                top: 0;
            }
        }

        > img {
            max-height: 26px;
            margin-left: 10px;
            order: 9; // Last
        }

        > span {
            color: $color-theme;
            font-weight: 700;
        }
    }
}

.checkout-payment-method .checkout-billing-address {
    margin-bottom: 0 0 2rem;
}

.checkout-payment-method .payment-option-title .action-toggle:after {
    top: -3px;
    position: absolute;
    left: 95px;
}

.action-toggle {
    position: relative;
}

.payment-option-inner {
    font-size: 10px;
    font-weight: normal;
    color: $color-theme;
}

.payment-method {
    .fieldset {
        margin-top: 20px;
    }

    .actions-toolbar {
        float: right;
        margin-top: 2rem;

        .primary {
            right: 0;

            button {
                @media #{mq(md, max)}{
                    margin-top: 1rem !important;
                }
            }

            button:after {
                background: transparent !important;
            }
        }
    }

    .checkout-agreements-block {
        float: left;

        > div {
            width: 254px;
            padding-top: 1rem;
            padding-right: 1rem;
        }
    }
}

.field[name="billingAddress.order_reference"] {
    margin-bottom: 0 !important;
}

@media #{mq(md)}{
    .billing-address-form {
        #experius-postcode-fieldset {
            margin: 0 !important;
        }

        .field.postcode {
            margin-bottom: 2rem !important;
        }

        .field.firstname {
            width: 100% !important;

            > .control {
                max-width: 37.3rem !important;
            }
        }

        .field.prefix {
            display: flex;
            margin-left: 0 !important;

            .label {
                width: 16.7rem !important;
                margin-bottom: 0.5rem !important;
            }

            > .control {
                margin-top: 0.7rem !important;
                width: 100% !important;
                max-width: 373px !important;
                display: flex !important;
                flex-wrap: nowrap !important;
                justify-content: space-between !important;
            }
        }
    }
}
