footer.page-footer .footer.footnote {
    $margin: 2.5rem;
    padding: 1.5rem 0;
    background-color: #ffffff;
    color: getColor(text, light);
    font-size: getSize(p);

    ul,
    ol {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-right: $margin;
        text-align: center;

        @media #{mq(md, max)} {
            justify-content: space-between;
        }

        li {
            margin: 0 $margin 0 0;
        }

        li a {
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                text-decoration: underline;
            }
        }
        li a,
        li a:hover,
        li a:active,
        li a:visited,
        li a:focus {
            color: getColor(text, light);
            font-size: getSize(p);
            font-weight: normal;
        }

        @media #{mq(md, max)} {
            &,
            li:last-child {
                margin-right: 0;
            }
        }
    }

    .copyright-counter {
        margin-right: 3rem;

        @media #{mq(md, max)} {
            margin-bottom: 1rem;
            margin-right: 0;
        }

        b {
            font-weight: getWeight(semibold);
        }
    }
}
