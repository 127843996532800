.configurator-content-blocks {
    display: flex;
    width: 100%;
    padding-top: 5rem;
    z-index: 0;

    .content-block {
        display: block !important;
    }
}
