// Fylgja v0.2.0 (getfylgja.com)
// Licensed under MIT Open Source

$root-color: black !default;
$hero-height: 360px !default;
$hero-bg: rgba($root-color, .05) !default;
$hero-img: none !default;
$hero-color: inherit !default;

.hero {
    min-height: $hero-height;
    background: $hero-img $hero-bg center/cover no-repeat;
    color: $hero-color;
}
