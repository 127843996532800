%default-button-styling {
    min-width: 14rem;
    min-height: 4.8rem;
    max-width: 100%;
    font-weight: 700;
    border-radius: 2.4rem;
    padding: 1.3rem 3rem;
    text-align: center;
    transition: getTrans(background), getTrans(box-shadow);
    user-select: none;
    margin-right: 1rem;
    position: relative;
    white-space: nowrap;
    text-decoration: none;
}

a.button {
    appearance: none;

    &,
    &:visited,
    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }

    &:visited {
        @extend .button;
    }
}

.button {
    @extend button;
    @extend %default-button-styling;

    &,
    > span {
        @extend %button;
    }

    &.style-link {
        padding: 0;
        background: none;
        color: $color-link;
        font-size: inherit;
        text-decoration: underline;

        &:focus,
        &:hover {
            box-shadow: none;
            background: none;
            color: $color-link-focus;
            text-decoration: none;
        }
    }

    &[style-next="true"],
    &.style-next {
        &:not(.style-link)::after {
            @include fa-icon();
            content: $fa-var-caret-right;
            padding-left: 1.4rem;
        }

        &.style-down::after {
            transform: rotate(90deg);
            padding-right: 1rem;
        }
    }

    &.color {
        //Primary button
        &-cta {
            background-color: $color-cta;
            background-image: $gradient-cta;
            color: $color-on-cta;

            &:hover,
            &:focus {
                box-shadow: 0 .4rem .7rem rgba($color-cta, .5);
            }
        }

        //Secondary button
        &-accent {
            background-color: getColor(theme, base);
            background-image: none;
            color: getColor(theme, contrast);

            &:hover,
            &:focus {
                box-shadow: 0 .4rem .7rem rgba(getColor(theme, base), .5);
            }
        }

        //Tertiary button
        &-blank {
            background-color: getColor(gray, base);
            background-image: none;
            color: getColor(gray, dark);

            &:hover,
            &:focus {
                background-color: getColor(gray, light);
            }
        }

        //Special button
        &-cta-green {
            background-color: $color-secondary;
            background-image: getColor(gradient, secondary);
            color: $color-on-secondary;

            &:focus,
            &:hover {
                box-shadow: 0 .4rem .7rem rgba(getColor(secondary), .5);
            }
        }

        //Ghost button
        &-cta-white {
            background-color: white;
            background-image: none;
            color: getColor(theme, base);
            border: 1px solid getColor(theme, base) !important;

            &:focus,
            &:hover {
                background-color: rgba(getColor(theme, base), .15);
            }
        }

        //White button
        &-white {
            background-color: white;
            background-image: none;
            color: getColor(text, base);

            &:hover,
            &:focus {
                box-shadow: 0 .4rem .7rem rgba(getColor(theme, base), .5);
            }
        }

        &-link {
            background-color: transparent;
            color: #929292;
            padding: 0;
            text-decoration: underline;

            span {
                font-size: 1.4rem;
            }
        }
    }

    &[data-element="empty_link"] {
        @extend %h4;
        min-width: 0;
        margin: 0 1rem 0 0;
        padding: 0;
        vertical-align: initial;
        color: getColor(theme, base);
        background-color: transparent;
        appearance: none;

        &:focus,
        &:hover {
            background-color: #ffffff00;
            box-shadow: none;
        }
    }
}

.button.action.continue.primary,
.action.primary.checkout,
.action.primary {
    @extend .button;
    @extend .color-cta;
    @extend .style-next;

    &:hover,
    &:active {
        background-image: $gradient-cta;
    }
}

.action.primary,
a.action.primary {
    &,
    &:hover,
    &:focus,
    span {
        color: $color-on-cta;
    }
}

body a {
    @extend %a;
}
