//
//  Variables
//  _____________________________________________

$checkout-modal-popup__width: 800px !default;

.checkout-index-index {
    .modal-popup {
        .field-tooltip {
            .field-tooltip-content {
                @extend .abs-checkout-tooltip-content-position-top;
            }
        }
        .modal-inner-wrap {
            background-color: #fff;
        }
    }
}

.checkout-index-index {
    .modal-popup .modal-footer {
        display: flex;
        flex-direction: row-reverse;

        .action-hide-popup {
            margin: 0;
            align-self: center;
            color: getColor(theme, contrast);
            background-color: getColor(theme, base);
            height: 100%;
            padding: 1.3rem 0;
            border-radius: 3.4rem;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-index-index {
        .modal-popup {
            display: flex;
            align-items: center;
            .form-shipping-address {
                @include lib-css(max-width, $checkout-shipping-address__max-width);
            }

            .modal-footer {
                .action-save-address {
                    float: right;
                    margin: 0 0 0 $indent__s;
                }
            }
            .modal-inner-wrap {
                margin: auto;
            }
        }
    }
    html[class^="ie"] {
        .checkout-index-index {
            .modal-popup {
                display: block;

                .modal-inner-wrap {
                    margin: 5rem auto;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .checkout-index-index {
        .modal-popup {
            .modal-inner-wrap {
                @include lib-css(width, $checkout-modal-popup__width);
            }
        }
    }
}

