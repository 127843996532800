// Fylgja v0.2.0 (getfylgja.com)
// Licensed under MIT Open Source

// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

// 1) preventing text to be condensed
// See https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe

// 2) Fallback for Edge

@mixin sr-only($important: null) {
    @if ($important == !important) or ($important == '!') {
        $important: !important;
    }
    position: absolute $important;
    width: 1px $important;
    height: 1px $important;
    white-space: nowrap $important; // 1
    overflow: hidden $important;
    clip: rect(1px, 1px, 1px, 1px) $important; // 2
    clip-path: inset(50%) $important;
}

.sr-only {
    @include sr-only(!important);
}
