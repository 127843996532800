.multi-ship-modal {
    #multi-ship-modal {
        border-top: 1px solid rgb(244, 246, 248);
        width: 100%;
        padding-top: 8rem;
    }

    .modal-inner-wrap {
        min-height: 30px;
        height: 75.8rem;
        max-width: 102.7rem;
        border-radius: 1.5rem;
        background-color: #FFFFFF !important;
        padding: 1rem 0;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3.5rem 3.5rem 0 3.5rem;
    }

    .modal-content {
        min-height: 45vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem 3.5rem;
    }

    .upload-icon {
        margin-bottom: 1.5rem;
    }

    .multiship-cta-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 41.2rem;
        max-width: 100%;

        .upload-icon {
            width: 2.5rem;
        }

        .re-upload {
            margin-top: 1rem;
        }
    }

    .multiship-footer {
        &-default {
            border: 2px dashed getColor(border, menu);
            border-radius: 1rem;
            background-color: #F5F6F8;
            cursor: pointer;
            height: auto;
            width: 50%;

            .button {
                font-size: 1.4rem;
                line-height: 1;
                text-align: center;
                min-height: 3.2rem;
                box-sizing: border-box;
                height: 6rem;
                width: 21.4rem;
                border-radius: 2rem;
                background-color: #FFFFFF;
                border: 2px solid #3e4890 !important;
                color: #3e4890;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto
            }
        }

        &-error {
            width: 100%;
            justify-content: space-evenly;

            @media (min-width: 767px) {
                flex-direction: row;
            }
        }
    }

    .download-example {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: auto;
        width: 35rem;
        background-color: getColor(bg, base);
        padding: 2rem;
        border-radius: 1.3rem;
        max-width: 100%;

        a.title {
            display: block;
            text-align: left;
            color: $color-accent-dark;
            font-size: 1.6rem;
            line-height: 1.6rem;
            font-weight: 700;
            text-decoration: underline;
            position: relative;
            top: -.3rem;
            margin-bottom: 0.7rem;
        }

        p {
            color: getColor(gray, dark);
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 1.25;
            margin-bottom: 0;
        }
    }

    .modal-body-content {
        &-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &[aria-hidden="true"] {
                display: none;
            }
        }

        .title {
            color: $color-theme-dark;
            font-size: 2.5rem;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 2.8rem;
            text-align: center;
            margin-bottom: 2.5rem
        }

        .message {
            color: $color-theme-dark;
            font-size: 1.4rem;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 1.7rem;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 3.2rem;
            width: 60rem;
            max-width: 100%;
        }

        &-error {
            .message {
                text-align: left;

                * {
                    color: $color-danger;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    .modal-footer {
        display: none;
    }

    .multiship-upload-wrapper {
        display: flex;
        border: 1px solid #E4E4E4;
        padding: 2rem;
        border-radius: 1rem;
        width: calc(100% - 4rem);
    }

    .multiship-upload-details {
        width: 50%;

        &-title {
            text-align: left;
            color: #263550;
            font-size: 1.4rem;
            font-weight: bold;
        }

        &-item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }

            &-number {
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: center;
                justify-content: center;
                text-align: center;
                color: #fff;
                background-color: #3E4890;
                min-height: 3.5rem;
                min-width: 3.5rem;
                border-radius: 10rem;
                font-size: 1.5rem;
                font-weight: bold;
            }

            &-body {
                color: #263550;
                text-align: left;
                line-height: 1.5;
                font-size: 1.4rem;
                margin: 0;
                padding-left: 1rem;
                font-weight: bold;

                a {
                    color: #263550
                }
            }
        }
    }
}

.multiship-options {
    margin-top: -1.8rem;
    display: none;

    &.is-active {
        display: block;
    }

    .v-stepper {
        background-color: transparent;

        &__wrapper {
            .row {
                margin: .6rem 0 2rem;
            }

            .col {
                padding: .6rem
            }
        }
    }

    .tile-card {
        padding: 0 !important;
        text-align: left;

        &::before {
            display: none;
        }
    }

    .tile-title {
        padding: 0;

        h3 {
            padding: 1.5rem 1.3rem;
            border-radius: 1.3rem;
            border: 1px solid getColor(border, menu);
            width: 100%;
            cursor: pointer;
            font-size: 1.6rem;
            text-align: left;
            font-weight: 500;
            margin: 0;
        }
    }
}
