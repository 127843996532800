//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base !default;
$checkout-sidebar__margin__xl: 46px !default;
$checkout-sidebar__columns: 4 !default;

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-sidebar {
        @include lib-css(margin, $checkout-sidebar__margin__xl 0 $checkout-sidebar__margin);
        @include lib-layout-column(2, 2, $checkout-sidebar__columns);
    }
}

#opc-sidebar {
    .opc-block-summary .items-in-cart > .title::after {
        top: 10px;
    }

    tr,
    td,
    th {
        display: inline-block;
    }

    .shipping th.mark {
        padding: 0;
    }

    tr.totals.shipping.excl {
        border: none;
    }

    .minicart-items .product-item-details .price-including-tax,
    .minicart-items .product-item-details .price-excluding-tax {
        margin-top: 0;
    }

    #opc-sidebar .minicart-items li.product-item,
    .price-including-tax,
    .price-excluding-tax {
        line-height: 0px;
    }

    .minicart-items {
        li.product-item {
            line-height: 1px;
            padding: 0;
            border: none;
        }

        li.product-item:first-of-type {
            padding-top: 24px;
        }

        li.product-item:last-of-type {
            padding-bottom: 16px;
        }
    }

    .table-totals {
        line-height: 1.9;
    }

    .totals-tax-summary {
        display: flex;
        border: none;

        > * {
            border: none;

            &,
            .price {
                padding: 0;
            }
        }

        th {
            flex: 1 0;
        }

        td {
            text-align: right;
        }
    }

    .totals {
        th.mark,
        td.amount {
            padding: 0 0 0 0;
        }

        &.grand {
            margin-top: 2rem;
        }
    }

    .totals:first-of-type {
        th.mark,
        td.amount {
            padding: 20px 0 0 0;
        }
    }

    .totals-tax {
        th.mark,
        td.amount {
            padding: 0 0 20px 0;
        }
    }

    .totals-tax,
    .totals {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    tr.grand {
        border-top: 1px solid getColor(gray, 300);
        border-bottom: 1px solid getColor(gray, 300);

        th.mark,
        td.amount {
            padding: 20px 0 20px 0;
        }
    }

    .opc-block-shipping-information {
        margin: 0;
        padding: 2px 17px 16px 22px;
    }

    .ship-to {
        padding-bottom: 20px;
    }

    .shipping-information-title {
        padding: 0 0 20px 0;
    }
}
