.header-sub-navigation {
    display: none;
    align-items: center;
    align-self: center;
    position: relative;
    top: 1px;

    .account-cont {
        position: relative
    }

    .button {
        min-width: 6rem;

        &.style-link {
            min-height: auto;

            span {
                font-family: $family-body;
            }
        }
    }

    .pagebuilder-button-primary.button {
        line-height: 1;
    }

    & > div:first-child {
        margin-top: 0.2rem;

        a.pagebuilder-button-primary.button.style-link,
        [data-content-type="buttons"] [data-content-type="button-item"]:first-of-type  {
            margin-bottom: 0;
        }
    }

    @media #{mq(md, max)} {
        & > div:first-child {
            margin-top: 0;
        }
    }

    @media #{mq(lg, max)} {
        .button.color-cta-green {
            display: none;
        }

        .button.style-link {
            font-size: getSize(button);
            font-family: $family-body;
            font-weight: 400;
            width: 100% !important; // Overrides inline style
            min-height: auto;
            padding: 1rem 1.5rem !important; // Overrides inline style
            text-align: left !important; // Overrides inline style
            border-bottom-width: 1px !important; // Overrides inline style
            border-bottom-style: solid !important; // Overrides inline style
            border-bottom-color: $color-gray82 !important; // Overrides inline style
            border-radius: 0 !important; // Overrides inline style

            span {
                font-family: $family-body;
                font-weight: 400;
            }
        }

        a {
            font-size: getSize(button);
        }

        div[data-content-type="button-item"] {
            display: block !important; // Overrides inline style
        }
    }

    @media #{mq(lg, min)} {
        display: inline-flex;

        a.pagebuilder-button-primary:not(.color-cta-green) span,
        a[href*='/customer/account/login/'],
        a[data-toggle~=account-header-nav],
        a[data-toggle~=account-header-nav] + .dropdown-pane .menu li a {
            font-size: getSize(p);
            color: getColor(text, light);
        }
    }

    @media #{mq(lg, min)} and #{mq(xl, max)} {
        padding-right: 0;
    }

    a.style-link {
        text-decoration: none;

        &:active,
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

// Slide in menu
@media #{mq(lg, max)} {
    .navigation.flyout ~ .header-sub-navigation {
        display: block !important; // Overrides inline style
        padding: 0;

        div:not(.cta) > .button {
            font-size: 1.5rem;
            font-weight: getWeight(regular);
            padding: 0.8rem 4rem 0.8rem 1.5rem;
            text-align: left;
            color: $color-theme;
            border-bottom: 1px solid $color-gray82;
            border-radius: 0;

            &:last-child {
                border: none;
            }

            &:hover {
                box-shadow: none;
            }
        }

        .cta {
            margin-top: 2rem;
            padding: 0 1.5rem;
        }

        .cta .button {
            max-width: 480px;
            margin: auto;
        }
    }
}

/* Do not use display flex for sub navigation bar in IE 11, because it make elements jumps on hover */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .header-sub-navigation > div > div {
        display: inline-block !important;
    }
}
