// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
    max-width: 100%;
    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
    [data-element='empty_link'] {
        cursor: default;
    }
}

// Removed Page Builder button styling
