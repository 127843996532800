/**
 * Foundation for Sites by ZURB
 * Version 6.4.2
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 3px 3px 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 3px 3px; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #2ba6cb; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 3px 3px; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    top: 34px !important;
    left: 10px !important;
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    -ms-transform: translate(0, 0);
        transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    -ms-transform: translate(0, 0);
        transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  -ms-transform: translateX(-250px);
      transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    -ms-transform: translateX(-250px);
        transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      -ms-transform: translate(0, 0);
          transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    -ms-transform: translateX(250px);
        transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  -ms-transform: translateX(250px);
      transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    -ms-transform: translateX(250px);
        transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      -ms-transform: translate(0, 0);
          transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    -ms-transform: translateX(-250px);
        transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  -ms-transform: translateY(-250px);
      transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    -ms-transform: translateY(-250px);
        transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      -ms-transform: translate(0, 0);
          transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    -ms-transform: translateY(250px);
        transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  -ms-transform: translateY(250px);
      transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    -ms-transform: translateY(250px);
        transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      -ms-transform: translate(0, 0);
          transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    -ms-transform: translateY(-250px);
        transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  -ms-transform: none;
      transform: none;
  transition: transform 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    -ms-transform: translate(0, 0);
        transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    -ms-transform: translate(0, 0);
        transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    -ms-transform: none;
        transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      -ms-transform: none;
          transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    -ms-transform: none;
        transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      -ms-transform: none;
          transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    -ms-transform: none;
        transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      -ms-transform: none;
          transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    -ms-transform: none;
        transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      -ms-transform: none;
          transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    -ms-transform: none;
        transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      -ms-transform: none;
          transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    -ms-transform: none;
        transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      -ms-transform: none;
          transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    -ms-transform: none;
        transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      -ms-transform: none;
          transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    -ms-transform: none;
        transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      -ms-transform: none;
          transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      -ms-transform: none;
          transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      -ms-transform: none;
          transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-order: 1;
        order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #2ba6cb; }
  .tabs.primary > li > a {
    color: #0a0a0a; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #299ec1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #2ba6cb; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #258faf; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #2ba6cb; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 62.5rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 62.5rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 62.5rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 62.5rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  -ms-transform: translateY(0);
      transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  -ms-transform: translateX(0);
      transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(100%);
      transform: translateY(100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  -ms-transform: translateY(0);
      transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(100%);
      transform: translateX(100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  -ms-transform: translateX(0);
      transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(0);
      transform: translateY(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  -ms-transform: translateY(100%);
      transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(0);
      transform: translateX(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  -ms-transform: translateX(100%);
      transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(0);
      transform: translateY(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  -ms-transform: translateY(-100%);
      transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(0);
      transform: translateX(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  -ms-transform-origin: top;
      transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  -ms-transform-origin: right;
      transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  -ms-transform-origin: bottom;
      transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  -ms-transform-origin: left;
      transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  -ms-transform-origin: center;
      transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  -ms-transform-origin: center;
      transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: top;
      transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: right;
      transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: bottom;
      transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: left;
      transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: center;
      transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: center;
      transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(0.5);
      transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  -ms-transform: scale(1);
      transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1.5);
      transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  -ms-transform: scale(1);
      transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1);
      transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  -ms-transform: scale(1.5);
      transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1);
      transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  -ms-transform: scale(0.5);
      transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(-0.75turn);
      transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  -ms-transform: rotate(0);
      transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0);
      transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  -ms-transform: rotate(0.75turn);
      transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0.75turn);
      transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  -ms-transform: rotate(0);
      transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0);
      transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  -ms-transform: rotate(-0.75turn);
      transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }
