// Code based on:
// - Fylgja container Package        | getfylgja.com
// - Bootstrap4 container var values | getbootstrap.com

$enable-container-box-sizing: false !default;

.container {
    &,
    &-fluid {
        @if $enable-container-box-sizing {
            box-sizing: border-box;
        }
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
    // Check if responsive gaps are needed
    @each $mq, $value in map-get($config-container, gutters) {
        @if map-has-key($breakpoints, $mq) {
            @if map-get($breakpoints, $mq) != 0 {
                @media (min-width: map-get($breakpoints, $mq)) {
                    padding-right: $value;
                    padding-left: $value;
                }
            } @else {
                padding-right: $value;
                padding-left: $value;
            }
        }
    }
    // Build Resposive breakpoints for the container
    @each $mq, $value in map-get($config-container, max-widths) {
        @if map-has-key($breakpoints, $mq) {
            @if ($value == null)
            or ($value == 0)
            or ($value == 100%) {
                // Do nothing
            } @else {
                @media (min-width: map-get($breakpoints, $mq)) {
                    max-width: $value;
                }
            }
        }
    }
}
