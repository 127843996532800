.product-card {
    height: 100%;

    @media #{mq(md)} {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__image {
        width: 100%;
        margin-bottom: 3.5rem;

        @media #{mq(md)} {
            flex: 0 1 auto;
        }
    }

    &__content {
        flex: none;

        @media #{mq(md)} {
            flex: 1 0 auto;
        }

        h3 {
            margin: 0 0 2.5rem;
        }
    }

    &__description {
        > * {
            margin: 0 0 2.5rem;
        }

        ul {
            padding-left: 1.8rem;
        }

        ul li,
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__actions {
        flex: none;

        @media #{mq(md)} {
            flex: 0 1 auto;
            justify-self: flex-end;
        }

        .button {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .button:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}
