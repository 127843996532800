@mixin contact-button() {
    background-color: $color-cta;
    background-image: $gradient-cta;
    color: $color-on-cta;

    &:hover,
    &:focus {
        box-shadow: 0 .4rem .7rem rgba($color-cta, .5);
    }
}

.contact button.action.primary {
    @include contact-button;
    width: 22.4rem;
    font-weight: 700;
}
