// Part of header styles
.page-header .sticky {
    background-color: #fff;
    transform: none;
    &.is-stuck {
        box-shadow: 0 0 3px rgba(black, .3);
    }
}

// unset styles to keep magento off-canvas intact

