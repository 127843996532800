@import url('https://fonts.googleapis.com/css?family=Architects+Daughter|Muli:400,700|Quicksand:700&display=swap');

$font: (
    family: (
        heading: ('Quicksand', sans-serif),
        body: ('Muli', sans-serif),
        special: ('Architects Daughter', cursive)
    ),
    size: (
        h1: 4.1rem,
        h2: 3.2rem,
        h3: 2.5rem,
        h4: 2.2rem,
        h5: 1.9rem,
        h6: 1.7rem,
        p: 1.6rem,
        p-small: 1.4rem,
        a: 1.6rem,
        small: 1.2rem,
        button: 1.6rem,
        label: 1.7rem
    ),
    line-height: (
        h1: 4.5rem,
        h2: 3.7rem,
        h3: 2.8rem,
        h4: 2.5rem,
        h5: 2.6rem,
        h6: 2.6rem,
        p: 2.6rem,
        p-small: 1.7rem,
        a: 2.6rem,
        small: 1.5rem,
        button: normal,
        label: 2.6rem
    ),
    margin: (
        h1: 1.5rem,
        h2: 1.5rem,
        h3: 1.5rem,
        h4: 1.5rem,
        h5: 1.5rem,
        h6: 1.5rem,
        p: 1.5rem,
        p-small: 1.5rem,
        a: 1.5rem,
        small: 1.5rem,
        button: 0,
        label: 1rem
    ),
    weight: (
        regular: 400,
        bold: 700
    )
) !default;

$family-heading: map-get(map-get($font, family), heading);
$family-body: map-get(map-get($font, family), body);
$family-cta: map-get(map-get($font, family), cta);
$family-special: map-get(map-get($font, family), special);

%h1 {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), h1);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), h1);
    color: getColor(theme, base);
    margin: 0 0 map-get(map-get($font, margin), h1);
}

%h2 {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), h2);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), h2);
    color: getColor(theme, base);
    margin: 0 0 map-get(map-get($font, margin), h2);
}

%h3 {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), h3);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), h3);
    color: getColor(theme, base);
    margin: 0 0 map-get(map-get($font, margin), h3);
}

%h4 {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), h4);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), h4);
    color: getColor(theme, base);
    margin: 0 0 map-get(map-get($font, margin), h4);
}

%h5 {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), h5);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), h5);
    color: getColor(theme, base);
    margin: 0 0 map-get(map-get($font, margin), h5);
}

%h6 {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), h6);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), h6);
    color: getColor(theme, base);
    margin: 0 0 map-get(map-get($font, margin), h6);
}

%label {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), label);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), label);
    color: getColor(theme, base);
}

%p {
    font-family: map-get(map-get($font, family), body);
    font-size: map-get(map-get($font, size), p);
    font-weight: map-get(map-get($font, weight), regular);
    line-height: map-get(map-get($font, line-height), p);
    color: getColor(gray, dark);
    margin: 0 0 map-get(map-get($font, margin), p);
}

%p-small {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), p-small);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), p-small);
    color: getColor(theme, base);
    margin: 0 0 map-get(map-get($font, margin), p-small);
}

%a {
    font-family: map-get(map-get($font, family), a);
    font-size: map-get(map-get($font, size), a);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), a);
    color: getColor(gray, dark);
    text-decoration: underline;

    &:hover,
    &:active {
        color: getColor(text, dark);
    }

    p &,
    div &,
    span & {
        font-size: inherit;
    }
}

%button {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), button);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), button);
    text-decoration: none;
    margin: 0 0 map-get(map-get($font, margin), button);
}

%small {
    font-family: map-get(map-get($font, family), heading);
    font-size: map-get(map-get($font, size), small);
    font-weight: map-get(map-get($font, weight), bold);
    line-height: map-get(map-get($font, line-height), small);
    color: getColor(accent, dark);
    text-decoration: none;
}

.seo-text,
.seo-text * {
    color: getColor(text, dark) !important;
}
