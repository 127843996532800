html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    max-width: 100%;
    background-color: getColor(body);
    font-size: getSize(small);
    // overflow protection for breakout's
    overflow-x: hidden;
    // Set headers
    @for $i from 1 to 7 {
        h#{$i} {
            @extend %h#{$i};
        }
    }

    p,
    li {
        @extend %p;
    }

    label {
        @extend %label;
    }

    small,
    p > small {
        @extend %small;
    }

    label {
        @extend %label;
    }
}

.columns .column.main {
    padding-bottom: 10px;
}

svg:not([fill]) {
    // Allows inline svg's to inherit the text color if no fill is present
    fill: currentColor;
}

.item-spacer {
    margin-bottom: 1rem;
}

@media #{mq(sm, max)} {
    .cms-home.cms-index-index .page-main.container .column.main > [data-content-type="row"][data-appearance="contained"][data-element="main"]:nth-child(4) {
        margin-top: 0 !important;
    }

    h1, h1 span {
        font-size: 28px !important;
    }
}

.custom-from-label {
    font-size: 1.5rem;
}

.custom-font-size h2 {
    font-size: 22px;
}

.cookie-status-message {
    display: none;
}
