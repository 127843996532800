// Fylgja v0.3.0 (getfylgja.com)
// Licensed under MIT Open Source

/// Remove the unit of a length
/// @link https://css-tricks.com/snippets/sass/strip-unit-function/
/// @param {Number} $number - Number with unit
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
