.seo-text {
    margin-top: 1rem;

    @media #{mq(lg)} {
        margin-top: 4rem;
    }

    .title {
        margin-top: 0;
        margin-bottom: 2.5rem;
        color: $color-theme;
    }

    .description p {
        font-weight: initial;
    }
}
