// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Collage left appearance styles
//  _____________________________________________

[data-appearance='collage-left'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}
