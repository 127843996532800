// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type="buttons"] {
    max-width: 100%;

    @media #{mq(md, max)} {
        [data-content-type="button-item"]:first-of-type {
            margin-bottom: 1rem;
        }
    }
}
