.page-layout-expivi {
    @media (max-width: 767px) {
        .header.sticky.is-stuck {
            position: relative;
            box-shadow: none;
        }
    }

    .page-title {
        margin-bottom: 0;
    }

    .page-switcher {
        text-align: right;

        @media (max-width:991px) {
            text-align: center;
        }

        .switcher-url {
            padding-right: 1.5rem;
            padding-bottom: 1.2rem;
            font-size: 1.6rem;

            @media screen and (max-width:1024px) {
                margin-top: 14rem;
                font-size: 1.4rem;
            }

            @media screen and (max-width:768px) {
                margin-top: 4rem;
                padding-right: 0;
            }
        }
    }

    .xpv-tooltip {
        .tooltip-inner {
            background-color: $color-theme;
            box-shadow: none !important;
            border: 0;

            small {
                color: $color-on-theme;

                span {
                    display: none;
                }
            }
        }
    }

    .selector-options-container {
        border: 1px solid getColor(border, menu);
        border-radius: 1.3rem;
        margin-top: .6rem;

        svg {
            display: none;
        }

        .list-items {
            min-height: 4.8rem;

            .v-list-item__title {
                color: $color-text
            }

            &.active {
                background-color: getColor(bg, base);

                .v-list-item__title {
                    color: $color-theme;
                    font-weight: bold;
                }
            }
        }
    }

    .product-info-main {
        min-width: 50%;
        transition: margin-top .3s;
        margin-top: 0;

        &.has-active-sticky:not(.has-collapsed-viewer) {
            @media (max-width: 768px) {
                margin-top: 9rem;
            }
        }
    }

    @media (min-width: 768px) {
        .product.media {
            width: 50%;
        }
    }

    #viewer-container {
        height: 40rem !important;
        z-index: 4;

        .interact-icon-360-container {
            z-index: 5;
        }

        &.is-sticky canvas {
            @media (max-width: 768px) {
                position: fixed !important;
                top: 0;
                right: 0;
                left: 0;
                transform: translateY(-100%);
                transition: transform .3s;
                width: 100% !important;
                opacity: 0;
            }
        }

        &.is-collapsed canvas {
            @media (max-width: 768px) {
                opacity: 1;
            }
        }

        &.is-active {
            z-index: 5;

            @media (max-width: 768px) {
                canvas {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }

        @media (max-width: 768px) {
            height: 30rem !important;
        }

        canvas {
            position: absolute;
            display: unset;
            opacity: 1;
            z-index: 5;
        }

        .xpv__viewer_toolbar_container {
            display: none;
        }

        .interact-icon-360-container {
            z-index: 5;
            .animation-wrapper .wobble-hor-bottom.no-animation {
                animation: unset !important
            }
        }
    }

    #expivi-form {
        * {
            box-sizing: border-box !important;
            font-family: $family-body;
            letter-spacing: normal !important;
        }

        @mixin input-field-styling {
            border: 1px solid getColor(border, menu) !important;
            border-radius: .6rem !important;
            font-size: 1.6rem;
            padding: .5rem 1rem !important;
            max-height: 4.4rem;
            min-height: 4.4rem;
        }

        @mixin label-styling {
            all: unset !important;
            display: block !important;
            font-size: 1.4rem !important;
            color: $color-theme !important;
            margin-bottom: .4rem !important;
            font-weight: bold !important;
        }

        .v {
            &-input__slot {
                &::before, &::after {
                    display: none;
                }
            }

            &-text-field__slot {
                display: block;

                & > input {
                    @include input-field-styling;
                }
            }

            &-slider {
                &__track-container {
                    height: .6rem;
                }

                &__thumb {
                    width: 2.2rem;
                    height: 2.2rem;
                    border: 2px solid $color-on-theme;
                    cursor: grab;

                    &::after, &::before {
                        display: none;
                    }
                }

                &__track {
                    &-background, &-fill {
                        border-radius: 10rem;
                    }
                }
            }

            &-tabs {
                .v-tab {
                    font-size: 1.4rem;
                    color: #929292;
                    font-weight: bold;

                    &::before {
                        background-color: $color-theme;
                    }

                    &--active {
                        color: $color-theme;
                    }
                }
            }

            &-window {
                overflow: visible !important;
                position: relative;
                z-index: 1;

                &::before, &::after {
                    display: none;
                }

                .v {
                    &-menu {
                        &__content {
                            top: 6.2rem !important;
                            max-height: 25.6rem !important;
                            min-width: 100% !important;
                            overflow-x: hidden;
                            border: 2px solid getColor(border, menu);
                            border-radius: 1.3rem;
                            box-shadow: none;

                            .v-list {
                                padding: 0;

                                &-item {
                                    background-color: $color-on-theme;
                                }
                            }
                        }
                    }

                    &-input {
                        &__control {
                            & > div[role="button"] {
                                @include input-field-styling;

                                &::before, &::after {
                                    display: none;
                                }
                            }
                        }

                        &__prepend, &__append {
                            &-outer {
                                span {
                                    font-size: 1.4rem;
                                    color: $color-theme;
                                    font-weight: bold;
                                }
                            }
                        }
                    }

                    &-list-item--active {
                        background-color: getColor(bg, base);

                        span {
                            color: $color-theme;
                            font-weight: bold;
                        }
                    }
                }
            }

            &-application {
                a {
                    color: $color-theme;
                }

                .primary {
                    background-color: $color-theme !important;
                    border-color: $color-theme !important;


                    &--text {
                        color: $color-theme !important;
                        caret-color: $color-theme !important;
                    }

                    &.lighten-5 {
                        background-color: getColor(bg, base) !important;
                        border-color: getColor(bg, base) !important;
                    }

                    &--text.text--lighten-5 {
                        color: getColor(bg, base) !important;
                        caret-color: getColor(bg, base) !important;
                    }

                    &.lighten-4 {
                        background-color: getColor(bg, base) !important;
                        border-color: getColor(bg, base) !important;
                    }

                    &--text.text--lighten-4 {
                        color: getColor(bg, base) !important;
                        caret-color: getColor(bg, base) !important;
                    }

                    &.lighten-3 {
                        background-color: getColor(bg, base) !important;
                        border-color: getColor(bg, base) !important;
                    }

                    &--text.text--lighten-3 {
                        color: getColor(bg, base) !important;
                        caret-color: getColor(bg, base) !important;
                    }

                    &.lighten-2 {
                        background-color: $color-theme !important;
                        border-color: $color-theme !important;
                    }

                    &--text.text--lighten-2 {
                        color: $color-theme !important;
                        caret-color: $color-theme !important;
                    }

                    &.lighten-1 {
                        background-color: $color-theme-dark !important;
                        border-color: $color-theme-dark !important;
                    }

                    &--text.text--lighten-1 {
                        color: $color-theme-dark !important;
                        caret-color: $color-theme-dark !important;
                    }

                    &.darken-1 {
                        background-color: $color-theme-dark !important;
                        border-color: $color-theme-dark !important;
                    }

                    &--text.text--darken-1 {
                        color: $color-theme-dark !important;
                        caret-color: $color-theme-dark !important;
                    }

                    &.darken-2 {
                        background-color: $color-theme-dark !important;
                        border-color: $color-theme-dark !important;
                    }

                    &--text.text--darken-2 {
                        color: $color-theme-dark !important;
                        caret-color: $color-theme-dark !important;
                    }

                    &.darken-3 {
                        background-color: $color-theme-dark !important;
                        border-color: $color-theme-dark !important;
                    }

                    &--text.text--darken-3 {
                        color: $color-theme-dark !important;
                        caret-color: $color-theme-dark !important;
                    }

                    &.darken-4 {
                        background-color: $color-theme-dark !important;
                        border-color: $color-theme-dark !important;
                    }

                    &--text.text--darken-4 {
                        color: $color-theme-dark !important;
                        caret-color: $color-theme-dark !important;
                    }
                }
            }
        }

        .theme--light.v-application {
            background-color: transparent;
        }

        .v-stepper {
            padding: 1rem;

            & > .container {
                margin-bottom: 1.6rem !important;
                border-radius: 1rem;
                border: 1px solid getColor(border, menu);
                box-shadow: 0 11px 26px rgba(199, 204, 206, .3), 0 10px 11px rgba(0, 0, 10, .07);
                background-color: $color-on-theme;

                label {
                    @include label-styling;
                }
            }

            //Amount styling
            & > .container[data-xpv-key="stap-waarde-aantal"] {
                .v {
                    &-stepper__wrapper {
                        position: relative;

                        & > .container {
                            &:first-child {
                                & > .container {
                                    //Cards styling
                                    &:first-child {
                                        .v-label {
                                            display: none !important;
                                        }

                                        .container {
                                            & > .row {
                                                & > .col {
                                                    flex: calc(100% / 4);

                                                    // FIXME TODO after keys are working, this needs to be updated
                                                    & div[data-xpv-key="meest-gekozen"],
                                                    &:nth-child(4) {
                                                        .tile-title {
                                                            &::after {
                                                                content: 'Meest gekozen';
                                                                position: absolute;
                                                                bottom: .4rem;
                                                                left: 0;
                                                                right: 0;
                                                                margin: auto;
                                                                color: $color-accent-dark;
                                                                margin-top: .6rem;
                                                                font-size: 1.2rem;
                                                                font-weight: 500;
                                                            }
                                                        }
                                                    }

                                                    @media (max-width: 900px) {
                                                        flex: calc(100% / 2);
                                                    }

                                                    .v-ripple__container {
                                                        display: none;
                                                    }

                                                    .tile-card {
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        min-width: 100%;
                                                        min-height: 7.4rem;
                                                        border-radius: 1.3rem;
                                                        border: 1px solid getColor(border, menu);
                                                        background-color: $color-on-theme;
                                                        overflow: hidden;
                                                        box-shadow: none;
                                                        transition: ease-in-out .2s;

                                                        &:hover {
                                                            box-shadow: none;
                                                            background-color: getColor(gray, light);
                                                        }

                                                        .active-tile {
                                                            all: unset;
                                                            position: absolute;
                                                            top: 0;
                                                            left: 0;
                                                            height: 100%;
                                                            width: 100%;
                                                            background-color: getColor(gray, light);
                                                        }

                                                        .tile-title {
                                                            color: $color-theme;
                                                            font-size: 2rem;
                                                            white-space: nowrap;
                                                            z-index: 1;
                                                            font-weight: bold;
                                                        }
                                                    }

                                                    // FIXME TODO after keys are working, this needs to be updated
                                                    & div[data-xpv-key="eigen-bedrag-trigger"],
                                                    &:last-of-type {
                                                        flex-grow: inherit !important;
                                                        @media (min-width: 1050px) {
                                                            max-width: 50%;
                                                        }

                                                        .tile-title {
                                                            font-size: 1.8rem;
                                                            white-space: initial;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    //Input styling
                                    &[data-xpv-key="aantal"] {
                                        position: absolute;
                                        right: 18rem;
                                        bottom: 0;
                                        padding: 0;
                                        display: flex;
                                        align-items: center;
                                        max-width: 12rem;

                                        & > .container {
                                            padding: 0;
                                        }

                                        .v {
                                            &-label {
                                                margin-right: 1.6rem !important;
                                                margin-bottom: 0 !important;
                                            }

                                            &-input {
                                                margin: 0;

                                                &__slot {
                                                    margin: 0;

                                                    &::before, &::after {
                                                        display: none;
                                                    }

                                                    input {
                                                        @include input-field-styling;
                                                        max-width: 8rem;
                                                    }
                                                }

                                                .v-text-field__details {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //Eigen Bedrag Styling
            div[data-xpv-key="bedrag"] {
                max-height: 7.4rem;
                right: 0;
                padding: 0;

                @media (min-width: 1050px) {
                    position: absolute;
                    bottom: 8rem;
                    max-width: calc(50% - .9rem);
                }

                @media (max-width: 1050px) {
                    margin-bottom: 3rem;
                }

                & > label {
                    display: none !important;
                }

                & > .container {
                    padding: 0;
                }

                .v-input {
                    margin: 0;

                    &__slot {
                        min-height: 7.4rem;
                        background-color: getColor(bg, base);
                        border-radius: 1.3rem;
                        margin: 0;
                    }

                    .v-text-field__slot {
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 1.2rem;

                        &::before {
                            content: '€';
                            position: absolute;
                            top: 0;
                            left: 2.4rem;
                            transform: translateY(0.1rem);
                            font-size: 2rem;
                            font-weight: 700;
                            color: $color-text;
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }
                    }

                    input {
                        min-height: 6rem;
                        max-width: calc(100% - 1.2rem);
                        border-radius: 1.3rem !important;
                        background-color: $color-on-theme;
                        padding: .9rem 1.6rem .9rem 3.2rem !important;
                        font-size: 2rem;
                        font-weight: 700;
                        color: $color-text;
                    }
                }

                @import "components/heartbeat";

                .v-messages__message {
                    text-align: center;
                    margin-top: 0.3rem;
                    font-size: 1.2rem;
                    font-weight: 500;
                    color: $color-theme;
                }

                .error--text .v-messages__message {
                    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
                    animation: heartbeat 1.5s ease-in-out infinite both;
                    color: $color-danger;
                }
            }

            //SVG Canvas Styling
            & > .container[data-xpv-key="stap-ontwerp-je-kaart"] {
                .v-stepper__wrapper {
                    & > .container {
                        &:first-child {
                            & > .container {
                                //Card Select Styling
                                &:first-child {
                                    .trigger > button {
                                        @include input-field-styling;

                                        .v-list-item__title {
                                            font-size: 1.6rem;
                                            color: $color-text;
                                        }

                                    }
                                }
                            }

                            //Canvas Styling
                            .container[data-xpv-key="upload-afbeelding"] {
                                padding-bottom: 0;

                                .v-label {
                                    display: none !important;
                                }

                                .dropbox {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 2rem;
                                    background-color: $color-on-theme;
                                    outline: none;

                                    &::after {
                                        content: '';
                                        background: getColor(bg, base);
                                        background-image: url('../images/image-placeholder.svg');
                                        background-repeat: no-repeat;
                                        background-size: 125px 125px;
                                        background-position: top 30% right 50%;
                                        outline: 2px dashed $color-theme;
                                        height: 96%;
                                        width: 96%;
                                        position: absolute;
                                        border-radius: 1rem;
                                        z-index: 0;
                                    }

                                    input {
                                        z-index: 2;
                                    }

                                    .browse-btn {
                                        margin-bottom: 0;
                                        position: relative;
                                        z-index: 1;
                                        padding: 12.5rem 0 1rem 0;

                                        br {
                                            display: none !important;
                                        }

                                        button {
                                            all: unset;
                                            color: $color-theme;
                                            text-decoration: underline;
                                            font-weight: bold;
                                        }
                                    }
                                }

                                .v-card {
                                    box-shadow: none;
                                    display: flex;
                                    flex-direction: column;

                                    .v-image {
                                        order: 0;
                                    }

                                    &__actions {
                                        padding: 0 0 1.5rem;
                                        order: 1;

                                        button {
                                            min-height: auto;
                                            min-height: auto;
                                            padding: 0;

                                            &::before {
                                                all: unset;
                                                content: "";
                                                height: 15px;
                                                width: 15px;
                                                background-image: url(../images/expivi/recycle-bin.svg);
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                                color: $color-text;
                                                margin-right: 0.4rem;
                                            }

                                            span {
                                                color: $color-text;
                                                font-size: 1.2rem;
                                                font-weight: bold;
                                                text-decoration: underline;
                                            }
                                        }
                                    }

                                    &__title {
                                        padding-top: 0;
                                        padding-bottom: 0;
                                        order: 2;

                                        & > .grey--text {
                                            display: none;
                                        }

                                        & > .container {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }

                            .container[data-xpv-key="let-op-afbeelding"] {
                                padding: 0;

                                .v-subheader {
                                    padding-left: 0.8rem !important;
                                    font-size: 1.4rem;
                                    font-weight: bold;
                                    color: #929292;
                                    height: auto;
                                    text-decoration: underline;

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }

                                .container {
                                    display: none;
                                }
                            }

                            .container[data-xpv-key="voeg-tekst-toe"] {
                                border-top: 1px solid getColor(border, menu);
                                margin-top: 3.5rem !important;
                                padding-top: 3rem;
                                padding-bottom: 0;

                                .v-subheader {
                                    font-size: 1.6rem;
                                    line-height: 2.6rem;
                                    font-weight: bold;
                                    padding-left: 0;
                                    color: getColor(theme, base);
                                    height: auto;
                                }

                                .container {
                                    display: none;
                                }
                            }

                            //Fonts Styling
                            .container[data-xpv-key="kies-tekst-font"] {
                                border-top: 1px solid getColor(border, menu);
                                margin-top: 2rem;
                                padding-top: 2rem;

                                .v {
                                    &__slot {
                                        border: 0;
                                        padding: 0;
                                    }

                                    &-input {
                                        padding-top: 0;
                                    }

                                    &-input__slot {
                                        padding: 0;
                                        border: 0;

                                        &::before, &::after {
                                            display: none;
                                        }

                                        .v-text-field__slot {
                                            display: block;

                                            & > input {
                                                @include input-field-styling;;
                                            }
                                        }
                                    }

                                    &-window {
                                        @media (max-width: 767px) {
                                            .v-list-item:not(.v-list-item--highlighted) {
                                                &:hover:before, &:focus:before {
                                                    opacity: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            //Color Styling
                            .container[data-xpv-key="kies-tekst-kleur"] {
                                .row {
                                    margin: 0;

                                    & > .col {
                                        flex-basis: unset;
                                        width: calc(100% / 12);
                                        padding: .4rem;

                                        @media (min-width: 950px) and (max-width: 1200px) {
                                            width: calc(100% / 9);
                                        }

                                        @media (min-width: 768px) and (max-width: 950px) {
                                            width: calc(100% / 6);
                                        }

                                        @media (max-width: 500px) {
                                            width: calc(100% / 9);
                                        }

                                        @media (max-width: 400px) {
                                            width: calc(100% / 6);
                                        }
                                    }
                                }

                                .v-ripple__container {
                                    display: none;
                                }

                                .tile-card {
                                    box-shadow: none;
                                    position: relative;

                                    &::before {
                                        display: none;
                                    }

                                    .color-tile {
                                        border: 1px solid getColor(border, menu);
                                        height: 4rem;
                                        width: 4rem;

                                        @media (max-width: 767px) {
                                            height: 3rem;
                                            width: 3rem
                                        }
                                    }

                                    .active-tile {
                                        position: absolute;
                                        top: -.3rem;
                                        left: -.3rem;
                                        height: calc(4rem + .6rem);
                                        width: calc(4rem + .6rem);
                                        border: 2px solid $color-theme;
                                        border-radius: 50% !important;
                                        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, .25);

                                        @media (max-width: 767px) {
                                            height: calc(3rem + .6rem);
                                            width: calc(3rem + .6rem);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //Package styling
            & > .container[data-xpv-key="stap-laten-inpakken"],
            & > .container[data-xpv-key="stap-verzendadres-ontvangers"] {
                .v-label {
                    display: none !important;
                }

                .container {
                    & > .row {
                        margin: 0;

                        & > .col {
                            padding: .9rem;
                            height: 12.4rem;

                            @media (max-width: 567px) {
                                flex: 100%;
                                max-width: 100%;
                            }

                            .tile-card {
                                flex-direction: row;
                                min-height: 100%;
                                border-radius: 1.3rem;
                                border: 1px solid getColor(border, menu);
                                background-color: $color-on-theme;
                                padding: .5rem;

                                &::before, &::after {
                                    display: none;
                                }

                                .image-tile {
                                    height: 5.6rem;
                                    width: 9rem;
                                    padding: 0;
                                    background-position: center !important;
                                }

                                .tile-title {
                                    min-width: calc(100% - 9rem);
                                    justify-content: flex-start;
                                    padding: 0;

                                    h3 {
                                        margin: 0;
                                        font-size: 1.2rem;
                                        text-align: left;
                                    }
                                }

                                .v-card__actions {
                                    display: none !important;
                                }
                            }

                            .active-tile {
                                height: 100%;
                                border: 2px solid $color-theme;
                                border-radius: 1.3rem !important
                            }

                            .v-ripple__container {
                                display: none;
                            }
                        }
                    }
                }
            }

            & > .container[data-xpv-key="stap-verzendadres-ontvangers"],
            & > .container[data-xpv-key="stap-persoonlijk-kaartje"] .step-btn-next,
            & > .container[data-xpv-key="stap-persoonlijk-kaartje"] .container[data-xpv-key="schrijf-bericht"] #toolbar-container {
                // Multiship
                // Hide by default, show when relevant
                display: none;
            }

            & > .container[data-xpv-key="stap-persoonlijk-kaartje"] .container[data-xpv-key="schrijf-bericht"] #editor-container {
                border-top: 1px solid #ccc;
                border-radius: .6rem;
            }

            & > .container[data-xpv-key="stap-laten-inpakken"] {
                .row {
                    margin: 0 !important;
                }

                .col.col-6 {
                    flex: 0 0 33% !important;
                    max-width: 33% !important;
                    height: auto !important;

                    @media (max-width: 500px) {
                        flex: 0 0 50% !important;
                        max-width: 50% !important;
                    }
                }

                // FIXME TODO after keys are working, this needs to be updated similar to below
                // h3[data-xpv-key="geen-verpakking"]::after
                .col:nth-child(1) .tile-card .tile-title h3::after {
                    content: '';
                    display: block;
                }

                .col:nth-child(2) .tile-card .tile-title h3::after {
                    content: '+ € 2,29 p/st';
                }

                .col .tile-card {
                    flex-direction: column !important;

                    .image-tile {
                        height: 9.6rem !important;
                        width: 12rem !important;
                    }

                    .tile-title {
                        h3 {
                            text-align: center !important;

                            &::after {
                                content: '+ € 1,20 p/st';
                                display: block;
                                color: $color-secondary;
                                margin-top: .6rem;
                            }
                        }
                    }
                }
            }

            &__step {
                padding: 1.6rem;

                &__step {
                    align-self: center;
                    width: 3.5rem;
                    height: 3.5rem;
                    margin-right: 1rem;
                    border: 2px solid $color-theme;
                    background-color: $color-theme;
                    color: $color-on-theme;
                    font-size: 1.8rem;
                    font-weight: bold;
                    border: 2px solid $color-theme !important;

                    &.primary {
                        background-color: $color-on-theme !important;

                        svg {
                            color: $color-secondary;
                        }
                    }
                }

                &.active-step + .v-stepper__content {
                    padding: 0 1.6rem 1.6rem;
                }

                .step-info.has-tooltip {
                    display: none;
                }
            }

            &__wrapper {
                & > .container {
                    &:last-of-type {
                        & > .layout {
                            margin-top: 1.6rem;
                            align-items: center;

                            .spacer {
                                display: none;
                            }
                        }
                    }
                }
            }

            &__content {
                border-left: 0;
                margin: 0;
                padding: 0;

                .select-field-btn * {
                    font-size: 1.2rem;
                    color: #929292;
                    font-weight: bold;
                }

                .step-btn {
                    font-weight: bold;

                    &-prev {
                        color: #929292;
                        font-size: 1.4rem;
                        line-height: 1.4rem;
                        margin: 0 2rem;
                        cursor: pointer;
                        text-decoration: underline;

                        &:hover, &:focus {
                            &:before {
                                display: none;
                            }
                        }

                        svg {
                            display: none;
                        }
                    }

                    &-next {
                        background-color: $color-theme;
                        color: $color-on-theme !important;
                        font-size: 1.6rem;
                        min-height: 4.8rem;
                        min-width: 14rem;
                        border-radius: 10rem;

                        &:hover, &:focus {
                            background-color: #4e5ab3;
                        }
                    }
                }
            }

            &__label {
                .step-header {
                    &-name {
                        color: $color-theme;
                        font-weight: bold;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .product-price-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            flex-wrap: wrap;

            @media (max-width: 400px) {
                padding: 0 !important;
            }

            .confirmation-modal-trigger {
                margin-right: 0;
            }

            @media (max-width: 400px) {
                .product-info-price {
                    max-width: 45% !important;

                }
            }

            & > .action {
                display: flex;

                @media (max-width: 400px) {
                    max-width: 55% !important;
                }
            }

            .box-tocart {
                display: none;
            }

            .price {
                margin-bottom: 0;
                margin-left: .2rem;
                color: $color-theme;

                &-box {
                    margin-top: 0;

                    .price-label {
                        display: none;
                    }

                    .price-from {
                        margin-bottom: 0;
                    }

                    .price-to {
                        display: none;
                    }
                }

                &::before {
                    content: "Totaal: ";
                    font-size: 1.4rem;
                }
            }

            .price-box.price-final_price::after {
                content: "verzendkosten v.a €1,95";
                display: block;
            }

            .box-tocart {
                margin: 0;
            }

            .actions {
                padding-top: 0;
            }
        }
    }

    .selector-options-container {
        .xpv-popup-close {
            color: $color-theme;
        }

        .v-list-item__title {
            font-size: 1.4rem;
            color: #929292;
            font-weight: bold;
        }
    }

    .xpv-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 0;

        &::before {
            content: "";
            left: 0;
            top: 0;
            background-color: rgba(62, 72, 144, 0.19);
            width: 100%;
            height: 100%;
            backdrop-filter: blur(4px);
        }

        @media (max-width: 767px) {
            .modal-inner-wrap {
                min-height: 40% !important;
            }
        }

        .modal-title {
            font-size: 2.8rem !important;
            line-height: 4rem;
        }

        .action-close {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-theme;
            padding: 1rem 1.3rem;
            border-radius: 10rem;
            height: 4rem;
            width: 4rem;

            &::before {
                content: "Sluiten";
                font-family: Quicksand, sans-serif;
                font-size: 1.4rem;
                font-weight: bold;
                color: #3E4890;
                position: absolute;
                left: 0;
                top: .4rem;
                opacity: 0;
                transform: translateX(0);
                transition: .3s;
            }

            &:hover:before {
                opacity: 1;
                transform: translateX(-5.5rem);
            }

            &::after {
                content: "\f00d";
                font-family: "FontAwesome";
                color: $white;
                font-size: 1.5rem;
            }
        }

        .modal {
            &-inner-wrap {
                margin: auto;
            }

            &-title {
                border: 0;
                @media (max-width: 767px) {
                    padding-top: 5rem;
                }
            }

            &-footer {
                display: flex;
                justify-content: space-between;

                @media (max-width: 767px) {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .confirm-add-to-cart {
        &.has-status {
            .text {
                display: none;
            }
        }

        .loader-wrapper,
        .error-wrapper {
            display: none;
        }

        &.is-loading {
            .loader-wrapper {
                display: block;
            }
        }

        &.has-error {
            .error-wrapper {
                display: block;
            }
        }
    }

    .image-conditions-modal {
        li {
            margin-bottom: 0;
        }

        footer.modal-footer {
            justify-content: right !important;
        }
    }

    .lds-ellipsis div {
        background-color: getColor(theme, base);
    }

    @import "components/loader";
    @import "components/multiship";
    @import "components/amount-exceeded";
    @import "components/blocks";
    @import "components/shipping-information";

    .interaction {
        &-wrapper {
            background-color: $color-on-theme;
            padding: 1.25rem;
            box-sizing: border-box;
            z-index: 5;
            transition: .3s;
            box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.1);
            opacity: 0;
            pointer-events: none;

            &.is-visible {
                opacity: 1;
                pointer-events: all;
                left: 0;
            }

            &.is-active {
                .interaction-button::before {
                    transform: rotate(180deg);
                }
            }

            @media (min-width: 767px) {
                display: none !important;
            }
        }

        &-button {
            box-sizing: border-box;
            border: 1px solid getColor(border, menu);
            padding: 1.25rem;
            text-align: center;
            border-radius: 1rem;
            position: relative;
            color: $color-theme;
            font-size: 1.6rem;
            cursor: pointer;

            &::before {
                content: "\f078";
                font-family: "FontAwesome";
                font-size: 1rem;
                display: flex;
                align-items: center;
                position: absolute;
                left: 1.25rem;
                top: 0;
                height: 100%;
                color: inherit;
                transition: .3s;
            }
        }
    }
}

@import "typography";
