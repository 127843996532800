.menu {
    &,
    ol,
    ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style-type: none;
        // extra unset for Magento-ui
        > li {
            margin-bottom: 0;
        }
    }

    a,
    &-item {
        display: block;
        color: inherit;
        text-decoration: none;

        &:focus,
        &:hover {
            background-color: getColor(bg, base);
            color: inherit;
            text-decoration: none;
        }

        &.active,
        &:active {
            background-color: getColor(gray, base);
            color: inherit;
        }
    }
}
