.contact {
    background: #ffffff;
    margin-bottom: 8rem;
    border-radius: 1.5rem;

    h2 {
        color: getColor(theme, base);
        font-size: 2.6rem;
        font-weight: 700;
        padding-left: 2.1rem;
        margin-top: 1.8rem;
    }

    h1 {
        color: getColor(theme, base);
    }

    form {
        padding-left: 0;
    }

    .webforms p {
        font-size: 1.6rem;
        color: getColor(theme, base);
        font-weight: bold;
        margin-top: -3rem;
        max-width: 39.8rem;
    }

    h1 + p {
        margin-top: -4.7rem;
        font-weight: bold;
        padding-right: 3rem;
    }

    p + h2 {
        color: getColor(theme, base);
        margin-top: 3.1rem;
        padding-left: 0;
        font-size: 1.6rem;
    }

    p + p, h2 + p {
        font-weight: 400;
    }

    .fieldset {
        margin: -2.1rem 0 4rem;
    }

    .tel {
        font-weight: bold;
        color: getColor(theme, base);
        margin-top: 1.8rem;
    }

    textarea {
        height: 7.5rem;
    }

    .fieldset > .field {
        margin: 0 0 2rem;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
    }
}
