.badge {
    display: inline-block;
    padding: 2px 4px;
    border-radius: 1em;
    background-color: $color-theme;
    color: $color-on-theme;
    &.style-price {
        box-sizing: border-box;
        position: relative;
        width: 118px;
        height: 101px;
        padding: 7px;
        border-radius: 12px;
        text-align: center;
        background: transparent svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="118" height="101"><path fill="#{$color-theme}" d="M49.129 94.851L2.528 18.236C-.916 12.574.882 5.192 6.544 1.748A12 12 0 0 1 12.78 0h93.203c6.627 0 12 5.373 12 12a12 12 0 0 1-1.748 6.236L69.634 94.851c-3.444 5.663-10.826 7.46-16.489 4.017a12 12 0 0 1-4.016-4.017z"/></svg>') center/contain no-repeat;
        .price {
            font-size: 20px;
            line-height: 1.15;
            font-weight: 700;
        }
        .cta {
            $size: 33px;
            box-sizing: border-box;
            position: absolute;
            right: 20px;
            bottom: 8px;
            width: $size;
            height: $size;
            border: 2px solid #fff;
            border-radius: 50%;
            background-color: $color-secondary;
            color: $color-on-secondary;
            line-height: ($size - (2px * 2));
            font-weight: 700;
        }
    }
}
