// Fylgja v0.2.0 (getfylgja.com)
// Licensed under MIT Open Source

$skip-link-bg: if(variable_exists(color-bg), $color-bg, white);
$skip-link-color: if(variable_exists(color-text), $color-text, rgba(black, .87));

.skip-link {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;
    padding: .5rem;
    background-color: $skip-link-bg;
    color: $skip-link-color;
    text-align: center;
    transform: translateY(-100%);
    &:focus {
        transform: translateY(0);
    }
}
