.checkout-onepage-success {
  .page-title-wrapper {
    display: none;
  }
}

.checkout-success {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 13px;
  margin-top: 24px;
  margin-bottom: 40px;

  .page-title {
    text-align: center;
    margin-bottom: 5px;
    padding-top: 13px;
  }

    a.order-number {
        position: relative;
        z-index: 999;
    }

  .container-order {
    max-width: 340px;
    border-radius: 13px;
    position: relative;
    padding: 219px 0 112px;
    margin: 0 10px;
    img {
      position: absolute;
      top: 76px;
      right: 12px;
      width: 142px;
    }
    > p {
      text-align: center;
      margin-bottom: 8px;
      font: 500 12px $family-heading;
      color: #080808;
      span, a {
        font-weight: normal;
        color: #080808;
      }
    }
    .container-text {
      font: bold 12px $family-heading;
      background: #f5f6f8;
      padding: 18px 16px 23px;
      border-radius: 13px;
      text-align: center;
      margin-bottom: 54px;
      p {
        color: #3e4890;
      }
      .email {
        color: #ef7d28;
      }
    }
    .actions-toolbar {
      text-align: center;

      > .primary {
        float: none;
        display: flex;
        flex-direction: row;
        justify-content: center;

        a {
            border-radius: 20px;
            background-image: linear-gradient(to right, #ef7d28, #fabe47, #fcc61b);
            padding: 11px 17px 11px 8px;
            width: auto;
            box-sizing: border-box;
            min-width: 161px;
            font: bold 14px/18px $family-heading;
            text-align: center;
            position: relative;
            transition: box-shadow 0.6s cubic-bezier(0, 0.18, 0.21, 1);

          &:hover {
            box-shadow: 0 4px 7px 0 rgba(239, 125, 40, 0.5);
          }
        }
      }
    }
  }
}

@include max-screen(360px) {
  .checkout-success {
    .container-order {
      img {
        right: -4px;
      }
    }
  }

}
