//
//  Typography variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------

//  Path
$font-path                      : "../../fonts/" !default;
$icons__font-path               : "../fonts/Blank-Theme-Icons/Blank-Theme-Icons" !default;

//  Names
$icons__font-name               : 'icons-blank-theme' !default;

//  Font families
$font-family-name__base         : 'Open Sans' !default;
$font-family__sans-serif        : 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family__serif             : Georgia, 'Times New Roman', Times, serif !default;
$font-family__monospace         : Menlo, Monaco, Consolas, 'Courier New', monospace !default;

$font-family__base              : getFamily(body);

//  Sizes
$root__font-size                : 62.5% !default;
$font-size-ratio__base          : 1.4 !default;

$font-size-unit                 : rem !default;
$font-size-unit-ratio           : $root__font-size * 16 / 100 / 1% !default;
$font-size-unit-convert         : true !default;

$font-size__base                : $font-size-unit-ratio * $font-size-ratio__base * 1px !default;
$font-size__xl                  : ceil(1.5 * $font-size__base) !default;
$font-size__l                   : ceil(1.25 * $font-size__base) !default;
$font-size__s                   : ceil(0.85 * $font-size__base) !default;
$font-size__xs                  : floor(0.75 * $font-size__base) !default;

//  Weights
$font-weight__light             : getWeight(light);
$font-weight__regular           : getWeight(regular);
$font-weight__heavier           : getWeight(heavier);
$font-weight__semibold          : getWeight(semibold);
$font-weight__bold              : getWeight(bold);

//  Styles
$font-style__base               : normal !default;
$font-style__emphasis           : italic !default;

//  Line heights
$line-height__base              : 1.428571429 !default;
$line-height__computed          : floor($font-size__base * $line-height__base) !default;
$line-height__xl                : 1.7 !default;
$line-height__l                 : 1.5 !default;
$line-height__s                 : 1.33 !default;

//  Colors
$text__color                    : getColor(text);
$text__color__intense           : getColor(text, dark);
$text__color__muted             : getColor(text, light);

//
//  Indents
//  ---------------------------------------------

$indent__base                   : $line-height__computed !default;
$indent__xl                     : $indent__base * 2 !default;
$indent__l                      : $indent__base * 1.5 !default;
$indent__m                      : $indent__base * 1.25 !default;
$indent__s                      : $indent__base / 2 !default;
$indent__xs                     : $indent__base / 4 !default;

//
//  Borders
//  ---------------------------------------------

$border-color__base             : getColor(border);
$border-width__base             : 0.1rem;

//
//  Links
//  ---------------------------------------------

$link__color                    : getColor(theme);
$link__text-decoration          : none;

$link__visited__color           : getColor(theme, dark);
$link__visited__text-decoration : none;

$link__hover__color             : getColor(theme, light);
$link__hover__text-decoration   : underline !default;

$link__active__color            : getColor(theme, dark);
$link__active__text-decoration  : underline !default;

//
//  Focus
//  ---------------------------------------------

$focus__color                   : transparent;
$focus__box-shadow              : none;

//
//  Lists
//  ---------------------------------------------

$list__color__base              : false !default;
$list__font-size__base          : false !default;
$list__margin-top               : 0 !default;
$list__margin-bottom            : $indent__m !default;

$list-item__margin-top          : 0 !default;
$list-item__margin-bottom       : $indent__s !default;

$dl__margin-top                 : 0 !default;
$dl__margin-bottom              : $indent__base !default;

$dt__margin-top                 : 0 !default;
$dt__margin-bottom              : $indent__xs !default;
$dt__font-weight                : $font-weight__bold !default;

$dd__margin-top                 : 0 !default;
$dd__margin-bottom              : $indent__s !default;

//
//  Paragraphs
//  ---------------------------------------------

$p__margin-top                  : 0 !default;
$p__margin-bottom               : $indent__s !default;

//
//  Headings
//  ---------------------------------------------

$heading__font-family__base     : getFamily(heading);
$heading__font-weight__base     : $font-weight__light !default;
$heading__line-height__base     : 1.1 !default;
$heading__color__base           : false !default;
$heading__font-style__base      : false !default;
$heading__margin-top__base      : $indent__base !default;
$heading__margin-bottom__base   : $indent__base !default;

$h1__font-size                  : getSize(h1);
$h1__font-color                 : $heading__color__base !default;
$h1__font-family                : $heading__font-family__base !default;
$h1__font-weight                : $heading__font-weight__base !default;
$h1__font-style                 : $heading__font-style__base !default;
$h1__line-height                : getLineHeight(h1);
$h1__margin-top                 : 0 !default;
$h1__margin-bottom              : getFont(margin, h1) * 10;
$h1__font-size-desktop          : ceil(($font-size__base * 2.85)) !default;

$h2__font-size                  : getSize(h2);
$h2__font-color                 : $heading__color__base !default;
$h2__font-family                : $heading__font-family__base !default;
$h2__font-weight                : $heading__font-weight__base !default;
$h2__font-style                 : $heading__font-style__base !default;
$h2__line-height                : getLineHeight(h2);
$h2__margin-top                 : $indent__m !default;
$h2__margin-bottom              : getFont(margin, h2) * 10;

$h3__font-size                  : getSize(h3);
$h3__font-color                 : $heading__color__base !default;
$h3__font-family                : $heading__font-family__base !default;
$h3__font-weight                : $heading__font-weight__base !default;
$h3__font-style                 : $heading__font-style__base !default;
$h3__line-height                : getLineHeight(h3);
$h3__margin-top                 : $indent__base * 0.75 !default;
$h3__margin-bottom              : getFont(margin, h3) * 10;

$h4__font-size                  : getSize(h4);
$h4__font-color                 : $heading__color__base !default;
$h4__font-family                : $heading__font-family__base !default;
$h4__font-weight                : $font-weight__bold !default;
$h4__font-style                 : $heading__font-style__base !default;
$h4__line-height                : getLineHeight(h4);
$h4__margin-top                 : $heading__margin-top__base !default;
$h4__margin-bottom              : getFont(margin, h4) * 10;

$h5__font-size                  : getSize(h5);
$h5__font-color                 : $heading__color__base !default;
$h5__font-family                : $heading__font-family__base !default;
$h5__font-weight                : $font-weight__bold !default;
$h5__font-style                 : $heading__font-style__base !default;
$h5__line-height                : getLineHeight(h5);
$h5__margin-top                 : $heading__margin-top__base !default;
$h5__margin-bottom              : getFont(margin, h5) * 10;

$h6__font-size                  : getSize(h6);
$h6__font-color                 : $heading__color__base !default;
$h6__font-family                : $heading__font-family__base !default;
$h6__font-weight                : $font-weight__bold !default;
$h6__font-style                 : $heading__font-style__base !default;
$h6__line-height                : getLineHeight(h6);
$h6__margin-top                 : $heading__margin-top__base !default;
$h6__margin-bottom              : getFont(margin, h6) * 10;

$heading__small-color           : $primary__color !default;
$heading__small-line-height     : 1 !default;
$heading__small-size            : ($font-size__xs / $font-size__base) * 100% !default;

//  Code blocks
$code__background-color         : $panel__background-color !default;
$code__color                    : $primary__color__darker !default;
$code__font-size                : $font-size__s !default;
$code__padding                  : 2px 4px !default;

$pre__background-color          : $panel__background-color !default;
$pre__border-color              : $border-color__base !default;
$pre__border-width              : $border-width__base !default;
$pre__color                     : $primary__color__darker !default;

$kbd__background-color          : $panel__background-color !default;
$kbd__color                     : $primary__color__darker !default;

//  Blockquote
$blockquote__border-color       : $border-color__base !default;
$blockquote__border-width       : 0 !default;
$blockquote__content-before     : '\2014 \00A0' !default;
$blockquote__font-size          : $font-size__base !default;
$blockquote__font-style         : $font-style__emphasis !default;
$blockquote__margin             : 0 0 $indent__base $indent__xl !default;
$blockquote__padding            : 0 !default;

$blockquote-small__color        : $primary__color !default;
$blockquote-small__font-size    : $font-size__xs !default;

$cite__font-style               : $font-style__base !default;

//  Misc
$hr__border-color               : $border-color__base !default;
$hr__border-style               : solid !default;
$hr__border-width               : $border-width__base !default;

$mark__color                    : $primary__color__dark !default;
$mark__background-color         : $panel__background-color !default;

$abbr__border-color             : $border-color__base !default;

//  Disable filters output in css
$disable-filters                : false !default;
