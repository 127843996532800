$bg-color: #010C3E;
$color: #435063;

.lottery-main-container {
    position: fixed;
    z-index: 9999999999999999999999999999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    background: $bg-color;
    height: 100%;
}

label sup {
    color: red;
}

.lotto-page {
    background-color: $bg-color;

    .content-block.page-main {
        padding: 0 5px;
    }

    .breadcrumbs.container {
        display: none;
    }

    .page-title-wrapper {
        margin-bottom: 0;
    }

    #lottery-popup-error{
        display: none;

        .outer-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,.2);
            z-index: 99;
    
            .inner-wrapper {
                background: white;
                border-radius: 1.5rem;
                width: 500px;
                align-items: center;
                padding: 30px 40px;
                text-align: center;

                img {
                    max-width: 100px;
                }

                p {
                    font-weight: bold;
                    width: 500px;
                    max-width: 305px;
                    margin: 0px auto 30px auto;
                }

                h2.popup-title {
                    color: #263550;
                    font-size: 25px;
                    margin: 0;
                    margin-top: 10px;
                }
            }
        }
    }

    #maincontent.lottery {
        margin: auto;
        position: relative;
        padding: 150px 0 0 0;

        .padding-dec > div > div{
            padding: 0 !important;
        }

        .page-title-wrapper.onMobile {
            display: none;
        }
        .page-title-wrapper {
            display: block;
        }

        img.coupon {
            position: relative;
            top: 10px;
        }

        img.zalando {
            position: relative;
            top: 5px;
        }

        .page-title {
            font-size: 25px;
        }
        .page-title-block.onMobile {
            margin: 0;
        }
    
        .page-subtitle {
            font-size: 16px;
            color: #ef7e28;
            line-height: 2.7rem;
        }

        .progress-bar {
            transition: all 0.3s ease-out;
            position: absolute;
            bottom: 0;
            width: 0;
            left: 1rem;
            border-bottom: 0.1rem solid #fabe47;
            left: 10px;
            bottom: 0;
            height: 1px;

            &:after {
                display: inline-block;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                content: "";
                color: rgb(83, 156, 57);
                opacity: 0;
                position: absolute;
                top: -0.75rem;
                z-index: 4;
                background-color: white;
                font: 1.5rem / 1 FontAwesome;
                border-radius: 100%;
            }

            &.valid {
                border-color: #539c39;

                &:after {
                    opacity: 1;
                    right: -1rem;
                }
            }
        }


        .page-wrapper.content-block {
            max-width: 51%;
            margin: auto;
            margin-top: 238px;
        }

        .images {
            .logo {
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                max-width: 100%;
                display: block;
                margin: auto;
            }
        }

        .peak {
            display: block;
            margin: auto;
            position: relative;
            top: -21px;
            left: 17px;
        }

        .inner-wrapper {
            background: white;
            padding: 20px 40px;
            border-radius: 1.5rem;
            margin: auto;
            margin-top: 20px;


            .upper-text {
                color: $color;
                font-weight: bold;
                margin: 0px 10px 40px 10px;
            }

            .char-counter-wrapper {
                color: $color;
                position: relative;
                left: 20px;
                top: -20px;
                font-size: 12px;
                font-weight: bold;
                opacity: 0.55;

                .char-counter:before {
                    display: none;
                }
            }

            .form-row {
                margin: 25px 0;
                
                &.button-row {
                    margin: 0;
                }

                .button-wrapper {
                    text-align: center;
                    margin: 6px 10px 0 10px;
                    margin-top: 3px;
                }

                .form-group {
                    width: 100%;
                    margin: 0 10px;
                    position: relative;

                    input {
                        background: white;
                        background-clip: padding-box;
                        border: 1px solid #e4e4e4;
                        border-radius: 1rem;
                        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
                        vertical-align: baseline;
                        width: 100%;
                        box-sizing: border-box;
                        font-size: 16px;
                        width: 100%;
                        height: 45px;

                        &[type="checkbox"] {
                            border-color: #929292;
                        }                        
                        
                        &::placeholder {
                            font-size: 16px;
                        }
                    }

                    input[type="checkbox"] + label {
                        font-weight: 500;
                        a {
                            font-weight: 500;
                        }
                    }

                    label {
                        position: relative;
                        top: -5px;
                        font-size: 16px;
                        font-family: "Muli", sans-serif;
                        font-weight: 600;

                        &.kaartnummer:after {
                            content: '';
                            font-family: fontAwesome;
                            color: #435063;
                            margin-left: 5px;
                            font-size: 16px;
                        }

                        &.active {
                            &:after {
                                content: "active";
                                height: 5px;
                                width: 13px;
                                display: block;
                                position: absolute;
                                color: transparent;
                                top: 4px;
                                left: 3px;
                                border-left: 2px solid #3e4890;
                                border-bottom: 2px solid #3e4890;
                                transform: rotate(-45deg);
                            }
                        }
                    }
    
                    button.action{
                        width: 77%;
                        height: 45px;
                    }
                }
            }

            .disclaimer {
                text-align: center;
                color: #435063;
                margin-top: 20px;
                margin-top: -10px;
                
                & > div {
                    margin: 0 !important;

                    & > div {
                        margin-top: 25px !important;
                        margin-bottom: 0 !important;
                        
                        p {
                            margin: 0;
                        }
                    } 
                }

                a {
                    color: #435063;
                    font-weight: 500;
                }
            }

            .second-step {
                display: none;
            }
        }
    }

    .bottom-section {
        padding-top: 20px;
        padding-bottom: 100px;
        width: 65%;
        margin: auto;


        h2 {
            text-align: center;
            color: white;
            font-size: 25px;
        } 
        
        .row-image {
            display: flex;
            justify-content: center;
            display: flex;
            flex-flow: row wrap;
            position: relative;

            & > div {
                margin: 4px;
                flex: 0 1 calc(16.6666666667% - 8px);
            }

    
            img {
                max-width: 100%;
            }
        }
    }




    #confirmation {
        display: none;
        text-align: center;

        img.logo {
            width: 67%;
        }

        .page-title {
            font-size: 32px;
            color: #263550;
        }
    
        .page-subtitle {
            font-size: 16px;
            color: #ef7e28;
            line-height: 2.7rem;
        }

        .outer-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
            top: -229px;

            .inner-wrapper {
                background: white;
                border-radius: 1.5rem;
                width: 500px;
                align-items: center;
                padding: 30px 60px 45px 60px;
                text-align: center;

                img.peak {
                    left: 16px;
                    position: relative;
                    top: -31px;
                    width: 100%;
                    max-width: 110px;
                }

                img {
                    max-width: 100px;
                }

                button {
                    width: 80%;
                }

                p {
                    font-weight: bold;
                    width: 500px;
                    margin: 20px 0 50px 0;
                }
            }
        }
    }
    h1,
    h2,
    h3,
    h4 {
        font-family: "Muli", sans-serif !important;
    }
}


@media only screen and (max-width: 1270px) {
    .lotto-page {
        #maincontent.lottery {
            .page-wrapper.content-block {
                max-width: 60%;
            }
        } 

        #confirmation {
            .outer-wrapper {
                top: -236px;
            }
            img.logo {
                max-width: 100%;
                width: auto;
            }
        }
    } 
}

@media only screen and (max-width: 991px) {
    .lotto-page {
        #maincontent.lottery {
            .page-wrapper.content-block {
                max-width: 75%;
            }
        } 

        #confirmation {
            .outer-wrapper {
                top: -293px;

                .inner-wrapper {
                    p {
                        width: auto;
                    }
                }
            } 

            img.logo {
                position: relative;
                top: -100px;
            } 
        } 
    }
}

@media only screen and (max-width: 991px) {
    .lotto-page {
        #maincontent.lottery {
            .page-wrapper.content-block { 
                max-width: 75%;
            }

            .peak {
                display: none;
            }
        } 
    } 
}



@media only screen and (max-width: 805px) {
    .lotto-page {

        #maincontent.lottery  {
            .page-wrapper.content-block {
                max-width: 100%;
            }
            position: relative;

            .inner-wrapper {
                padding: 15px;
                position: static;

                .char-counter-wrapper {
                    top: -10px;
                }

                .logo {
                    position: absolute;
                    z-index: -1;
                    top: -75px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100%;
                }

                .upper-text {
                    margin: -10px 0px 30px 0px;
                }

                .form-row {
                    display: block;

                    .form-group {
                        margin: 25px 0;

                        &.button-wrapper {
                            margin: 0;
                        }
                    }

                    &.button-row {
                        margin: 0;
                    }
                }

                .char-counter-row {
                    position: relative;
                    top: -107px;
                }
            }
        }
        .bottom-section {
            width: 100%;
        }

        #lottery-popup-error {
            
            .outer-wrapper .inner-wrapper {
                overflow: scroll;
                height: 75%;
                max-width: 500px;
                width: 88%;
                padding: 20px 15px;

                
                p {
                    max-width: 500px;
                    width: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .lotto-page {
        
        #maincontent.lottery  {
            padding: 1px 0 0 0;
            position: relative;

            .page-title-wrapper {
                display: none;
            }

            .page-title-wrapper.onMobile {
                margin-top: 192px;
                display: block;
            }
            
            .peak {
                display: none;
            }
            
            .page-wrapper.content-block {
                max-width: 100%;
                margin-top: 0;
            }

            .images .logo {
                top: -50px;
            }

            .page-title-wrapper {
                .page-title {
                    color: white;
                }                
            }

            .disclaimer {
                display: none;
            }

            .inner-wrapper {
                padding: 30px 15px;
                position: relative;

                .first-step {
                    position: relative;
                    & .form-row > .form-group:last-child {
                        margin-top: 40px;
                    }
                }

                .char-counter-wrapper {
                    top: -25px;
                    position: absolute;
                }

                .second-step {
                    & > div.form-row:first-child {
                        margin-top: 0;

                        & > div.form-group:first-child {
                            margin-top: -10px;
                        }
                    }
                }

                .logo {
                    position: absolute;
                    z-index: -1;
                    top: -56px;
                    left: -36px;
                    right: 0;
                    bottom: 0;
                    max-width: 121%;
                }

                .form-row .form-group {
                    input.shorter {
                        width: 45%;
                    }
                }

                .disclaimer {
                    color: #435063;
                    font-size: 11px;
                }
            }
         
        }

        .bottom-section {
            width: 80%;

            .row-image {
                display: flex;
                justify-content: center;
                display: flex;
                flex-flow: row wrap;
                position: relative;

                & > div {
                    margin: 4px;
                    flex: 0 1 calc(50% - 8px);
                }
            }
        }

        #confirmation {
            img.logo {
                z-index: -1;
                top: -56px;
                left: -36px;
                right: 0;
                bottom: 0;
                max-width: 121%;
                width: auto;
            }
            .peak {
                display: none;
            }
            .outer-wrapper {
                .inner-wrapper {
                    width: 100%;
                    margin-top: 150px;
                    padding: 25px 10px;

                    p {
                        margin: 30px 0;
                    }
                }
            } 
        } 
    }
}
        



