.dropdown-pane {
    font-size: inherit;
    box-sizing: border-box;
    border: 0;
    border-radius: 15px;
    box-shadow: getShadow(theme);

    ul.menu a {
        padding: .5rem 1rem;
    }
}

.tabs {
    padding: 0;

    &-title {
        margin-bottom: 0;

        > a {
            font-size: inherit;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}
