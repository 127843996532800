$flyout-dropdown: 0 11px 26px 0 rgba(199, 204, 206, 0.3), 0 10px 11px 0 rgba(0, 0, 10, 0.07);
$flyout-background: #ffffff;
$flyout-radius: 1.5rem;
$flyout-transition: 0.3s cubic-bezier(0.55, 0, 0.1, 1);

// Main navigation
@include min-screen($screen__xls) {
    nav.flyout > ul.vertical {
        flex-direction: row;

        .level0 a.level-top {
            position: relative;
            padding: 1.8rem 1.6rem;
            border-radius: $flyout-radius;
            font-size: getSize(p);

            &::after {
                transition: transform $flyout-transition;
            }
        }

        .level0.parent a.level-top {
            border-radius: $flyout-radius $flyout-radius 0 0;
            transition: background $flyout-transition, box-shadow $flyout-transition;

            // Adds overlay to hide bottom shadow
            // This prevents animation issues
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1.8rem;
                background-color: white;
                transform: translateY(100%);
                pointer-events: none;
            }
        }

        .level0.dropdown-open a.level-top {
            background-color: $flyout-background;
            box-shadow: $flyout-dropdown;
            color: $color-theme;

            &::after {
                transform: rotate(180deg);
            }
        }

        ul.is-dropdown-submenu.menu {
            display: block !important;
        }
    }

    // Dropdown
    .dropdown-menu-flyout {
        pointer-events: none;
        display: flex;
        position: absolute;
        top: 5.8rem;
        right: 10.2rem;
        left: 8rem;
        min-height: 26rem;
        padding: 2rem 1rem;
        overflow: hidden;
        border-radius: 0 $flyout-radius $flyout-radius $flyout-radius;
        background-color: $flyout-background;
        box-shadow: $flyout-dropdown;
        opacity: 0;
        transition: opacity $flyout-transition;
        z-index: 99;

        @include min-screen($screen__xls) {
            left: 9rem;
            right: 10rem;
        }

        li a {
            font-family: map-get(map-get($font, family), heading);
            font-size: map-get(map-get($font, size), h6);
            font-weight: map-get(map-get($font, weight), bold);
            line-height: map-get(map-get($font, line-height), h6);
            color: $color-theme;
            border-radius: 0.5rem;

            &:hover {
                color: $color-theme;
            }
        }

        > ul.menu {
            width: 20rem;
            padding-right: 2rem;

            @media #{mq(xl)} {
                width: 30rem;
            }

            > li {
                position: relative;

                > a {
                    margin: 0;
                    padding: 0.8rem;
                    width: 100%;
                }

                > a:hover {
                    transition: background $flyout-transition;
                }

                a::before {
                    display: inline-block;
                    height: 1.1rem;
                    width: 0;
                    overflow: hidden;
                    margin-right: 0.6rem;
                    transform: rotate(-90deg);
                    background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z" fill="#{$color-accent-dark}"/></svg>') center / 125% no-repeat;
                    transition: width $flyout-transition, margin $flyout-transition;
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    right: -2rem; // Parent padding + this width
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6.5px 0 6.5px 0;
                    border-color: transparent transparent transparent #ffffff;
                    z-index: 1;
                    transform: translateY(-50%);
                    transition: border $flyout-transition, right $flyout-transition;
                }

            }

            li a::before,
            li::after {
                content: '';
            }

            li:hover::after {
                border-width: 6.5px 0 6.5px 13px;
                right: -3.3rem; // Parent padding + this width
            }

            li:hover a::before {
                width: 1.8rem;
                margin-right: 0.6rem;
            }
        }

        .dropdown-content-box {
            cursor: pointer;
            position: relative;
            flex: 1 0;
            margin: -2rem -1rem -2rem 0; // Compensating for the padding of the wrapper
            background-size: cover;
            background-position: top right;
        }

        .title {
            font-family: map-get(map-get($font, family), heading);
            font-size: map-get(map-get($font, size), h3);
            font-weight: map-get(map-get($font, weight), bold);
            line-height: map-get(map-get($font, line-height), h3);
            margin-bottom: 2.5rem;
            color: $color-theme;
        }

        .description {
            max-width: 32rem;
            font-family: map-get(map-get($font, family), body);
            font-size: map-get(map-get($font, size), p);
            font-weight: map-get(map-get($font, weight), regular);
            line-height: map-get(map-get($font, line-height), p);
            color: getColor(gray, dark);
        }

        .actions {
            position: relative;
            margin-top: 2rem;
            z-index: 1;
        }

        .content {
            width: 50%;
            margin: 2rem 0 0 3rem;
            color: $color-theme;

            @media #{mq(xl)} {
                margin: 3rem 0 0 4.6rem;
            }
        }

        .tablet-close-button {
            $size: 3.5rem;
            position: absolute;
            top: 1rem;
            right: 1.3rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: $size;
            height: $size;
            padding: 0.2rem;
            border-radius: $size;
            border: 2px solid $color-theme;
            background-color: $color-theme;
            color: $color-on-theme;
            line-height: 2.9rem;
            text-align: center;
            z-index: 2;

            @media #{mq(xl)} {
                display: none;
            }

            &::before {
                content: 'X';
                color: inherit;
                font-size: getSize(p);
            }

            &:focus,
            &:hover {
                background-color: $color-on-theme;
                color: $color-theme;
            }
        }
    }

    .dropdown-open .dropdown-menu-flyout {
        pointer-events: all;
        opacity: 1;
    }

    // Hover effect
    .hover-overlays {
        &,
        .hover-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .hover-overlay {
            width: 100%;
            height: 100%;
            background: white linear-gradient(90deg, getColor(body), #ffffff); // Fallback
            background-size: cover;
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity $flyout-transition;

            &.active {
                opacity: 1;
            }
        }
    }
}

@include max-screen($screen__xls) {
    nav.flyout .dropdown-content-box {
        display: none;
    }

    nav.flyout .tablet-close-button {
        display: none;
    }
}

// IE button focus fix
.navigation.flyout .hover-overlay a.button {
    &,
    &:active,
    &:hover,
    &:focus,
    &:visited {
        color: #ffffff;
    }
}
