// vars & extends for theme
// Any styles repeated should be here

// TODO: maybe? add sub styles
@function getShadow($style: theme) {
    // base is theme
    $shadow: 0 11px 26px rgba(199, 204, 206, .3),
             0 10px 11px rgba(0, 0, 10, .07);
    @if $style = secondary {
        $shadow: 0 11px 26px rgba(199, 204, 206, .3),
                 0 10px 11px rgba(0, 0, 10, .07);
    }
    @if $style = accent {
        $shadow: 0 11px 26px rgba(199, 204, 206, .3),
                 0 10px 11px rgba(0, 0, 10, .07);
    }
    @return $shadow;
}
