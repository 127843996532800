body.customer-account-create {
    .page-main {
        background: #fff;
        padding: 10px 20px 20px;
        border-radius: 13px;
        margin-bottom: 80px;
        .columns {
            margin-bottom: 0;
            .column.main {
                padding: 10px 3px 20px;
                form {
                    display: flex;
                    flex-wrap: wrap;
                    .left-side, .right-side {
                        width: 50%;
                        display: flex;
                        flex-wrap: wrap;
                        max-width: 544px;
                        .fieldset {
                            width: 100%;
                            padding: 0;
                            .legend {
                                margin-bottom: 20px;
                            }
                            .field-name-prefix .control {
                                display: flex;
                                margin-top: .8rem;
                                width: 100%;
                                flex-direction: column;

                                .control-input{
                                    max-width: 100px;
                                }
                            }

                            .field {
                                .nested {
                                    .required {
                                        label::after {
                                            content: '';
                                            margin: 0;
                                            color: $color-red10;
                                            font-size: 1.2rem;
                                            position: relative;
                                            top: -.2rem;
                                            left: .1rem;
                                        }
                                    }
                                    :not(.required) {
                                        label {
                                            display: flex;
                                            gap: 0.3rem;
                                            align-items: center;
                                            &::after {
                                                content: ' (optioneel)';
                                                font-size: 50%;
                                            }
                                        }
                                    }
                                }

                                .label {
                                    float: left;
                                    margin-top: 2px;
                                    margin-bottom: 0;
                                    line-height: 20px;
                                    text-align: left;
                                    padding-right: 0;

                                    &[for="prefix"] {
                                        min-width: 118px !important;
                                    }
                                }
                                .control {
                                    max-width: 373px;
                                    float: left;
                                }
                                .nested {
                                    float: left;
                                    width: 100%;
                                    margin-top: 20px;

                                    .field {
                                        display: flex;
                                        flex-direction: column;

                                        .label {
                                            margin-top: 8px;
                                        }
                                    }
                                }
                                .field.zip {
                                    float: left;
                                    margin-right: 20px;
                                    margin-bottom: 0;

                                    .control {
                                        width: 125px;
                                    }
                                }
                                .field.additional {
                                    float: left;
                                    margin: 0 26px 0px 0;

                                    .control {
                                        width: 90px;
                                    }

                                    .label {
                                        position: relative;
                                        overflow: visible;
                                        width: 48px;
                                        min-width: 0;
                                        &[for="street_2"] {
                                            &:before {
                                                content: 'Huisnr';
                                                font-size: 12px;
                                                font-weight: bold;
                                                color: #3e4890;
                                                margin-top: 2px;
                                                margin-bottom: 0;
                                                line-height: 20px;
                                                text-align: left;
                                            }
                                        }
                                        &[for="street_3"] {
                                            width: 37px;
                                            &:before {
                                                content: 'Toev.';
                                                font-size: 12px;
                                                font-weight: bold;
                                                color: #3e4890;
                                                margin-top: 2px;
                                                margin-bottom: 0;
                                                line-height: 20px;
                                                text-align: left;
                                            }
                                        }
                                        span {
                                            display: none;
                                        }
                                    }
                                    &:last-of-type {
                                        margin: 0;
                                    }
                                }
                                &.fullname {
                                    width: 100%;
                                    > .control {
                                        max-width: 100%;
                                    }
                                    .field-name-firstname {
                                        float: left;
                                        clear: none;
                                        width: calc(100% - 233px);
                                        .control {
                                            max-width: 177px;
                                        }
                                    }
                                    .field-name-prefix,
                                    .field-name-middlename {
                                        float: left;
                                        .label {
                                            max-width: 92px;
                                            min-width: 0;
                                        }
                                        .control {
                                            max-width: 100px;
                                        }
                                    }
                                    .field-name-prefix {
                                        width: 100%;
                                    }
                                    .field-name-lastname {
                                        float: left;
                                        width: 100%;
                                    }
                                }
                            }
                            &.create.account {
                                .field {
                                    .label {
                                        width: 147px;
                                        margin-top: 4px;
                                    }
                                    .control {
                                        width: 341px;
                                    }
                                }
                            }
                            .additional-container {
                                background: #f5f6f8;
                                padding: 20px;
                                max-width: 490px;
                                border-radius: 13px;
                                .field {
                                    .control {
                                        max-width: 325px;
                                    }
                                    .label {
                                        width: 127px;
                                    }
                                }
                            }
                            &.address {
                                margin-bottom: 0;
                                padding-top: 30px;
                                position: relative;
                                margin-top: 10px;
                                &:before {
                                    content: '';
                                    height: 1px;
                                    width: 100%;
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    background: #e4e4e4;
                                    max-width: 491px;
                                }
                            }
                        }
                        .bottom-section {
                            display: flex;
                            align-self: flex-end;
                            flex-wrap: wrap;
                        }
                    }
                    .actions-toolbar {
                        margin: 0 0 15px;
                        > .primary {
                            min-width: 225px;
                            button {
                                padding: 11px 25px 11px 8px;
                            }
                            a {
                                padding: 11px 10px;

                            }
                            margin-right: 10px;
                        }
                        > .secondary {
                            min-width: 225px;
                            margin-bottom: 10px;
                            margin-right: 38px;
                            a {
                                padding: 11px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(1200px) {
    body.customer-account-create {
        .page-main {
            .columns {
                .column.main {
                    form {
                        .left-side, .right-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(768px) {
    body.customer-account-create {
        .page-main {
            .columns {
                .column.main {
                    form {
                        .left-side, .right-side {
                            .fieldset, .fieldset.create.account {
                                .field, .field .field.zip,
                                .field .field.additional,
                                .field.fullname .field-name-prefix,
                                .field.fullname .field-name-firstname,
                                .field.fullname .field-name-middlename,
                                .field.fullname .field-name-lastname {
                                    margin-bottom: 10px;
                                    float: left;
                                    width: 100%;
                                    .label {
                                        width: 100%;
                                        &[for="street_2"],
                                        &[for="street_3"] {
                                            width: 100%;
                                            height: 20px;
                                        }
                                    }
                                    .control {
                                        width: 100%;
                                        max-width: 495px;
                                    }
                                }
                                .additional-container {
                                    max-width: 495px;
                                    .field {
                                        margin-bottom: 10px;
                                        float: left;
                                        width: 100%;
                                        .label {
                                            width: 100%;
                                        }
                                        .control {
                                            width: 100%;
                                            max-width: 495px;
                                        }
                                    }
                                }
                                .field.required.fullname.customer-name-prefix-middlename {
                                    margin:0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media #{mq(md, max)} {
    body.customer-account-create .page-main .columns .column.main form .left-side {
        .fieldset.create.info {
            margin-bottom: 0;
        }

        .fieldset.address {
            .field.street.required {
                .control {
                    margin-bottom: 0;
                }
            }

            .field.zip.required {
                margin-bottom: 0.5rem;
            }

            .field.additional.required {
                margin-bottom: 0.4rem;
            }

            .field.region {
                margin-top: 0.6rem;
            }

            .nested {
                margin-top: 0.2rem;
            }
        }
    }
}
