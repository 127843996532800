.downloadable.samples {
    margin-bottom: $indent__base;

    dd {
        margin: 0 0 $indent__s;
    }
}

.table-downloadable-products {
    .product-name {
        margin-right: 15px;
    }
}

.field.downloads {
    .price-container {
        display: inline;
        white-space: nowrap;

        &:before {
            content: ' + ';
            display: inline;
        }
    }

    .price-excluding-tax {
        display: inline;

        &:before {
            content: ' ('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }
    }

    .price-including-tax {
        @include lib-font-size(14);
        display: inline;
        font-weight: $font-weight__semibold;
    }

    .sample.link {
        float: right;
    }
}

.page-product-downloadable {
    .product-add-form {
        @extend .abs-add-clearfix;
        clear: both;
        margin-bottom: $indent__m;
    }

    .product-options-bottom {
        .field.qty {
            display: none !important;
        }
    }

    .product-options-wrapper {
        .fieldset {
            .legend.links-title {
                @extend .abs-visually-hidden;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-product-downloadable {
        .product-options-wrapper {
            float: left;
            width: 55%;
        }

        .product-options-bottom {
            float: right;
            width: 40%;

            .field.qty {
                & + .actions {
                    padding-top: 0;
                }
            }
        }
    }
}
