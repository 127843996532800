.amdelivery-main-container {
    order: -1;
    margin-top: 0 !important;

    .field {
        &.date {
            display: flex;
            align-items: center;

            .label {
                margin: 0;
                padding-bottom: 0;
            }
        }

        .control {
            max-width: 16rem;
            position: relative;
            display: inline-block;
            width: 100%;
            margin-left: 1rem;

            .input-text._has-datepicker {
                cursor: pointer;
                width: 100%;
            }

            .ui-datepicker-trigger {
                position: absolute;
                right: 0;
                top: .2rem;

                &::before {
                    color: $color-theme
                }
            }
        }
    }
}

#ui-datepicker-div {
    .ui-datepicker-header {
        .ui-datepicker-prev, .ui-datepicker-next {
            top: 1.1rem
        }

        .ui-datepicker-title {
            display: flex;
        }
    }

    .ui-datepicker-current {
        display: none;
    }

    td.ui-datepicker-unselectable,
    td[data-handler="selectDay"] {
        padding: 0;

        a, span {
            padding: 1.2rem 1.4rem !important;
            text-align: center;
            line-height: 2;
        }
    }
}
