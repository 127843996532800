@import url('https://fonts.googleapis.com/css?family=Asap|Lora|Passion+One|Patrick+Hand&display=swap');

@font-face {
    font-family: 'VAGRoundedLight';
    src: url('../fonts/vagroundedstd-light.woff2') format('woff2'),
    url('../fonts/vagroundedstd-light.woff') format('woff'),
    url('../fonts/vagroundedstd-light.ttf') format('truetype');
    font-weight: light;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'VAGRoundedBold';
    src: url('../fonts/vagroundedstd-bold.woff2') format('woff2'),
    url('../fonts/vagroundedstd-bold.woff') format('woff'),
    url('../fonts/vagroundedstd-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
