.form.send.friend {
    @extend .abs-add-fields;
}

.action.mailto.friend {
    margin-left: -7px;
    @include lib-icon-font(
        $_icon-font-content: $icon-envelope,
        $_icon-font-size: 28px,
        $_icon-font-line-height: 28px,
        $_icon-font-vertical-align: top
    );

    span {
        display: inline-block;
        line-height: 28px;
        vertical-align: top;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .form.send.friend {
        @extend .abs-add-fields-desktop;
    }
}
