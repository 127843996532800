$button: (
    min-width: 8em,
    max-width: 100%,
    radius: 0.4rem,
    padding: 0.5rem 1.2rem,
    border: 0.1rem solid,
    shadow-level: 1,
    colors: (
        default: getColor(theme),
        primary: getColor(cta)
    ),
    sizes: (
        default: 1.6rem,
        xs: 1.2rem,
        sm: 1.4rem,
        md: 1.6rem,
        lg: 1.8rem,
        xl: 2rem
    )
) !default;
