$config-colors: (
    theme: (
        base: #3e4890,
        light: #6cbfec,
        dark: #223786,
        contrast: #ffffff
    ),
    secondary: (
        base: #539c39,
        light: #95be27,
        dark: #539c39,
        contrast: #ffffff
    ),
    accent: (
        base: #fabe47,
        light: #fcc61b,
        dark: #ef7d28,
        contrast: #ffffff
    ),
    cta: (
        base: #fabe47,
        light: #fcc61b,
        dark: #ef7d28,
        contrast: #ffffff
    ),
    gray: (
        base: #f5f6f8,
        light: #e4e4e4,
        dark: #435063,
        contrast: #ffffff
    ),
    body: #f5f6f8,
    text: (
        base: #435063,
        light: #435063,
        dark: #0b1455,
        contrast: #ffffff
    ),
    link: (
        base: #435063,
        focus: #0b1455
    ),
    gradient: (
        base: linear-gradient(to left, #223786, #6cbfec),
        secondary: linear-gradient(to left, #95be27, #539c39),
        cta: linear-gradient(to left, #fcc61b, #ed7424)
    ),
    bg: (
        base: #f4f6f8,
        dark: #000000
    ),
    border: (
        base: #f5f6f8,
        menu: #e4e4e4,
        form: #435063,
        contrast: #ffffff
    ),
    status: (
        base: #21b0ff,
        info: #21b0ff,
        danger: #e91c01,
        warning: #fdd949
    )
);

// Theme variables as var
$color-theme: getColor(theme);
$color-theme-dark: getColor(theme, dark);
$color-theme-light: getColor(theme, light);
$color-on-theme: getColor(theme, contrast);
$gradient-theme: getColor(gradient);

$color-secondary: getColor(secondary);
$color-secondary-dark: getColor(secondary, dark);
$color-secondary-light: getColor(secondary, light);
$color-on-secondary: getColor(secondary, contrast);
$gradient-secondary: getColor(gradient, secondary);

$color-accent: getColor(accent);
$color-accent-dark: getColor(accent, dark);
$color-accent-light: getColor(accent, light);
$color-on-accent: getColor(accent, contrast);

$color-cta: getColor(cta);
$color-on-cta: getColor(cta, contrast);
$gradient-cta: getColor(gradient, cta);

$color-body: getColor(body);
$color-text: getColor(text);
$color-text-secondary: getColor(text, dark);
$color-text-muted: getColor(text, light);
$color-text-disabled: getColor(text, disabled);
$color-link: getColor(link);
$color-link-focus: getColor(link, focus);

$color-info: getColor(status, info);
$color-success: getColor(status, success);
$color-warning: getColor(status, warning);
$color-danger: getColor(status, danger);
