.tabs.style-card .nav-item > a[aria-selected="true"] {
    position: relative;

    &::before {
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        margin-top: 10px;
        margin-left: -15px;
        content: '';
        -webkit-transform: rotate(31deg);
        transform: rotate(31deg);
        border: 6px solid $color-accent-dark;
        border-right: 3px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 4px solid transparent;
        border-radius: 2px 1px 1px 0;
    }
}
