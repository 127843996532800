 // !important = override inline style

table.b-table.b-table-stacked > tbody.flex-grid {
    display: flex !important;
    > tr > td {
        padding: 0 !important;
    }
    &.flex-fill > .cell {
        &,
        > td,
        > td > div {
            box-sizing: border-box;
            display: flex;
            width: 100%;
        }
        > td > div > .card {
            flex: 1 1 auto;
        }
    }
}
