$input: (
    height: 3.5rem,
    margin: 0,
    padding: 0.9rem 1.6rem,
    border: 1px solid getColor(border, menu),
    radius: 1rem,
    color: white,
    font-size: map-get(map-get($font, size), small),
    placeholder: getColor(text, muted),
    hover: (
        border: #929292,
        bg: white,
        color: getColor(text, base)
    ),
    focus: (
        border: getColor(border, form),
        bg: white,
        color: getColor(text, base)
    ),
    disabled: (
        border: getColor(border, menu),
        bg: getColor(bg, base),
        color: getColor(text, base),
        opacity: 1
    ),
    error: (
        border: getColor(status, danger),
        bg: getColor(bg, base)
    ),
);

$form-padding: map-get($input, padding);

// Form select arrow
$icon-arrow-select-color: $color-theme;
$icon-arrow-select: svg-url('<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#{$icon-arrow-select-color}" d="M7 10l5 5 5-5z"/><path fill="none" d="M0 0h24v24H0z"/></svg>');

$icon-error-color: $color-danger;
$icon-error: svg-url('<svg width="24" height="24" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="#{$icon-error-color}" d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"/></svg>');

.login-container .fieldset::after {
    margin: 0;
}
