.has-status {
     .wrapper {
         width: 100%;
         border-radius: 1.3rem;
         background-color: transparent;
         padding-top: 2.5rem;
         padding-bottom: 2.3rem;
         text-align: center;
         margin: auto;
     }

     .status-icon {
         display: flex;
         align-items: center;
         justify-content: center;

         &::before {
             content: "\21";
             display: flex;
             align-items: center;
             justify-content: center;
             font-family: "FontAwesome";
             background-color: $color-theme;
             color: #fff;
             font-size: 2.5rem;
             height: 4.4rem;
             width: 4.4rem;
             border-radius: 100px;
         }
     }

     .error-icon::before {
         background-color: $danger;
         content: "\f00d";
     }

    .status-text {
        height: 1.7rem;
        width: max-content;
        color: $color-theme;
        font-family: Muli;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1.7rem;
        margin-top: 1rem;
        text-align: center;
        margin-left: auto;
        margin-bottom: 0;
        margin-right: auto;
    }
 }

.loader-wrapper {
    .lds-roller,
    .lds-roller div,
    .lds-roller div:after {
        box-sizing: border-box;
    }
    .lds-roller {
        display: inline-block;
        position: relative;
        width: 4rem;
        height: 4rem;
    }
    .lds-roller div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 2rem 2rem;
    }
    .lds-roller div:after {
        content: "";
        display: block;
        position: absolute;
        width: .36rem;
        height: .36rem;
        border-radius: 50%;
        background: $color-theme;
        margin: -.18rem 0 0 -.18rem;
    }
    .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
        top: 3.131371rem;
        left: 3.131371rem;
    }
    .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
        top: 3.385641rem;
        left: 2.8rem;
    }
    .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
        top: 3.545481rem;
        left: 2.41411rem;
    }
    .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
        top: 3.6rem;
        left: 2rem;
    }
    .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
        top: 3.545481rem;
        left: 1.58589rem;
    }
    .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
        top: 3.385641rem;
        left: 1.2rem;
    }
    .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
        top: 3.131371rem;
        left: .868629rem;
    }
    .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
        top: 2.8rem;
        left: .614359rem;
    }
    @keyframes lds-roller {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
