.swiper-pagination-bullet {
    box-sizing: border-box;
    border: 1px solid $color-theme;
    background-color: transparent;
    opacity: 1;
    &-active {
        box-sizing: border-box;
        width: 10px;
        height: 10px;
        background-color: $color-theme;
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 1px 6px;
    &-active {
        margin: 0 6px;
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -2px;
}
