@mixin loading-spinner($activeColor: #fff, $selector: "&::before", $time: 1.5s) {
    @keyframes spinner {
        0% {
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }

        100% {
            transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
    }
    
    animation-play-state: running;
    opacity: 1;
    position: relative;
    &.-paused { 
        animation-play-state: paused; 
        opacity: 0.2;
        transition: opacity linear 0.1s;
    }
    &.show {
        display:inline;
    }
    &.hide {
        display:none;
    }
    #{$selector} {
        animation: $time linear infinite spinner;
        animation-play-state: inherit;
        border: solid 3px $color-theme;
        border-bottom-color: #{$activeColor};
        border-radius: 50%;
        content: "";
        height: 1rem;
        left: 50%;
        opacity: inherit;
        position: absolute;
        top: 50%; 
        transform: translate3d(-50%, -50%, 0);
        width: 1rem;
        will-change: transform;
    }
}


.loading-spinner {
    @include loading-spinner;
    height: 1rem;
    width: 1rem;
    margin: 1rem;
    margin-right: 2rem;
}