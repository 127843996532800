// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='tabs'] {
    .tabs-navigation {
        display: flex;
        font-size: 0;
        margin: 0 0 -1px;
        padding: 0;
        flex-direction: row;

        @media only screen and (max-width: $screen__m) {
            flex-direction: column;
        }

        li:first-child {
            margin-left: 0 !important;
        }

        li.tab-header {
            background-color: #d4d4d4; // TODO: Make it a variable
            border-bottom: 0;
            border-radius: 1.5rem 1.5rem 0 0 !important;
            display: inline-block;
            margin: 0;
            max-width: 100%;
            overflow-wrap: break-word;
            position: relative;
            word-wrap: break-word;
            z-index: 1;
            margin-right: 1.5rem;

            @media only screen and (max-width: $screen__m) {
                border-radius: 0 !important;
                background-color: getColor(theme, contrast);
                margin-bottom: .3rem;
                margin-right: 0;
            }

            &:not(:first-child) {
                margin-left: -1px;
            }

            a.tab-title {
                border-right: 0;
                color: #848383;  // TODO: Make it a variable
                cursor: pointer !important;
                display: block;
                font-size: 14px;
                font-weight: 600;
                padding: 1.4rem 2rem;
                position: relative;
                transition: all .3s;
                vertical-align: middle;
                white-space: normal;
                text-decoration: none;

                @media only screen and (max-width: $screen__m) {
                    color: getColor(theme, base);

                    &:after {
                        position: absolute;
                        right: 0;
                        padding: 0 1.5rem;
                        content: "\f0d7";
                        font-family: FontAwesome;
                        color: #3e4890;
                        transition: -webkit-transform .6s cubic-bezier(0,.18,.21,1);
                        transition: transform .6s cubic-bezier(0,.18,.21,1);
                        transition: transform .6s cubic-bezier(0,.18,.21,1), -webkit-transform .6s cubic-bezier(0,.18,.21,1);
                    }

                }

                span.tab-title {
                    display: block;

                    @media only screen and (max-width: $screen__m) {
                        display: inline-block;
                    }
                }
            }

            &.ui-state-active {
                background: $color-white;
                z-index: 5;

                a.tab-title {
                    color: getColor(theme, base);
                    position: relative;
                    transition: all .3s;

                    @media only screen and (max-width: $screen__m) {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    .tabs-content {
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        z-index: 5;
        border: none;

        [data-content-type='tab-item'] {
            box-sizing: border-box;
            min-height: inherit;

            &:not(:first-child) {
                display: none;
            }
        }
    }

    &.tab-align-left {
        .tabs-content {
            border-top-left-radius: 0 !important;
        }
    }
    &.tab-align-right {
        .tabs-content {
            border-top-right-radius: 0 !important;
        }
    }
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $screen__m) {
    [data-content-type='tab-item'] {
        background-attachment: scroll !important;
    }
}
