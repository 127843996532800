.page-header {
    position: relative;
    z-index: 6;
    margin-bottom: 10px;
    background-color: white;

    @media #{mq(sm)} {
        margin-bottom: 20px;
    }

    .header.content {
        padding-bottom: 1.4rem;

        @media #{mq(md)} {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        > .container {
            position: static;

            > .flex-grid {
                align-items: center;
                flex-flow: row nowrap;
            }
        }
    }

    .panel.header {
        display: none;
    }
}


@include max-screen($screen__xls) {
    .nav-open .page-header {
        z-index: 0;
    }
}

.message {
    &.global.cookie,
    &.message.company-warning {
        position: relative;
        z-index: 1;
        background-color: white;

        > span,
        > .content {
            display: block;
            max-width: 1250px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &.message.company-warning._hidden {
        display: none;
    }
}
