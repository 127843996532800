footer.page-footer .footer.newsletter {
    padding: 2.5rem 0 5.5rem;
    color: getColor(theme);
    border-radius: 1.5rem 1.5rem 0 0;
    background-color: white;

    @media #{mq(sm, min)} {
        padding-bottom: 2rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 0.4rem;
        color: getColor(theme);
    }

    .block.newsletter {
        width: 100%;
        max-width: 100%;

        @media #{mq(md)} {
            max-width: calc((100% / 3) * 2);
            margin: 0 0 0 auto;
        }

        @media #{mq(lg)} {
            display: flex;
            justify-content: space-between;
            max-width: 80%;
        }

        @media #{mq(xl)} {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .form.subscribe {
            display: flex;
        }
    }

    .description {
        width: 100%;

        @media #{mq(sm)} {
            margin: 2rem 2rem 2rem 0;
        }

        @media #{mq(lg)} {
            width: 40%;
        }

        .title {
            display: block;
            @include lib-heading(h4);
        }
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 60%;
        padding: 0 0 5rem 0;

        @media #{mq(sm, min)} {
            padding-bottom: 0;
        }

        @media #{mq(lg, max)} {
            width: 100%;
        }

        small {
            margin-top: 1rem;
            color: getColor(theme);
        }
    }

    .block-subscribe > div {
        @media #{mq(md, max)} {
            width: 100%;
        }
    }

    .block-subscribe {
        margin-top: 1.6rem;
        margin-bottom: 0.3rem;

        + * {
            font-size: 1rem;
            padding-left: 1rem;
        }

        button.button {
            min-width: 13.5rem;
        }

        .input-container {
            position: static;
            width: 100%;
        }

        .input-box {
            float: left;
            width: calc(100% - 2rem); // Button width + 2rem margin

            input {
                padding-left: 1.5rem;
            }
        }

        .actions {
            width: auto;
            text-align: right;

            .button {
                height: 3.5rem;
                min-height: initial;
                padding: 0;
            }
        }
    }
}
