//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size                              : 18px !default;
$checkout-progress-bar__font-weight                            : $font-weight__light !default;
$checkout-progress-bar__margin                                 : $indent__base !default;

$checkout-progress-bar-item__background-color                  : $color-gray-middle1 !default;
$checkout-progress-bar-item__border-radius                     : 6px !default;
$checkout-progress-bar-item__color                             : $primary__color !default;
$checkout-progress-bar-item__margin                            : $indent__s !default;
$checkout-progress-bar-item__width                             : 185px !default;
$checkout-progress-bar-item__active__background-color          : $color-orange-red1 !default;
$checkout-progress-bar-item__complete__color                   : $link__color !default;

$checkout-progress-bar-item-element__width                     : 38px !default;
$checkout-progress-bar-item-element__height                    : $checkout-progress-bar-item-element__width !default;

$checkout-progress-bar-item-element-inner__background-color    : $page__background-color !default;
$checkout-progress-bar-item-element-inner__color               : $checkout-progress-bar-item__color !default;
$checkout-progress-bar-item-element-outer-radius__width        : 6px !default;
$checkout-progress-bar-item-element-inner__width               : $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2) !default;
$checkout-progress-bar-item-element-inner__height              : $checkout-progress-bar-item-element-inner__width !default;
$checkout-progress-bar-item-element-inner__active__content     : $icon-checkmark !default;
$checkout-progress-bar-item-element-inner__active__font-size   : 28px !default;
$checkout-progress-bar-item-element-inner__active__line-height : 1 !default;

//
//  Checkout Progress Bar
//  ---------------------------------------------

.opc-progress-bar {
    display: inline-block;
    @extend .abs-reset-list;
    counter-reset: i;
    font-size: 0;
}

.opc-progress-bar-item {
    @include lib-css(margin, 0 0 $checkout-progress-bar-item__margin);
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 50%;
    // Horizontal line
    &::before {
        @include lib-css(background, $checkout-progress-bar-item__background-color);
        @include lib-css(top, $checkout-progress-bar-item-element__width/2);
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        width: 100%;
    }
    &:first-child::before {
        @include lib-css(border-radius, 0);
    }
    &:last-child::before {
        @include lib-css(border-radius, 0 $checkout-progress-bar-item__border-radius $checkout-progress-bar-item__border-radius 0);
    }
    > span {
        display: inline-block;
        padding-top: 45px;
        width: 100%;
        word-wrap: break-word;
        @include lib-typography(
            $_color       : $checkout-progress-bar-item__background-color,
            $_font-family : false,
            $_font-size   : $checkout-progress-bar__font-size,
            $_font-style  : false,
            $_font-weight : $checkout-progress-bar__font-weight,
            $_line-height : false
        );
        // Item element
        &::before,
        &::after {
            @include lib-css(background, $checkout-progress-bar-item__background-color);
            @include lib-css(height, $checkout-progress-bar-item-element__height);
            @include lib-css(margin-left, -($checkout-progress-bar-item-element__width/2));
            @include lib-css(width, $checkout-progress-bar-item-element__width);
            border-radius: 50%;
            content: '';
            left: 50%;
            position: absolute;
            top: 0;
        }
        // Item element inner
        &::after {
            @include lib-css(background, $checkout-progress-bar-item-element-inner__background-color);
            @include lib-css(height, $checkout-progress-bar-item-element-inner__height);
            @include lib-css(margin-left, -($checkout-progress-bar-item-element-inner__width/2));
            @include lib-css(top, $checkout-progress-bar-item-element-outer-radius__width);
            @include lib-css(width, $checkout-progress-bar-item-element-inner__width);
            content: counter(i);
            counter-increment: i;
            @include lib-typography(
                $_color       : $checkout-progress-bar-item-element-inner__color,
                $_font-family : false,
                $_font-size   : $checkout-progress-bar__font-size,
                $_font-style  : false,
                $_font-weight : $font-weight__semibold,
                $_line-height : false
            );
        }
    }
    &._active {
        &::before {
            background: $checkout-progress-bar-item__active__background-color;
        }
        > span {
            @include lib-css(color, $checkout-progress-bar-item__color);
            &::before {
                @include lib-css(background, $checkout-progress-bar-item__active__background-color);
            }
            &::after {
                @include lib-css(content, $checkout-progress-bar-item-element-inner__active__content);
                @include lib-css(font-family, $icons__font-name);
                @include lib-css(line-height, $checkout-progress-bar-item-element-inner__active__line-height);
                @include lib-font-size($checkout-progress-bar-item-element-inner__active__font-size);
            }
        }
    }
    &._complete {
        cursor: pointer;
        > span {
            @include lib-css(color, $checkout-progress-bar-item__color);
            &::after {
                @include lib-css(content, $checkout-progress-bar-item-element-inner__active__content);
                @include lib-css(font-family, $icons__font-name);
                @include lib-css(line-height, $checkout-progress-bar-item-element-inner__active__line-height);
                @include lib-font-size($checkout-progress-bar-item-element-inner__active__font-size);
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________
.opc-progress-bar {
    width: 100%;
    text-align: center;
    position: relative;
    &::before {
        background: getColor(gray, 300);
        top: 19px;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        width: 100%;
    }
}

.opc-progress-bar-item {
    &::before {
        background-color: getColor(gray, 300);
    }
    > span {
        color: $color-theme;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 700;
        text-align: center;
        &::after {
            color: getColor(gray, 500);
        }
    }
    &._active {
        &::before {
            background-color: $color-theme;
        }
        > span {
            color: $color-theme;
            &::before {
                background-color: $color-theme;
            }
            &::after {
                color: $color-theme;
            }
        }
    }
}

.opc-progress-bar-block {
    width: 100%;
    border-radius: 15px;
    background-color: #fff;
    padding-top: 35px;
    padding-bottom: 20px;
    margin-bottom: 20px;

    h1 {
        font-size: 26px;
        font-weight: bold;
        color: $color-theme;
        padding-left: 20px;
        padding-bottom: 22px;
        margin: 0;
        line-height: 0;
        display: inline-block;
    }

    .shop-safely {
        display: inline-block;
        float: right;
        padding-right: 2rem;
        position: relative;
        bottom: 7px;

        img {
            width: 1.6rem;
            padding-right: 0.3rem;
        }

        span {
            vertical-align: super;
            color: $color-text-muted;
        }
    }
}

.opc-progress-bar-item._complete > span {
    color: $color-theme;
    &::before {
        background-color: $color-theme;
    }
}

li.opc-progress-bar-item._active {
    position: relative;
    &::after {
        content: "";
        z-index: 1;
        position: absolute;
        top: 17px;
        right: -8px;
        display: block;
        width: 0;
        height: 0;
        border-radius: 2px 1px 1px 0px;
        border: 6px solid transparent;
        border-top-color: $color-theme;
        border-right-width: 3px;
        border-left-width: 4px;
        transform: rotate(-86deg);
    }
}
