// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

@media only screen and (max-width: $screen__m - 1) {
    .pagebuilder-column-group,
    .pagebuilder-column-group .pagebuilder-column-line {
        flex-wrap: wrap;
    }
}
