.list {
    &-unstyled {
        margin: 0;
        padding: 0;
        list-style: none;
        // extra unset for Magento-ui
        > li {
            margin-bottom: 0;
        }
    }
}
