footer.page-footer .footer {
    ol,
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    > .newsletter-container {
        @extend .container;
    }
}
