.main .webforms {

    .fieldset,
    .field {
        padding: 0;
        clear: both;
    }

    .actions-toolbar {
        display: flex;
        justify-content: flex-end;
    }

    .fieldset > .field:not(.choice) > .label {
        padding: 0 1.5rem 0 0;
        width: 100%;

        @media #{mq(lg)} {
            width: 30%;
        }
    }

    .fieldset > .field:not(.choice) > .control {
        width: 100%;

        @media #{mq(lg)} {
            width: 70%;
        }
    }
}

