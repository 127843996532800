//
//  Variables
//  _____________________________________________

$minicart__border-color: getColor(border, menu) !default;
$minicart__padding-horizontal: $indent__base !default;

$minicart-qty-border-size: 3px !default;
$minicart-qty-size: 23px !default;

//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .subtotal {
        margin: 0 $indent__s $indent__s;
        text-align: right;

        .label {
            @extend .abs-colon;
        }
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: $font-size__l;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            text-align: center;

            > .primary {
                .button.checkout {
                    display: block;
                    margin-bottom: 10px;
                    width: 100%;

                    &.style-next {
                        padding-right: 3em;

                        &::after {
                            right: 2em;
                        }
                    }
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    .product {
        .actions {
            align-self: center;
            margin-left: 10px;
            text-align: right;
            width: auto;

            > .primary,
            > .secondary {
                display: inline;
            }
        }
    }

    .minicart-icon {
        position: relative;
        display: inline-block;
        margin-top: 1rem;
    }

    .action {
        &.close {
            @include lib-button-icon(
                    $icon-remove,
                $_icon-font-size: 2.8rem,
                $_icon-font-line-height: 32px,
                $_icon-font-text-hide: true
            );
            @include lib-button-reset();
            float: right;
            width: 3.5rem;
            height: 3.5rem;
            margin-left: 1rem;
            border-radius: 50%;
            border: 2px solid $color-theme;
            background-color: $color-theme;
            color: $color-on-theme;

            &:hover,
            &:focus {
                border: 2px solid $color-theme;
                background-color: transparent;
                color: $color-theme;
            }
        }

        &.showcart {
            white-space: nowrap;

            .text {
                @extend .abs-visually-hidden;
            }

            .counter.qty {
                @include lib-css(background, $active__color);
                @include lib-css(color, $page__background-color);
                box-sizing: border-box;
                position: absolute;
                top: -15px;
                right: -15px;
                display: inline-block;
                border: $minicart-qty-border-size solid #fff;
                border-radius: 50px;
                min-width: $minicart-qty-size;
                height: $minicart-qty-size;
                padding: 0 2px;
                font-size: 10px;
                line-height: ($minicart-qty-size - ($minicart-qty-border-size * 2));
                text-align: center;
                white-space: normal;
                overflow: hidden;
                @media #{mq(md)} {
                    top: -(($minicart-qty-size - ($minicart-qty-border-size * 2)) / 3);
                    right: -(($minicart-qty-size - ($minicart-qty-border-size * 2)) / 2);
                    border-color: getColor(bg);
                }

                &.empty {
                    display: none;
                }

                .loader > img {
                    @include lib-css(max-width, $minicart-qty-size);
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }
}

.minicart-items-wrapper {
    margin: 37px 0;
}

.minicart-subtotal-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .minicart-subtotal-label {
        font-size: getSize(p);
        color: getColor(theme, base);
        font-weight: getWeight(bold);
        margin-right: 1.2rem;
    }

    .minicart-subtotal-price {
        font-size: getSize(h4);
        color: getColor(theme, base);
        font-weight: getWeight(bold);
    }
}

.minicart-divider {
    margin-top: 1.5rem;
    border-top: 0.1rem solid getColor(border, menu);
}

.minicart-items {
    @include lib-list-reset-styles();

    .product-item {
        margin: 0;
        padding: $indent__base 0;
        border: 0;

        &:not(:first-child) {
            @include lib-css(border-top, 1px solid $minicart__border-color);
        }

        &:first-child {
            padding-top: 0;
        }

        > .product {
            @extend .abs-add-clearfix;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product {
        > .product-item-photo,
        > .product-image-container {
            float: left;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 16px,
                $_icon-font-text-hide: false,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            cursor: pointer;
            position: relative;

            &:after {
                position: static;
                right: $indent__base;
                top: 0;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }
    }

    .product-item-name {
        margin: 0 0 $indent__s;
        font-family: Quicksand;
        font-size: 12px;
        font-weight: bold;
        color: #929292;

        a {
            @include lib-css(color, $link__color);

            span.qty {
                color: #929292;
            }
        }
    }

    .product-item-details {
        .price {
            font-weight: $font-weight__bold;
            font-family: Quicksand;
            font-size: 12px;
            font-weight: bold;
            color: #929292;
        }

        .price-including-tax,
        .price-excluding-tax {
            margin: $indent__xs 0 0;
            font-size: inherit;
            line-height: inherit;
        }

        .weee[data-label] {
            @include lib-font-size(11);

            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            margin-top: $indent__s;
        }

        .primary {
            margin-top: -10px;
            div:last-child {
                margin-top: -10px;
            }
        }
    }

    .product.options {
        .tooltip.toggle {
            @include lib-icon-font(
                    $icon-down,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide: true,
                $_icon-font-margin: -3px 0 0 7px,
                $_icon-font-position: after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 40px;
    }

    .update-cart-item {
        @include lib-font-size(11);
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.delete {
            display: inline-block;
            width: 15px;
            height: 15px;
            background: svg-url('<svg width="24" height="24" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M704 1376V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zM672 384h448l-48-117q-7-9-17-11H738q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5H480q-66 0-113-58.5T320 1464V512h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z" fill="#{getColor(text, light)}"/></svg>') center/cover no-repeat;

            > span {
                @include sr-only;
            }
        }
    }
}

// Offcanvas version
.minicart-wrapper {
    .ui-widget.ui-widget-content {
        @media #{mq(sm)} {
            display: block !important; // JS inline overwrite
        }
    }

    .block-minicart {
        box-sizing: border-box;
        z-index: 101;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        max-width: 390px;
        width: 100%;
        min-height: 100%;
        margin: 0;
        padding: 2rem .8rem;
        box-shadow: -8px 0 31px rgba(0, 0, 0, .15);;
        background-color: #fff;
        color: $color-text-secondary;
        overflow-y: auto;
        overscroll-behavior: contain;
        visibility: hidden;
        transform: translateX(100%);
        transition: transform .3s, visibility 0s linear .3s;
        @media #{mq(md)} {
            padding: 3rem;
        }
    }

    &.active .block-minicart {
        visibility: visible;
        transform: translateX(0);
        transition: transform .3s, visibility 0s;
    }

    .block-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        @media #{mq(md)} {
            padding-left: 0;
        }

        .title {
            margin-bottom: 0;
            color: $color-theme;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .block-content {
        position: relative;
        margin-bottom: 0;
        @media #{mq(md, max)} and (min-width: 320px) and (min-height: 500px) {
            margin-bottom: 80px;
        }

        .minicart-items-wrapper {
            height: auto !important;

            .minicart-items li.product-item .product {
                > .product-item-photo {
                    margin-right: 1.5rem;
                }

                > .product-item-details {
                    padding-left: 0;
                    display: flex;
                    flex-direction: column;

                    .product-item-name {
                        font-weight: bold;
                    }

                    .product-item-pricing {
                        margin-bottom: 10px;

                        .details-qty.qty {
                            display: none;
                        }
                    }

                    .product .actions {
                        margin: 0;
                        float: none;

                        .secondary a::before {
                            @include fa-icon;
                            content: $fa-var-trash;
                        }
                    }
                }

                .product.options.list {
                    margin-bottom: 5px;

                    .label,
                    .values {
                        margin: 0;
                    }
                }
            }
        }

        .minicart-user-prompt {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            text-align: center;

            a {
                margin-bottom: 2px;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    .menu {
        padding: 0 10px;
        font-weight: 700;
        @media #{mq(md)} {
            padding-right: 0;
            padding-left: 0;
        }

        a {
            padding: 1rem 2px;
            color: $color-link;

            &:hover,
            &:focus {
                color: $color-link;
            }

            > .icon {
                $minicart-menu-icon-size: 20px;
                width: $minicart-menu-icon-size;
                height: $minicart-menu-icon-size;
                margin-right: 10px;
                color: $color-accent-dark;
                vertical-align: bottom;
            }
        }

        li:not(:last-child) {
            border-bottom: 1px solid getColor(border, menu);
        }
    }
}
