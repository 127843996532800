.flex-grid {
    &,
    > .cell {
        box-sizing: border-box;
    }
}

.main > [data-content-type="row"] {
    margin: 5rem 0 !important; // override inline style
    padding: 0 !important; // override inline style

    &[data-appearance='full-width'] {
        margin-left: calc(50% - 50vw) !important; // override inline style
    }

    & > div {
        margin: 0 !important; // override inline style
        padding: 0 !important; // override inline style

        & > div:not(.pagebuilder-column-group) > div:not([data-content-type="text"]):not(.tabs-content):not([data-content-type="button-item"]):not([data-element="empty_link"]),
        & > .pagebuilder-column-group > .pagebuilder-column,
        & > .pagebuilder-column-group > .pagebuilder-column-line > .pagebuilder-column {
            margin: 0 2.5rem !important; // override inline style
            padding: 0 !important; // override inline style

            @media #{mq(md, max)} {
                margin: 0 0 5rem 0 !important; // override inline style
            }
        }

        & > .pagebuilder-column-group .pagebuilder-column {
            &:first-of-type {
                margin-left: 0 !important; // override inline style
            }

            &:last-of-type {
                margin-right: 0 !important; // override inline style
            }

            &.d-sm-none {
                @media #{mq(md, max)} {
                    display: none !important; // override inline style
                }
            }
        }
    }
}

.main [data-content-type="row"] > [data-element="inner"] > {
    figure,
    [data-content-type="banner"] {
        @media #{mq(md, max)} {
            margin-bottom: 5rem !important; // override inline style
        }
    }
}


@media #{mq(md, max)} {
    [data-content-type=row][data-appearance=contained] [data-element=inner] {
        justify-content: flex-start !important;
    }
}

.main > [data-content-type="row"] > {
    div >, // Direct descendant
    div > div > div >, // When using columns
    div > div > .pagebuilder-column-line > div > { // When using column-line and columns
        [data-content-type="block"],
        [data-content-type="buttons"],
        [data-content-type="heading"],
        [data-content-type="html"],
        [data-content-type="text"] {

            &:not(.no-padding) {
                padding: $padding-content-mobile !important; // override inline style

                @media #{mq(md)} {
                    padding: $padding-content !important; // override inline style
                }
            }
        }
    }
}



.page-wrapper .main > [data-content-type="row"] > div > [data-element=main] {
    &:nth-last-child(2) {
        padding-bottom: 0 !important;
    }
    &:last-child {
        padding-top: 0 !important;
    }
}

/* screens from iPhone 5 to iPhone 7*/
@media only screen
and (min-device-width : 319px)
and (max-device-width : 667px) {
    .page-wrapper .main > [data-content-type="row"] > div > [data-element=main] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.main > [data-content-type="row"] > div > figure {
    margin: 0 !important; // override inline style
}

.main > [data-content-type="row"] > div > * {
    &:first-child {
        padding-bottom: 0 !important; // override inline style
    }

    &:last-child {
        padding-top: 0 !important; // override inline style
    }
}

@media #{mq(md, max)} {
    .main > [data-content-type="row"] {
        margin: 2rem 0 !important; // override inline style
    }
}
