.vvv-code4code-index .page-title {
    margin-bottom: 0;
}

.vvv-code4code-index .page-title-wrapper + .page.messages {
    margin-top: 2rem;
}

.code4code-container {
    position: relative;
    margin-bottom: 80px;

    .subtitle {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-top: 0.2rem;
        margin-bottom: 4.6rem;
        color: $color-theme;
    }

    .code4code-logo {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        img {
            max-width: 200px;
            height: auto;
            max-height: 100px;
            margin-left: 1rem;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .code-block {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            padding: 0.8rem 1rem 3rem;
            border: solid 1px #e4e4e4;
            background: #ffffff;

            a:hover {
                color: $color-theme;
            }

            .step {
                font-size: 15px;
                font-weight: bold;
                line-height: 31px;
                display: inline-block;
                width: 31px;
                height: 31px;
                margin-right: 6px;
                text-align: center;
                vertical-align: top;
                color: #3e4890;
                border: solid 2px #3e4890;
                border-radius: 22px;
            }

            .title {
                font-size: 15px;
                font-weight: bold;
                display: inline-block;
                overflow: hidden;
                max-width: calc(100% - 35px);
                margin-top: 7px;
                margin-bottom: 25px;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #3e4890;
            }

            .img-container {
                text-align: center;

                img {
                    position: relative;
                    top: -6px;
                    left: -8px;
                    max-width: 149px;
                }
            }

            .block-content {
                position: relative;
                top: -5px;
                box-sizing: border-box;
                min-height: 110px;
                padding: 15px 25px 5px 25px;
                border-radius: 10px;

                .step3-image img {
                    position: absolute;
                    top: 35px;
                    right: -45px;
                }

                .mail {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 15px;
                    display: block;
                    margin-bottom: 1rem;
                    color: #ef7d28;
                }
            }

            &.active {
                .step {
                    color: #ffffff;
                    background: #3e4890;
                }

                .actions .button:not(.disabled) {
                    pointer-events: all;
                    opacity: 1;
                }
            }

            &.check .step {
                position: relative;

                &:after {
                    font-family: FontAwesome;
                    font-size: 10px;
                    font-weight: 100;
                    line-height: 15px;
                    position: absolute;
                    top: -4px;
                    right: -5px;
                    width: 15px;
                    height: 15px;
                    content: $fa-var-check;
                    text-align: center;
                    color: #ffffff;
                    border: solid 2px #ffffff;
                    border-radius: 50em;
                    background-color: #549d39;
                }
            }

            &.step-2 .details {
                margin-left: 5px;
            }

            &.step-3 .actions a {
                font-size: 1.2rem;
                font-weight: $font-weight__bold;
                position: absolute;
                bottom: 9rem;
                text-decoration: underline;
                color: $color-theme;

                @media #{mq(xl)}{
                    bottom: 3.15rem;
                }

                &:active,
                &:hover {
                    text-decoration: none;
                    color: getColor(theme, light);
                }
            }
        }

        &.last-step {
            color: inherit;

            @media #{mq(lg, max)} {
                .step-1,
                .step-2 {
                    display: none;
                }
            }
        }
    }

    form {
        fieldset {
            padding: 0;
            border: 0;
            border-radius: 0;

            .field {
                margin-bottom: 15px;

                &:not(.choice) {
                    label {
                        line-height: 15px;
                        float: left;
                        width: 100%;
                        margin-bottom: 5px;

                        span {
                            font: 1.2rem map-get(map-get($font, family), heading);
                            padding-top: 0;
                            color: $color-theme;
                        }
                    }

                    .control {
                        max-width: 348px;

                        &.valid-check {
                            position: relative;

                            &:after {
                                font-family: FontAwesome;
                                font-size: 12px;
                                font-weight: 100;
                                line-height: 15px;
                                position: absolute;
                                top: 30px;
                                right: 12px;
                                width: 15px;
                                height: 15px;
                                content: $fa-var-check;
                                text-align: center;
                                color: #59c73e;
                            }
                        }
                    }
                }

                &.field-amount {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    box-sizing: border-box;
                    width: 100%;
                    margin-top: -4px;
                    margin-bottom: 0;
                    padding: 5px;
                    border-radius: 10px;
                    background: #f4f5f8;

                    label {
                        max-width: 100px;
                        margin-bottom: 0;
                        margin-left: 17px;
                    }
                }

                &.choice {
                    display: block;
                    padding: 1rem 0;

                    input[type="checkbox"] + label {
                        padding-left: 3.2rem;
                    }

                    a {
                        text-decoration: underline;
                        color: #080808;
                    }
                }

                .details {
                    font-size: 10px;
                    display: block;
                    padding-top: 4px;
                    padding-left: 5px;
                    color: $color-theme;

                    span {
                        font-weight: 500;
                    }
                }
            }

            .fields {
                display: flex;

                .field {
                    padding: 0 5px;
                    &:first-child { padding-left: 0; }
                    &:last-child { padding-right: 0; }
                }
            }
        }

        .actions {
            width: 100%;
            height: 40px;

            .button {
                width: 100%;
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    .code4code-product {
        display: inline-block;
        cursor: pointer !important;
        text-align: center;

        label {
            cursor: pointer;
        }

        &.product-active {
            font-weight: bold;
            opacity: 1;
        }

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }

    .field-amount input {
        color: rgba(8, 8, 8, 0.6);
    }
}


@include max-screen(1024px) {
    .vvv-code4code-index .page-title span {
        font-size: 20px;
    }

    .code4code-container {
        .subtitle {
            font-size: 17px;
            line-height: 21px;
            margin-top: 4px;
        }

        .code4code-logo {
            img {
                display: none;
            }
        }

        .row {
            margin: 0 -10px;

            .code-block {
                border-bottom-width: 0;

                &:last-of-type {
                    border-bottom-width: 1px;
                }

                .img-container {
                    float: left;
                    width: 50%;

                    img {
                        position: relative;
                        top: 7px;
                        left: -7px;
                        max-width: 200px;
                    }
                }

                &.step-2 fieldset {
                    float: right;
                    box-sizing: border-box;
                    width: 49%;
                    margin-right: 6px;
                    margin-left: 0;
                    padding: 25px 0 0 12px;

                    .field.choice {
                        margin-bottom: 8px;
                    }
                }

                fieldset .field.confirm-email {
                    margin-bottom: 7px;
                }

                .block-content {
                    margin: 10px 0 51px 20px;

                    img {
                        top: -27px;
                        right: -66px;
                    }

                    .step3-image {
                        display: none;
                    }
                }

                .actions .button:after {
                    right: 1.4em;
                }
            }
        }
    }
}

@include max-screen(768px) {
    .vvv-code4code-index {
        .page-title {
            span {
                font-size: 17px;
            }
        }
    }

    .code4code-container {
        .subtitle {
            font-size: 15px;
            line-height: 20px;
            margin-top: 8px;
            margin-bottom: 15px;
        }

        .code4code-logo {

            img {
                width: 74px;
                display: none;
            }
        }

        .row .code-block {
            .block-content {
                margin: 4px 0 131px 0;

                img {
                    top: 50px;
                    right: 8px;
                    max-width: 85px;
                }
            }

            .img-container {
                width: 100%;
                text-align: right;

                img {
                    top: 5px;
                    left: -2px;
                }
            }

            &.step-1 .actions {
                margin-top: 20px;
            }

            &.step-2 fieldset {
                width: 100%;
                margin: 0;
                padding: 17px 0 0 0;

                .actions {
                    margin-top: 0;
                }
            }

            &.step-3 {
                padding-bottom: 20px;
            }

            .actions {
                float: left;
            }
        }
    }
}

@include min-screen(768px) {
    .code4code-container .row .code-block {
        padding: 7px 10px 30px 20px;

        .step {
            position: relative;
            left: -11px;
            margin-right: -5px;
        }

        .actions {
            float: right;
            width: 277px;

            .button {
                max-width: 277px;
            }
        }
    }
}

@include min-screen(1024px) {
    .code4code-container .row .code-block {
        width: calc((100% / 3) - 10px);
        margin-right: 14px;
        padding: 10px 27px 20px;
        border-radius: 10px;

        &:last-of-type {
            margin-right: 0;
        }

        .step {
            left: -16px;
            margin-right: -4px;
        }

        .title {
            margin-bottom: 30px;
        }

        &.active {
            box-shadow: 0 11px 26px 0 rgba(199, 204, 206, 0.3), 0 10px 11px 0 rgba(0, 0, 10, 0.07);
        }

        .actions {
            width: 100%;
            height: 40px;
            text-align: right;

            .button {
                bottom: 1rem;
                width: 20rem;
            }
        }

        &.active {
            border: 0;
        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .vvv-code4code-index .page-title {
        margin-left: 11px;
    }

    .code4code-container .subtitle {
        margin-left: 11px;
    }
}

html.ie11 .code4code-container form fieldset .field:not(.choice) .control.type-euro input#amount {
    min-height: 6rem;
}
