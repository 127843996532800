// Fylgja
// getfylgja.com
// Licensed under MIT Open Source
// v0.2.0

$gap-size: map-get($config-grid, gap-size);

.flex-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    &::before,
    &::after {
        // prevent the before/after from breaking the grid
        content: none;
    }
    &.flex-fill {
        > .cell {
            display: inline-flex;
            > * {
                flex: 1 1 auto;
            }
        }
    }
    > .cell {
        flex: 0 0 100%;
        @include build-breakpoints(false) {
            // autofilling cell style
            &-grow {
                flex: 1 1 auto;
            }
            // Content fitting cell style
            &-shrink {
                flex: 0 0 auto;
            }
            // Create cell sizes
            @for $size from 1 through map-get($config-grid, columns) {
                &-#{$size} {
                    flex: 0 0 ($size * 100% / 12);
                }
            }
        }
    }
    // offset function for cell's
    > .offset {
        @include build-breakpoints {
            @for $offset from 1 through 11 {
                &-#{$offset} {
                    margin-left: ($offset * 100% / 12);
                }
            }
        }
    }
    &.gap {
        @include flex-gap-size($gap-size);
        &-tiny {
            @include flex-gap-size(2px 2px);
        }
        &-row {
            // only adds a gap to the row not column
            @include flex-gap-size(nth($gap-size, 1));
        }
    }
}
