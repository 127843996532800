body.sales-order-view table#my-orders-table {
    thead tr {
        width: 100%;
    }

    thead th {
        width: 20%;
    }

    th.col.name {
        padding-left: 0;
        width: 30%;
    }

    th.col.sku,
    th.col.price,
    td.col.price,
    td.col.sku {
        text-align: left;
    }

    tbody tr {
        width: 100%;
        display: table-row;
    }

    tbody td {
        width: 20%;
        display: table-cell;
        padding-top: .8rem;
        vertical-align: top;

        @media #{mq(md, max)} {
            &::before {
                height: 3rem;
                padding: 0;
            }
        }
    }

    tbody td.col.value,
    tbody td.col.name {
        text-align: left;
        padding-left: 0;
    }
}
