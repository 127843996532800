.header-minicart {
    align-self: center;

    .minicart-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        margin: 0;
        padding: 3px;
        border-radius: .7rem;
        background-color: $color-accent-dark;
        color: $color-on-accent;

        @media #{mq(md)} {
            width: 90px;
            height: 60px;
            background-color: getColor(bg);
            color: $color-theme;
        }

        .action.showcart {
            display: block;
            color: inherit;
            text-align: center;
            text-decoration: none;

            &::before {
                content: none;
            }

            @media #{mq(md)} {
                .text {
                    position: relative;
                    display: block;
                    width: auto;
                    height: auto;
                    padding: 0;
                    font-size: getSize(small);
                    font-weight: 500;
                    overflow: auto;
                    clip: unset;
                }
            }
        }
    }

    .minicart-icon > .icon {
        width: 21px;
        height: 21px;

        @media #{mq(md)} {
            width: 32px;
            height: 25px;
            color: $color-accent-dark;
        }
    }
}
