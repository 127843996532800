.customer-account-login {
    .block-customer-login {
        .fieldset:after,
        .fieldset > .field.required > .label:after {
            display: none;
        }
    }
}

// Show y scroll always for desktop to avoid pixel jumpings
@media only screen and (min-width: 768px) {
    html {
        overflow-y: scroll;
    }
}

/* Popup Confirm Modal Custom Styles */

/* For desktop */
@media only screen and (min-width: 768px) {
    .confirm {
        width: 56rem;
        margin: 0 auto;

        .modal-inner-wrap {

            .modal-content {
                width: 32rem;
                margin: 0 auto;
                text-align: center;
                div {
                    font-size: 14px;
                }
            }
        }
    }
}

/* For mobile */
@media only screen and (max-width: 768px) {
    .confirm {
        width: 90%;
        margin: 0 auto;

        .modal-inner-wrap {
            overflow: hidden;
            .modal-content {
                width: 20rem;
                margin: 0 auto;
                text-align: center;
                div {
                    font-size: 14px;
                }
            }
            .modal-footer {
                button {
                    margin: 0.5rem auto;
                }
            }
        }
    }
}


/* Show different button for proceed to checkout */

/* For desktop */
@media only screen and (min-width: 768px) {
    #mobile-checkout-button {
        display: none;
    }
}

/* For mobile */
@media only screen and (max-width: 768px) {
    #desktop-checkout-button {
        display: none;
    }
}

// Tooltip for BTW for cart page
.cart-summary {
    .vat-container {
        position: relative;
        i.fa.fa-info-circle {
            font-size: 16px;
            color: #435063;
            z-index: 10;
            position: relative;
        }
        i.fa.fa-info-circle:hover + .vat-tooltip {
            visibility: visible;
        }
        .vat-tooltip {
            position: absolute;
            background: #3e4890;
            color: white;
            padding: 16px 22px;
            border-radius: 1rem;
            width: 100%;
            top: -57px;
            left: 7px;
            visibility: hidden;
            display: flex;
            justify-content: center;
            font-family: Quicksand;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 13px;
            &::after {
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: 25px;
                border-width: 7px;
                border-style: solid;
                border-color: #3e4890 transparent transparent transparent;
              }

        }
    }
}


// Show optionel next to optional fields in form

.form .field {
    display: flex;

    &.choice {
        padding: 1rem 0;
    }
}
.form .field, :not(._required, .required), .form-shipping-address .field {
    // align-items: center;
}

.form .field:not(._required, .required, .newsletter, .date, .payment-method-title, #remember-me-box) .label:not([for="show-password"]) span:after {
    content: ' (optioneel)';
    font-size: 70%;
}

.form .field:not(._required, .required, .newsletter, .date, .payment-method-title) .label span {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    flex-wrap: wrap;
}

// Remove optional from fields that are not needed
.opc-wrapper #email #checkout-step-title .form .field:not(._required):not(.required) .label span:after, .newsletter-subscribe span::after {
    content: '' !important;
}

#experius-postcode-fieldset .field:not(._required, .house-flat):not(.required) .label span:after {
    content: '' !important;
}

// Checkout login password
.opc-wrapper .form-login .label.checkout-password {
    line-height: 1;
    &::after {
        content: '(bij inloggen)' !important;
        font-size: 10px;
    }
}

// Align well fields for checkout login
.opc-wrapper .form-login .checkout-email, .opc-wrapper .form-login .checkout-password {
    padding-right: 0;
    min-width: 13rem;

    span {
        min-width: 13rem;
    }
}
.opc-wrapper {
    .fieldset {
        margin-bottom: 0 !important;
    }
    .step-title {
        color: #3e4890;
        font-size: 16px !important;
    }
    .checkout-password-wrapper {
        margin-bottom: 0 !important;
    }
    .checkout-forgot-password {
        margin-left: 14rem;
        font-size: 13px;
        a {
            text-decoration-color: #ccc;
            span {
                color: #ccc;
            }
        }
    }
}
#checkout-step-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.opc-wrapper .form-login .actions-toolbar {
    margin-left: 0 !important;
    margin-top: 10rem;
    display: flex;
    justify-content: flex-end;
    max-width: 410px;

    .primary {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .secondary {
        text-align: left;
        color: #3e4890;

        span:first-child {
            font-weight: 800;
        }

        a {
            font-weight: 400;
            color: #3e4890;
        }
    }
}

// Media query for checkout email part
@media screen and (max-width: 1040px) {
    .opc #email {
        #checkout-step-title {
            display: block;
        }
        .form .field {
            display: block !important;

            .input-text {
                width: 90% !important;
            }
            .field-tooltip {
                left: 90% !important;
            }
        }
    }
}

.field.zip.required .label span {
    line-height: 1.9 !important;
    &::after {
        content: '' !important;
    }
}

// Make calendar field full width in forms
.webforms-calendar {
    width: 100% !important; // Important so I don't have to copy files from vendor
}

// Remove margin from aanhef field

.form-edit-account {
    .field-name-prefix .control {
        margin-top: 0 !important;
    }

    .field.choice {
        margin-top: 1.5rem;
    }
}

