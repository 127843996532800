.cms-page-view #maincontent,
.cms-index-index #maincontent {

    button.slick-arrow {
        z-index: 99;
        right: 4rem;
        bottom: 4rem;
        width: 5rem;
        height: 5rem;
        color: white;
        border: 2px solid white;
        border-radius: 5rem;
        background: $color-theme;

        &:before {
            @include fa-icon();
            font-size: 4rem;
            font-weight: $font-weight__bold;
            top: 0.15rem;
            left: 1.2rem;
            color: getColor(theme, contrast);
        }
        &.slick-prev:before {
            content: $fa-var-angle-left;
        }
        &.slick-next:before {
            content: $fa-var-angle-right;
        }
        &.slick-disabled {
            opacity: 0;
        }

        &:hover {
            cursor: pointer;
            background: $color-theme-dark;
            box-shadow: $dropdown-box-shadow;
        }
    }


    .slick-initialized {
        background: transparent;
    }
}
