// Extends on Magento_CatalogSearch

.header-search {
    align-self: center;
    padding-right: 1.5rem;
    .block-search {
        margin-bottom: 0;
        input {
            position: static;
            left: auto;
            margin: 0;
        }
        .action.search {
            display: inline-block;
        }
        .search-toggle-btn {
            $size: 3.5rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: $size;
            height: $size;
            padding: 2px;
            border-radius: $size;
            border: 2px solid $color-theme;
            background-color: $color-theme;
            color: $color-on-theme;
            line-height: 2.9rem;
            text-align: center;
            &::before {
                @include fa-icon();
                content: $fa-var-search;
                color: inherit;
                font-size: 1.6rem;
            }
            &:focus,
            &:hover {
                background-color: $color-on-theme;
                color: $color-theme;
            }
        }
        .search-panel {
            z-index: 1;
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            border-top: 1px solid #c3c5c6;
            background-color: #fff;
        }
        .form.minisearch {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 10px;
            padding-bottom: 10px;
            @media #{mq(sm)} {
                flex-wrap: nowrap;
                height: 98px;
            }
        }
        .field {
            &-label,
            &-action,
            &.search {
                flex: 1 1 auto;
                width: auto;
            }
            &-label,
            &-action {
                text-align: center;
            }
            &-label {
                width: 100%;
                margin-bottom: 10px;
                color: $color-theme;
                font-size: 1.6rem;
                font-weight: 700;
                @media #{mq(sm)} {
                    width: auto;
                    min-width: 250px;
                    margin-bottom: 0;
                }
            }
            &-action {
                width: 120px;
                @media #{mq(sm)} {
                    max-width: 200px;
                }
                @media #{mq(lg)} {
                    min-width: 150px;
                }
                button {
                    width: 120px;
                }
            }
            &.search {
                width: calc(100% - #{(120px + 10px)});
                max-width: 723px;
                padding-right: 10px;
                @media #{mq(sm)} {
                    padding-left: 10px;
                    width: 100%;
                }
                input {
                    height: 40px;
                    @media #{mq(md)} {
                        font-size: 1.6rem;
                    }
                    @media #{mq(lg)} {
                        height: 63px;
                    }
                }
            }
        }
    }
}

.search-panel-is-open {
    &::after {
        content: "";
        z-index: 1;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(black, .2);
    }
}
