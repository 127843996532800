@mixin arrow {
    content: '';
    width: 0;
    height: 0;
    border-radius: 2px 1px 1px 0;
    display: block;
    border: 6px solid getColor(link, base);
    border-bottom: 6px solid transparent;
    border-left: 4px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    top: 12px;
}

.breadcrumbs {
    @include lib-breadcrumbs();
    margin: 0 auto;

    .items {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 3rem;

        .item {
            position: relative;

            a,
            strong {
                font-family: "Quicksand";
                font-size: getSize(small);
                font-weight: 400;
                color: getColor(theme, base);
            }

            &:nth-child(1) {
                padding: 0 3px 0 10px;

                a {
                    font-weight: bold;
                }

                &:after {
                    @include arrow;
                    left: 0;
                    transform: rotate(-23deg);
                }
            }

            &.item:nth-child(2) {
                padding: 0 3px 0 5px;
                border-left: 1px solid getColor(text, light);
            }

            &:nth-child(n+3) {
                padding: 0 3px 0 10px;

                &:after {
                    @include arrow;
                    left: -4px;
                    transform: rotate(33deg);
                }
            }

            &:last-of-type {
                padding: 0 0 0 10px;
                text-transform: capitalize;
            }
        }
    }
}
