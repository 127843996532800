.readmore {
    @extend .button;
    @extend .style-link;
    transition: none;
}

.description {
    .full {
        display: none;
        &.is-open {
            display: block;
            + .readmore {
                display: inline;
            }
        }
    }
}
