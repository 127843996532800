body.sales-order-print {
  .order-details-items.ordered {
    width: 100%;

    td.col.qty span.content {
      font-size: 1.6rem;
    }

    td.col.qty ul.items-qty {
      padding: 0;

      li.item::before {
        content: none;
      }
    }
  }
}