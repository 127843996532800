.header-locator.flex-grid.flex-fill {
    .order-lg-last {
        background-color: white;
        border-radius: 1.5rem;
        max-height: none;
        margin-top: 0;

        @media #{mq(lg, max)} {
            display: none;
        }
    }

    .cell.lg-4.order-lg-last {
        background-color: transparent;

        > div:not(.no-background) {
            background-color: getColor(bg, white);
            border-radius: 1.5rem;
        }
    }
}
