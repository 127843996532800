// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type$='block'] {
    .block {
        p:last-child {
            margin-bottom: 1rem;
            margin-top: 0;
        }
    }
}
