.pages {
    @include lib-pager();

    .action {
        width: 34px;

        &.previous {
            margin-right: 12px;
        }

        &.next {
            margin-left: 12px;
        }
    }
}
