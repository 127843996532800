// Fylgja
// getfylgja.com
// Licensed under MIT Open Source
// v0.2.0

/// @param {true or false} - if the breakpoint class is connected to the class you using this mixin in
/// @param {min or max} - breakpoint type is min-width or max-width
/// @param {boolean} - if to use build-breakpoints
/// @return values with breakpoints set arround it.

@mixin build-breakpoints($part-of: true, $mq-type: min, $mq: $breakpoints, $boolean: true) {
    $prefix: '.';
    @if $part-of {
        $prefix: '-';
    }
    @if $boolean == true {
        @each $name, $value in $mq {
            @if $value == 0 {
                @if $part-of {
                    @content;
                } @else {
                    &#{$prefix}#{$name} {
                        @content;
                    }
                }
            } @else {
                &#{$prefix}#{$name} {
                    @media (#{$mq-type}-width: $value) {
                        @content;
                    }
                }
            }
        }
    } @else {
        @if $part-of {
            @content;
        } @else {
            &#{$prefix}#{$name} {
                @content;
            }
        }
    }
}
