.sorting-options {
    display: flex;
    flex-direction: column;
    color: $color-theme;

    margin-left: auto;
    order: 2;

    @media #{mq(md)} {
        align-items: center;
        flex-direction: row;
   
    }

    label {
        font-size: 1.2rem;
        font-weight: 400;
        white-space: nowrap;
    }

    select {
        font-weight: 700;
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        color: inherit;
        border: 0;
        border-radius: 50px;
        background-color: transparent;

        @media #{mq(md)} {
            padding-left: 15px;
        }
    }

    select ::-ms-expand {
        display: block !important;
    }
}
