$config-grid: (
    columns: 12,
    gap-size: 1rem 1rem
) !default;

// Needed for remapping for M2 blank theme
$grid: (
    width: 120rem,
    columns: 12,
    breakpoints: (
        mobile: 576px,
        tablet: 768px,
        laptop: 992px,
        desktop: 1200px,
        grid: 1200px
    ),
    outer-gutter: (
        mobile: 1.5rem,
        tablet: 2rem,
        laptop: 3rem,
        desktop: 3rem,
        grid: 0
    ),
    gutter: (
        mobile: 1rem,
        tablet: 2rem,
        laptop: 3rem,
        desktop: 4rem,
        grid: 4rem
    )
) !default;
