// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Full Bleed row appearance styles
//  ________________________________

[data-content-type='row'][data-appearance='full-bleed'] {
    box-sizing: border-box;
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $screen__m) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        background-attachment: scroll !important;
    }
}
