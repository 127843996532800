// Extends foundtion css

.off-canvas {
    box-sizing: border-box;
    width: 90%;
    max-width: 320px;
    transform: translateX(-100%);
    visibility: hidden;
    transition: transform .3s, visibility 0s linear .3s;
    overscroll-behavior: contain;
    &.position-left {
        box-shadow: 8px 0 31px rgba(black, .15);
    }
    &.is-open {
        visibility: visible;
        transition: transform .3s, visibility 0s;
    }
    .close {
        @include lib-button-icon(
            $icon-remove,
            $_icon-font-size: 2.8rem,
            $_icon-font-line-height: 1,
            $_icon-font-text-hide: true
        );
        margin-left: 1rem;
        border: 2px solid $color-theme;
        background-color: $color-theme;
        color: $color-on-theme;
        &:hover,
        &:focus {
            border: 2px solid $color-theme;
            background-color: transparent;
            color: $color-theme;
        }
    }
    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 6;
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        visibility: hidden;
        transition: transform .3s, visibility 0s linear .3s;
        .scroll-lock & {
            visibility: visible;
            transition: transform .3s, visibility 0s;
        }
    }
}

.scroll-lock {
    // Lock page
    overflow: hidden;
}
