// Fylgja v0.2.0 (getfylgja.com)
// Licensed under MIT Open Source

$item-spacer: .625rem !default;
$form-enable-field-helper: true !default;
@if $form-enable-field-helper {
    .form-field {
        display: block;
        margin: 0 0 $item-spacer;
        &:focus-within {
            > label,
            > .label,
            > .helper-text {
                color: $form-focus-color;
            }
        }
    }
}
