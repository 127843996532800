picture {
    // 1) IE fix
    display: inline-block;
    max-width: 100%; // 1
    height: auto; // 1
    img {
        display: block;
    }
}

figure {
    line-height: 0;
}
