.option-plus-list {
    .product.options.list {
        font-size: 1.2rem;
        line-height: 1.15;
        .label {
            font-weight: normal;
            &:not(:first-of-type) {
                &::before {
                    content: "+ ";
                }
            }
            &:not(.show-label) {
                @include sr-only;
            }
        }
        .values {
            &.hide-values {
                @include sr-only;
            }
            .price {
                color: inherit;
                font-weight: normal;
            }
            &:not(:first-of-type) {
                &::before {
                    content: "+ ";
                }
                .price {
                    color: $color-theme;
                    white-space: nowrap;
                    &::before {
                        content: "(+ ";
                    }
                    &::after {
                        content: ")";
                    }
                }
            }
            .price-container {
                &,
                .minimal-price-link,
                .price-excluding-tax,
                .price-including-tax {
                    display: inline;
                }
            }
        }
    }
}
