//
//  Variables
//  _____________________________________________

$checkout-field-validation__border-color: $form-element-validation__border-error !default;

.experius_postcode_fieldset {
    .field-note {
        display:none;
    }
}

.field {
    .control {
        &._with-tooltip {
            @extend .abs-field-tooltip;
        }
    }

    &.choice {
        .field-tooltip {
            position: relative;
            top: -3px;
            display: inline-block;
            margin-left: $indent__s;
        }

        .field-tooltip-action {
            @include lib-css(line-height, $checkout-tooltip-icon__font-size);
        }
    }

    &._error .control {
        input,
        select,
        textarea {
            @include lib-css(border-color, $checkout-field-validation__border-color);
        }
    }

    input.error {
        border: 1px solid getColor(status, danger);
        background-color: getColor(status, bg);
    }
}

.opc-wrapper,
.form-shipping-address {
    .fieldset {
        > .field {
            > .label {
                font-size: 15px;
                font-weight: bold;
                display: inline-block;
                margin-left: 2px;
                color: getColor(theme, base);
            }

            &.receiver-type-field {
                margin-bottom: 0;

                > label {
                    display: none;
                }

                .control-input {
                    display: inline-block;
                    margin-right: 30px;
                    margin-bottom: 12px;
                }
            }
        }
    }
}

fieldset#customer-email-fieldset button.action {
    @extend %default-button-styling;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    //  ToDo UI: remove with global blank theme .field.required update
    .opc-wrapper {
        .fieldset {
            > .field {
                &.required,
                &._required {
                    position: relative;

                    > label {
                        padding-right: 25px;

                        &:after {
                            position: absolute;

                        }
                    }
                }
            }
        }
    }
}
