//
//  Variables
//  _____________________________________________

$gift-wrapping__border-color                 : $color-gray-light5 !default;
$gift-wrapping__border-width                 : $border-width__base !default;

$gift-wrapping-preview__active__border-color : $color-gray20 !default;
$gift-wrapping-preview__active__border-width : 3px !default;

$gift-wrapping-thumbnail__height             : 4rem !default;
$gift-wrapping-thumbnail__width              : $gift-wrapping-thumbnail__height !default;

$gift-wrapping-no-image__background          : $color-gray95 !default;
$gift-wrapping-no-image-icon__color          : $color-gray-darken4 !default;
$gift-wrapping-no-image-icon__font-size      : 38px !default;

.gift-wrapping {
    .label {
        @include lib-css(margin, $form-field-type-label-block__margin);
        display: inline-block;
    }

    .field {
        margin-bottom: $indent__s;
    }
}

.gift-wrapping-info {
    @include lib-css(font-weight, $font-weight__light);
    font-size: $font-size__l;
    margin: $indent__base 0;
}

.gift-wrapping-list {
    @extend .abs-add-clearfix;
    list-style: none;
    margin: $indent__base 0 $indent__s;
    padding: 0;

    .no-image {
        @include lib-css(background, $gift-wrapping-no-image__background);
        @include lib-icon-font(
            $icon-present,
            $_icon-font-size           : $gift-wrapping-no-image-icon__font-size,
            $_icon-font-line-height    : $gift-wrapping-thumbnail__height,
            $_icon-font-color          : $gift-wrapping-no-image-icon__color,
            $_icon-font-position       : after,
            $_icon-font-text-hide      : true,
            $_icon-font-vertical-align : middle
        );

        &:after {
            display: block;
        }
    }
}

.gift-wrapping-preview,
.gift-wrapping-item > span {
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.gift-wrapping-item {
    float: left;
    margin: 0 $indent__s $indent__s 0;

    &:last-child {
        margin-right: 0;
    }

    &._active {
        span {
            &:before {
                @include lib-css(border, $gift-wrapping-preview__active__border-width solid $gift-wrapping-preview__active__border-color);
                box-sizing: border-box;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
                z-index: 2;
            }
        }
    }

    > span {
        height: $gift-wrapping-thumbnail__height;
        width: $gift-wrapping-thumbnail__width;
    }
}

.gift-wrapping-title {
    margin: $indent__s 0;

    .action-remove {
        margin-left: $indent__xs;
    }
}

.gift-options-content {
    .fieldset {
        > .field {
            margin: 0 0 $indent__xs;

            &:before {
                display: none;
            }
        }
    }
}

.gift-wrapping-summary {
    .gift-wrapping-title {
        border: 0;
        display: inline-block;
        padding: 0;
    }
}

.opc-wrapper .data.table,
.table-order-items,
.table-order-review {
    .gift-wrapping {
        @extend .abs-add-clearfix;
        margin: $indent__base 0;

        .title {
            float: none;
        }

        .content {
            margin: 0;

            > img {
                float: left;
                margin: 0 $indent__s 0 0;
                max-height: 50px;
                max-width: 50px;
            }
        }
    }
}

.gift-summary,
.gift-options {
    @extend .abs-adjustment-incl-excl-tax;

    .price-box {
        margin-left: 22px;
    }

    .regular-price {
        &:before {
            content: attr(data-label)': ';
        }

        .price {
            font-weight: $font-weight__bold;
        }
    }
}

//
//  Multiple Shipping Checkout
//  ---------------------------------------------

.order-options {
    .item {
        padding-bottom: $indent__base;
    }

    .product {
        @extend .abs-add-clearfix;
        margin-bottom: $indent__s;
    }

    .product-image-container {
        float: left;
        padding-right: $indent__s;
    }

    .gift-wrapping {
        margin-bottom: $indent__s;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .gift-wrapping {
        @include lib-css(border-bottom, $gift-wrapping__border-width solid $gift-wrapping__border-color);
        margin-bottom: $indent__base;
        padding-bottom: $indent__s;
    }

    .gift-wrapping-list {
        width: 100%;
    }

    .gift-wrapping-preview {
        img {
            width: 100%;
        }
    }

    .item-actions {
        .actions-toolbar {
            .gift-options {
                .gift-wrapping,
                .gift-message {
                    display: block;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .gift-wrapping {
        .nested {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .table {
        .gift-wrapping {
            .content {
                @extend .abs-add-clearfix-desktop;
            }
        }
    }

    .gift-options-cart-item,
    .cart-gift-item {
        .gift-wrapping {
            box-sizing: border-box;
            float: left;
            padding-right: $indent__base;
            width: 50%;

            & + .gift-message {
                @include lib-css(border-left, $gift-wrapping__border-width solid $gift-wrapping__border-color);
                box-sizing: border-box;
                float: left;
                padding-left: 4.5rem;
                width: 50%;
            }
        }
    }
}
