.shipping-policy-block {
    display: block !important;
    position: static;
    width: 100%;

    .field-tooltip._active .field-tooltip-content {
        display: flex !important;
    }

    &.field-tooltip {

        .field-tooltip-action {
            width: 100%;
            cursor: default;

            &::before {
                content: none;
            }

            span {
                position: static;
                clip: unset;
                overflow: unset;
                font-family: $family-body;
                cursor: default;
            }

            span, a {
                color: getColor(theme, base);
                font-weight: bold;
                font-family: $family-body !important;
            }

            a {
                cursor: pointer;
            }
        }

        .menu-close {
            display: flex;
            justify-content: flex-end;
            padding: 1rem 2rem 0 0;
            margin-bottom: 2rem;

            @media #{mq(md, min)} {
                padding: 1rem 0 0 0;
            }

            &::after {
                @include fa-icon();
                content: $fa-var-times;
                font-size: 2.5rem;
                color: getColor(text, contrast);
                background-color: getColor(theme, base);
                padding: 1rem 1.3rem;
                border-radius: 50%;
            }
        }

        .field-tooltip-content {
            position: fixed;
            justify-content: center;
            background-color: rgba(0, 0, 0, .7);
            border: none;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            padding: 0;
        }

        .field-tooltip-wrapper {
            box-sizing: border-box;
            background-color: getColor(text, contrast);
            z-index: 99;
            position: static;
            width: 100%;
            height: 100vh;
            top: unset;
            bottom: unset;
            left: unset;
            right: unset;
            padding: 0 .5rem 3.5rem .7rem;
            font-family: $family-body;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-self: center;

            @media #{mq(md, min)} {
                width: 95%;
                max-width: 102.4rem;
                height: 70vh;
                border-radius: 1.5rem;
                padding: 3.5rem;
                margin: 10rem auto 0;
            }

            &::before,
            &::after {
                content: none;
            }
        }

        .content-paragraph-strong {
            font-weight: bold;
        }

        .field-tooltip-content table {
            border: .1rem solid getColor(bg, dark);
            font-family: $family-body;
            margin: 3rem 0;
            width: auto;
            max-width: 95%;

            thead tr td {
                background-color: #000080;
            }

            tbody tr:nth-child(2) td,
            tbody tr:nth-child(4) td {
                background-color: #c0c0c0;
            }
        }
    }
}
