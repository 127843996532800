.CookieDeclarationType {
    background-color: #fff;
    border-radius: $border-radius-def !important;
    padding: 20px !important;
    margin-top: 32px !important;
    background: white;
    transition: box-shadow 0.3s;
    border: solid 1px #e4e4e4 !important;

    &:hover,
    &:active {
        box-shadow: 0 11px 26px 0 rgba(199, 204, 206, 0.3), 0 10px 11px 0 rgba(0, 0, 10, 0.07);
    }
}
