fieldset.fieldset.create.info {
    position: relative;

    .additional-container {
        overflow: hidden;
        transiton: padding, height;
        transition-duration: 0.3s;
        transition-timing-function: linear;

        &.hidden {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .field-address-receiver_type {
        position: absolute;
        top: 3.8rem;
        right: 0;
        left: 0;
        max-width: 490px;

        .control {
            display: flex;
            max-width: none !important;

            .control-input label {
                display: flex;
                align-items: center;
                min-width: 0 !important;
                padding-right: 4rem !important;
            }
        }
    }
}
