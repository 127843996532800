//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color !default;
$account-nav-color: false !default;

$account-nav-current-border: 3px solid transparent !default;
$account-nav-current-border-color: getColor(accent, dark) !default;
$account-nav-current-color: false !default;
$account-nav-current-font-weight: $font-weight__semibold !default;

$account-nav-delimiter__border-color: $color-gray82 !default;

$account-nav-item-hover: $color-gray91 !default;

$_password-default: $color-gray-light01 !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;

@import 'register';
@import 'create';

.login-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .block {
        box-sizing: border-box;

        &-new-customer {
            .actions-toolbar {
                margin-top: 16px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size(18);
        }

        .block-content {
            form .fieldset {
                width: 100%;
            }
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);

        &:after {
            margin-top: 35px;
        }
    }
}

body.customer-account-login {
    .page-wrapper {
        .columns {
            .column.main {
                background: transparent;
                padding: 0;

                .block:not(.widget) {
                    background: #fff;
                    padding: 10px 15px;
                    box-sizing: border-box;
                    border-radius: 13px;
                    width: calc(50% - 10px);
                    flex-basis: auto;

                    form .fieldset {
                        width: 100%;
                        padding: 0;
                    }

                    &.block-customer-login {
                        order: 1;
                    }

                    &.block-new-customer {
                        align-self: flex-start;

                        .actions-toolbar {
                            margin-bottom: 10px;
                        }
                    }

                }
            }
        }
    }
}

body.customer-account-forgotpassword {
    .page-wrapper {
        .columns {
            .column.main {
                padding: 20px 15px;

                form {
                    .fieldset {
                        width: 100%;
                        padding: 0;

                        .field {
                            margin-bottom: 15px;

                            &.note {
                                font-size: 16px;
                                color: #3e4890;
                                font-weight: 700;
                            }
                        }
                    }

                    .actions-toolbar {
                        > .primary, > .secondary {
                            min-width: 200px;
                            margin: 0 10px 10px 0;
                        }
                    }
                }
            }
        }
    }
}

.form.form-add-user {
    max-width: 500px;
}

#new-customers-container {
    width: 100%;

    form {
        .fieldset {
            width: 100%;
        }
    }
}

body.account {
    .edit-company-profile {
        font-weight: 700;
        margin-bottom: 10px;
        display: block;
        text-decoration: underline;
    }

    .columns {
        .column.main {
            min-height: 0;
            display: flex;
            flex-wrap: wrap;

            .payment-methods-label {
                color: #3e4890;
                font-weight: 700;
                margin-bottom: 17px;
                display: block;
                font-size: 15px;
            }

            .admin__data-grid-outer-wrap {
                width: 100%;
            }

            ul {
                list-style: none;
                padding-left: 37px;

                li {
                    position: relative;
                    color: #3e4890;
                    font-weight: 700;
                    margin-bottom: 2px;

                    &:before {
                        content: '';
                        width: 3px;
                        height: 3px;
                        border-radius: 50em;
                        display: block;
                        position: absolute;
                        background: #3e4890;
                        left: -25px;
                        top: 7px;
                    }

                    ul {
                        margin-top: 2px;

                        li {
                            &:before {
                                width: 3px;
                                height: 2px;
                                border-radius: 0;
                            }

                            ul {
                                margin-left: -2px;
                                margin-top: 3px;
                            }
                        }
                    }
                }

                &.credit-balance-list {
                    margin-bottom: 0;

                    .credit-balance-item {
                        margin-bottom: 15px;

                        .credit-balance {
                            &-label {
                                margin-bottom: 0;
                            }
                        }

                    }
                }
            }

            .box-actions {
                .action {
                    margin-bottom: 10px;
                }
            }

            #my-orders-table,
            .orders-history {
                td.col {
                    text-align: left;
                }

                td.col.qty {
                    text-align: center !important;
                }
            }

            .orders-history,
            .order-products-toolbar,
            .message {
                width: 100%;
            }
        }
    }
}

.data-grid-filters-wrap {
    .action {
        margin-bottom: 10px;
    }
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.form-address-edit {
    #region_id {
        display: none;
    }

    .actions-toolbar .action.primary {
        @extend .abs-button-l;
    }

    .message {
        box-sizing: border-box;

        @media #{mq(md)} {
            max-width: 74.2%;
        }
    }
    .company {
        display: none;
    }

    .additional-business-fields {
        background: #f5f6f8;
        padding: 20px 20px 0 20px;
        max-width: 490px;
        border-radius: 13px;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
        margin-bottom: 20px;
        height: auto !important;

        &.hidden {
            padding: 0;
        }

        .field-address-receiver_type {
            order: 10;
            position: absolute;
            top: -4.4rem;
            width: 100%;
            left: 0;

            .control-input {
                display: inline-block;
            }
        }

        .field-department {
            order: 30;
        }

        .field-branch {
            order: 40;
        }

        .field-kvk_number {
            order: 50;
        }

        .company {
            order: 20;
            margin-bottom: 20px !important;
        }
    }

    .field.choice.set.shipping {
        margin-top: 0.5rem;

        span {
            color: #435063 !important;
        }
    }
}

.form-edit-account {
    .fieldset.password {
        clear: both;
        display: none;
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 26px;
    }
}

.block-addresses-list .items.addresses {
    @media #{mq(md)} {
        display: flex;
        flex-wrap: wrap;
    }

    > .item.box {
        font-size: 1.2rem;
    }
}

//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account
//  ---------------------------------------------

body.account {
    .columns {
        .main.column {
            box-shadow: 0 11px 26px 0 rgba(199, 204, 206, 0.3), 0 10px 11px 0 rgba(0, 0, 10, 0.07);
            margin-bottom: 80px;
        }
    }

    &.sales-order-view {
        .order-details-items {
            width: 100%;
        }

        .order-status,
        .order-title {
            color: #3e4890;
            font-weight: bold;
        }

        .order-date {
            color: #3e4890;
        }

        .columns .column.main ul {
            padding: 0;

            li {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.page-title {
    padding-top: 0;
    margin-bottom: 30px;
    font: bold 26px/1.08 $family-heading;

    span {
        font: bold 26px/1.08 $family-heading;
        color: #3e4890;
    }
}

.account,
.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword {
    a {
        color: #3e4890;
    }

    .block-title {
        strong {
            font: bold 16px $family-heading;
            color: #3e4890;
        }
    }

    .page-wrapper {
        > .column.main {
            width: 100%;
            float: none;
        }

        .columns {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 60px;

            .column.main {
                flex-basis: 0;
                flex-grow: 100;
                background: #fff;
                padding: 2.5rem 3rem;
                border-radius: 13px;
                margin-bottom: 10px;
                box-sizing: border-box;

                h2 {
                    margin-top: 0;
                }

                .toolbar {
                    text-align: center;

                    .limiter-options {
                        width: auto;
                    }

                    .toolbar-number {
                        font: 700 12px "Quicksand", sans-serif;
                        color: #3e4890;
                    }
                }

                .limiter {
                    > .label {
                        @extend .abs-visually-hidden;
                    }

                    .limiter-label, .limiter-text {
                        font: 700 12px "Quicksand", sans-serif;
                        color: #3e4890;
                    }
                }

                .block:not(.widget) {
                    margin-bottom: 25px;
                    width: 100%;

                    .block-title {
                        margin-bottom: 20px;

                        strong {
                            font: bold 20px $family-heading;
                            color: #3e4890;

                            & + a {
                                margin-left: 5px;
                            }
                        }
                    }

                    &.block-dashboard-orders {
                        flex-basis: 100%;
                    }
                }

                .box {
                    margin-bottom: 20px;

                    .box-title {
                        display: block;
                        margin-bottom: 10px;

                        span {
                            font: bold 16px $family-heading;
                            color: #3e4890;
                        }
                    }

                    .box-content {
                        line-height: normal;
                    }

                    .box-actions {
                        a {
                            font-weight: 700;
                            color: #3e4890;
                            text-decoration: underline;

                            & + a {
                                margin-left: 10px;
                            }
                        }
                    }
                }

                form {
                    float: left;
                    width: 100%;

                    .fieldset {
                        float: left;
                        width: 50%;
                        box-sizing: border-box;
                        margin-bottom: 20px;

                        &:nth-of-type(2),
                        &.job_title {
                            padding-right: 0;
                            padding-left: 10px;
                        }

                        &.company {
                            margin-top: 35px;
                        }

                        legend {
                            font: bold 16px $family-heading;
                            color: #3e4890;
                            margin-bottom: 15px;
                            width: 100%;
                        }

                        &.message {
                            margin-top: 1.3rem;
                        }

                        .field {
                            flex-direction: column;
                            align-items: flex-start;
                            margin-bottom: 0.1rem;

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            label {
                                text-align: left;
                                min-width: 100%;
                                @media #{mq(md)} {
                                    min-width: initial;
                                }
                            }

                            &:not(.choice) {
                                label {
                                    padding-right: 4px;
                                    margin-bottom: 5px;
                                    width: initial;
                                    @media #{mq(md)} {
                                        width: 100%;
                                    }
                                    line-height: 15px;

                                    span {
                                        font: bold 12px $family-heading;
                                        color: #3e4890;
                                        float: left;
                                        line-height: 2;
                                    }

                                    &[for="edit-address"],
                                    &[for="receiver_type_1"],
                                    &[for="receiver_type_2"] {
                                        width: calc(100% - 4rem);
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }

                            .fieldset {
                                margin-bottom: 0;
                            }

                            &.fullname {
                                overflow: hidden;
                            }
                        }

                        .fields {
                            .field:last-of-type {
                                margin-bottom: 0;
                            }
                        }

                        &.fieldset-fullname {
                            width: 100%;
                        }

                        &.login {
                            .field:not(.choice) {
                                label {
                                    width: 25.8%;
                                }
                            }
                        }
                    }

                    .fieldset .fieldset-wrapper {
                        padding-right: 50px;
                    }
                }

                table {
                    tr {
                        th {
                            font: bold 12px $family-heading;
                            color: #3e4890;
                            padding: 8px 5px;
                        }

                        td {
                            font: 500 12px $family-heading;
                            color: #3e4890;
                            padding: 8px 5px;

                            &:before {
                                display: block;
                            }

                            &.actions {
                                a {
                                    font: bold 12px $family-heading;
                                    color: #3e4890;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            .sidebars {
                order: 0;
                flex-grow: 0;
                flex-basis: 300px;
                margin-right: 15px;
                margin-bottom: 25px;
                background: #fff;
                padding: 10px 15px;
                border-radius: 13px;
                box-sizing: border-box;
            }

            .block-reorder .product-items .product-item {
                margin-bottom: 10px;
            }

            .actions-toolbar {
                margin: 0;
                float: left;
                width: 100%;

                .primary a {
                    @extend %default-button-styling;
                }
            }

            .block {
                overflow: hidden;
                margin-bottom: 15px;
                padding-bottom: 10px;

                &.block-wishlist {
                    padding-bottom: 10px;

                    .product-items {
                        .product-item {
                            margin-bottom: 10px;
                        }
                    }
                }

                .subtitle {
                    font: bold 12px $family-heading;
                    color: #3e4890;
                    margin-bottom: 10px;
                    display: block;
                }

            }
        }
    }

    &.customer-account-index {
        .columns {
            .main.column {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        padding: 15px 0;
    }

    .item {
        margin: 3px 0 0;
        width: 100%;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-left: 3px solid transparent;
            display: block;
            padding: $indent__xs 18px $indent__xs 15px;
        }

        a {
            text-decoration: none;
            color: getColor(gray, dark);

            &:hover {
                text-decoration: underline;
                color: getColor(theme, base);
            }
        }

        &.current {
            a,
            strong {
                @include lib-css(border-color, $account-nav-current-border-color);
                @include lib-css(color, $account-nav-current-color);
                @include lib-css(font-weight, $account-nav-current-font-weight);
                color: #3e4890;
            }

            a {
                @include lib-css(border-color, $account-nav-current-border-color);
            }
        }

        .delimiter {
            border-top: 1px solid $account-nav-delimiter__border-color;
            display: block;
            margin: $indent__s 1.8rem;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;

    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;

        strong {
            @include lib-heading(h4);

            .column.main & {
                font-size: $h3__font-size;
            }
        }
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: 10px;
    }

    a.action {
        font-weight: 700;
        color: #3e4890;
        text-decoration: underline;

        & + a {
            margin-left: 10px;
        }
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        @include lib-vendor-prefix-display();
        @include lib-vendor-prefix-flex-direction();

        .mage-error {
            @include lib-vendor-prefix-order(2);
        }

        .input-text {
            @include lib-vendor-prefix-order(0);
            z-index: 2;
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: 1.9rem;
    padding: 0.3rem 1rem;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 10px;
    font-size: 10px;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
        border-radius: 10px 0 0 10px;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
            border-radius: 10px;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
            border-radius: 10px;
        }
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {
    .account {
        .page.messages {
            margin-bottom: $indent__xl;
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            display: inline-block;
            margin: 0 10px 10px 0;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;

            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            &:after {
                @extend .abs-margin-for-forms-desktop;
            }

            > .field {
                > .control {
                    width: 55%;
                }
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            padding-right: 10px;
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    @include lib-css(width, $form-field-type-control-inline__width);
                    float: left;
                }
            }
        }
        .field.telephone {
            padding-right: 10px;
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    //
    //  My account
    //  ---------------------------------------------

    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;
            font-size: 0;

            > .item {
                display: inline-block;
                font-size: $font-size__base;
                margin-bottom: $indent__base;
                vertical-align: top;
                width: 48.8%;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-child(even) {
                    margin-left: 2.4%;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}

@include max-screen(1024px) {
    .account, body.customer-account-login, .customer-account-create {
        .page-wrapper {
            .columns {
                justify-content: space-between;

                .column.main {

                    .block:not(.widget) {
                        flex-basis: 100%;
                    }

                    form {
                        .fieldset {
                            width: 100%;
                            padding: 0;

                            &:nth-of-type(2),
                            &.job_title {
                                padding: 0;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .account {
        .page-wrapper {
            .columns {
                .column.main {
                    order: -1;
                    flex-basis: 100%;
                }
            }
        }
    }
}

@include max-screen(768px) {
    body.account, body.customer-account-login, .customer-account-create {
        .page-wrapper {
            .columns {
                .column.main {
                    flex-basis: 100%;
                    margin-bottom: 10px;
                }

                .sidebar-additional {
                    flex-basis: 100%;
                }

                .sidebars {
                    flex-basis: 100%;
                    margin-right: 0;
                    order: 2;
                }
            }
        }
    }
}

@include min-screen(1024px) {
    .login-container {
        .block {
            &:nth-of-type(even) {
                margin-right: 9px;
                float: left;
            }

            &:nth-of-type(odd) {
                margin-left: 9px;
                float: right;
            }
        }
    }
    body.customer-account-forgotpassword {
        .page-wrapper {
            .columns {
                justify-content: flex-start;

                .column.main {
                    width: 50%;
                    flex-grow: 0;
                    min-width: 600px;
                    flex-basis: 625px;

                    form {
                        .fieldset {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.form.password.reset {
    background-color: #fff;
    padding: 45px;
    border-radius: 13px;
    box-shadow: 0 11px 26px 0 rgba(199, 204, 206, 0.3), 0 10px 11px 0 rgba(0, 0, 10, 0.07);
    margin-bottom: 60px;

    .password-strength-meter {
        background: none;
        padding: 0;
        color: #3e4890;
        margin: 0;
    }

    .password-none .password-strength-meter:before {
        background: none;
    }

    .fieldset > .field:not(.choice) > .label {
        width: 30%;
        margin-bottom: 5px;
    }

    .fieldset > .field:not(.choice) > .control {
        width: 100%;
    }

    .actions-toolbar {
        margin: 0;

        .action.primary {
            background-image: linear-gradient(to right, #ef7d28, #fabe47, #fcc61b);
            font-weight: 700;

        }
    }
}

.field.password .control .mage-error {
    order: 0;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .form.password.reset {
        width: auto;
    }
}

.form-address-edit {
    .field.choice.set.billing {
        margin-top: 10px;
    }

    .field.message.autofill {
        margin-top: 10px;
    }
}

.box-billing-address,
.box-address-billing {
    .box-actions {
        display: none;
    }
}

.block-dashboard-info p {
    font-weight: getWeight(regular);
}

.field-name-prefix {
    .control {
        display: flex;
        margin-left: .1rem;
        margin-top: .5rem;

        .control-input {
            max-width: 100px;
            margin-right: 3rem;
        }
    }
}

.form-address-edit {
    .fieldset > .field:not(.choice) > .control,
    .fieldset .fullname .field .control,
    .fieldset .field.street .field,
    .fieldset .field.telephone,
    .fieldset .field.location {
        width: 100%;
        float: none;
    }

    .field.zip,
    .field.location .nested,
    .field.location .nested .control {
        display: flex;
        flex: 1 1;
        align-items: center;
        margin: 0 !important;

        @media #{mq(sm, max)} {
            min-width: 100%;
            flex-wrap: wrap;
        }

        @media #{mq(lg)} and #{mq(xl, max)} {
            min-width: 100%;
            flex-wrap: wrap;
        }
    }

    .field.location .nested .control {

        @media #{mq(md, min)} {
            > .control > .control {
                min-width: 7.2rem;
            }
        }

        @media #{mq(sm, max)} {
            margin-bottom: 1rem !important;
        }

        @media #{mq(lg)} and #{mq(xl, max)} {
            margin-bottom: 1rem !important;
        }
    }

    #zip {
        min-width: 7.2rem;
        margin-top: .5rem;
        margin-right: 1.5rem;

        @media #{mq(sm, max)} {
            margin-right: 0rem;
        }
    }

    .address-container {
        @media #{mq(sm, max)} {
            padding: 0 !important;
        }
    }

    .field.zip,
    #street_2 {
        margin-right: 1.5rem !important;
    }

    #street_2 {
        min-width: 6.3rem;

        @media #{mq(sm, max)} {
            min-width: 100%;
            margin-bottom: 1rem;
        }

        @media #{mq(lg)} and #{mq(xl, max)} {
            min-width: 100%;
            margin-bottom: 1rem;
        }

        &,
        + .control {
            flex: 0 1;
        }
    }

    #street_3 {
        min-width: 4.4rem;
    }

    label.label {
        flex: 0 1;
        margin: 0.2rem 0 0 !important;
        padding-right: 1rem !important;
        min-width: auto !important;
    }
}

.form-address-edit {

    .additional-business-fields-edit {
        max-width: 90%;
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto !important;
        margin-top: 10rem;

        &.hidden {
            padding: 0;
        }

        .field-address-receiver_type {
            order: 10;
            position: absolute;
            top: -3rem;
            width: 100%;
            left: 0;

            .control-input {
                display: inline-block;
            }
        }

        .field {
            margin: 0 0 20px;
        }

        .field  .label{
            padding: 6px 15px 0 0;
        }

        .field-department {
            order: 30;
        }

        .field-branch {
            order: 40;
        }

        .field-kvk_number {
            order: 50;
        }

        .company {
            order: 20;
        }
    }
}
