// Fylgja v0.2.1 (getfylgja.com)
// Licensed under MIT Open Source

@mixin load-order($index: false, $mq: true) {
    .order {
        @include build-breakpoints(true, min, $boolean: $mq) {
            &-default {
                order: 0;
            }
            &-first {
                order: -1;
            }
            &-last {
                order: 9;
            }
            @if $index != false {
                @each $order in $index {
                    @if $order < 1 and $order > 8 {
                        @error "Order value must be between 0 and 9";
                    }
                    &-#{$order} {
                        order: $order;
                    }
                }
            }
        }
    }
}
