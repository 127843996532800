// Fylgja v0.3.0 (getfylgja.com)
// Licensed under MIT Open Source

/// Use svg's as baseUri img or content value
/// @source https://codepen.io/jakob-e/pen/doMoML
/// @require {str-replace}
/// @param {string} $string - svg as string
/// @return {String} - baseUri svg string
@function svg-url($string) {
    //  Add missing namespace
    @if not str-index($string, xmlns) {
        $string: str-replace($string, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    // Chunk up string in order to avoid
    // "stack level too deep" error
    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($string)/$slice);
    @for $i from 1 through $loops {
        $chunk: str-slice($string, $index, $index + $slice - 1);
        // Encode
        $chunk: str-replace($chunk, '"', '\'');
        $chunk: str-replace($chunk, '%', '%25');
        $chunk: str-replace($chunk, '#', '%23');
        $chunk: str-replace($chunk, '{', '%7B');
        $chunk: str-replace($chunk, '}', '%7D');
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }
    @return url("data:image/svg+xml,#{$encoded}");
}
