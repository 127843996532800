.message.info {
    @include lib-message-icon-inner(info);
}

.message.error {
    @include lib-message-icon-inner(error);
}

.message.warning {
    @include lib-message-icon-inner(warning);
}

.message.notice {
    @include lib-message-icon-inner(notice);
}

.message.success {
    @include lib-message-icon-inner(success);
}
