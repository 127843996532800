.vvv-saldo-check,
.trade-index-activate {
    .page-title-wrapper {
        display: none;
    }

    .saldo-checker,
    .intersolve {
        margin-bottom: 5rem;

        .cell {
            flex: 0 0 100%;
            margin-top: 1rem;
            max-width: 100%;

            @media #{mq(lg)} {
                margin: 0 2.5rem !important; // override inline style
                flex: 0 0 calc(50% - 2.5rem);
                max-width: calc(50% - 2.5rem);

                &:first-child {
                    margin-left: 0 !important; // override inline style
                }

                &:last-child {
                    margin-right: 0 !important; // override inline style
                }
            }
        }

        .code-block {
            flex: 1;
            background: white;
            border-radius: $border-radius-def;
            border: $card-border;
            padding: $padding-content-mobile !important; // override inline style
        }

        .saldo {
            h1, p {
                &.hidden {
                    display: none;
                }
            }

            a.on-app {
                text-decoration: underline;
                color: getColor(black);
            }

            .saldo-checker-card {
                width: 100%;

                button.button-round {
                    float: right;
                    width: 40px;
                    height: 40px;
                    border-radius: 50rem;

                    i.times {
                        cursor: pointer;

                        &:before {
                            content: '\f00d';
                            color: #fff;
                            font: normal normal normal 14px/1 FontAwesome;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }

            .card-img {
                background-color: transparent;
                min-height: 24rem;
                margin: 0 auto 2rem;
                position: relative;
                max-width: 26.7rem;

                @media #{mq(lg)} {
                    max-width: 38rem;
                }

                .flipped {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    perspective: 100rem;

                    &.flipped {
                        .front {
                            transform: translate(-50%, -50%) rotateY(0deg) scale(1, 1);
                        }

                        .back {
                            transform: translate(-50%, -50%) rotateY(180deg) scale(1, 1);
                        }
                    }
                }

                .front,
                .back {
                    background-color: transparent;
                    position: absolute;
                    max-width: 26.7rem;
                    width: 100%;
                    backface-visibility: hidden;
                    top: 50%;
                    left: 50%;
                    transition: transform 0.5s ease-out;
                    border-radius: 1.2rem;
                }

                .back {
                    transform: translate(-50%, -50%) rotateY(0deg);
                    z-index: 1;
                }

                .front {
                    transform: translate(-50%, -50%) rotateY(-180deg);
                    z-index: 2;

                    &.hidden {
                        display: none;
                    }
                }
            }

            .saldo-checker-error-header {
                position: absolute;
                width: 100%;
                color: getColor(status, danger);
                z-index: 5;
                left: 50%;
                transform: translateX(-50%);
                margin: 0;
                background-color: white;
                box-shadow: 0 1rem 2.5rem 0 rgba(200, 200, 200, 0.4);
                border-radius: 1rem;
                padding: 0.5rem 1.8rem 1rem;

                h4 {
                    margin: 1rem 0;
                }

                p {
                    margin: 0;
                }

                ul {
                    padding-left: 1.8rem;
                    margin-bottom: 1.8rem;
                }

                ul li {
                    margin: 0;
                }

                p,
                ul,
                ul li {
                    color: getColor(theme);
                }
            }

            .saldo-checker-result {
                position: relative;
                z-index: 3;
                opacity: 0;
                transition: opacity 0.8s ease-out;
                margin-bottom: 6rem;

                .price {
                    background-color: white;
                    max-width: 14rem;
                    margin: -10rem auto 1.5rem;
                    box-shadow: 0 1rem 2.5rem 0 rgba(200, 200, 200, 0.4);
                    text-align: center;
                    border-radius: 1rem;
                    padding: 0.5rem 1rem;

                    .saldo-checker-balance {
                        margin: 0.5rem 0 0 0;
                        color: $color-theme;
                    }

                    .saldo-checker-header {
                        margin: 0;
                    }
                }

                > p,
                > span {
                    width: 100%;
                    text-align: center;
                    display: block;
                    color: $color-theme;
                }

                > p {
                    margin: 1rem 0 0;
                }

                > span {
                    margin: 0.5rem 0 .6rem;
                }

                &.show-result {
                    opacity: 1;
                }

                .transactions {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: .4rem;

                    p {
                        color: $color-text;
                        font-size: 1.2rem;
                        font-weight: 700;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }

            .saldo-checker-form {
                > fieldset {
                    position: relative;
                    min-height: 10rem;

                    > .field {
                        width: 100%;
                        position: relative;

                        .char-count {
                            position: absolute;
                            left: 0;

                            &:before {
                                display: none;
                            }
                        }

                        @media #{mq(md)} {
                            position: absolute;
                            width: calc(50% - 1.4rem);
                        }

                        &.number {
                            left: 0;
                        }

                        &.code {
                            right: 0;

                            @media #{mq(md, max)} {
                                margin: 4rem 0 1rem;
                            }
                        }

                        label {
                            margin-bottom: 0.5rem;
                        }

                        .progress-bar {
                            transition: all 0.3s ease-out;
                            position: absolute;
                            bottom: 0;
                            width: 0;
                            left: 1rem;
                            border-bottom: 0.1rem solid getColor(accent, base);

                            &::after {
                                @include fa-icon();
                                content: $fa-var-check-circle;
                                color: getColor(secondary, base);
                                opacity: 0;
                                position: absolute;
                                top: -0.75rem;
                                font-size: 1.5rem;
                                z-index: 4;
                                background-color: white;
                                border-radius: 100%;
                            }

                            &.valid {
                                border-color: getColor(secondary, base);

                                &::after {
                                    opacity: 1;
                                    right: -1rem;
                                }
                            }
                        }
                    }
                }

                &.hidden {
                    display: none;
                }
            }

            .saldo-checker-information {
                display: flex;
                justify-content: space-between;

                @media #{mq(md, max)} {
                    flex-direction: column-reverse;
                }

                small {
                    @extend %small;
                    background-color: getColor(gray, base);
                    color: getColor(theme, base);
                    padding: 1.5rem;
                    border-radius: $border-radius-def;

                    p {
                        line-height: 1;
                        margin: 0;
                        color: $color-theme !important;
                    }

                    @media #{mq(md)} {
                        margin: 0 4rem 0 0;
                    }
                }

                .button {
                    margin-right: 0;
                    opacity: 1;
                    transition: all 0.3s ease-in-out;

                    @media #{mq(md, max)} {
                        width: 100%;
                        margin-bottom: 2rem;
                    }

                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }

                &.hidden {
                    display: none;
                }
            }

            .saldo-checker-actions {
                display: flex;
                width: 100%;
                justify-content: space-between;

                @media #{mq(md, max)} {
                    flex-direction: column-reverse;
                }

                > a {
                    line-height: 3.5rem;
                    text-decoration: underline;

                    &::before {
                        @include fa-icon();
                        content: $fa-var-caret-left;
                        color: $color-theme;
                        margin-right: 1rem;
                    }
                }

                .actions > a {
                    @media #{mq(md)} {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }

                .saldo-back {
                    width: 70%;
                    font-weight: getWeight(bold);
                }
            }

            .on-app {
                margin-bottom: 1rem;
                width: 100%;

                @media #{mq(md)} {
                    display: none;
                }
            }

            .on-form {
                display: block;

                &.saldo-checker-actions,
                &.saldo-checker-information {
                    display: flex;
                }
            }

            .on-saldo {
                display: none;
            }

            &.show-saldo {
                .on-form {
                    display: none;
                }

                .on-saldo {
                    display: block;

                    &.saldo-checker-actions,
                    &.saldo-checker-information {
                        display: flex;
                    }
                }
            }

            .separator-arrow {
                position: absolute;
                margin-right: 1rem;
                margin-left: 1rem;
                font-family: $family-special;
                color: $color-accent-dark;
                font-size: 1.5rem;
                z-index: 4;

                @media #{mq(lg)} {
                    display: block;
                }

                &::after,
                &::before {
                    position: absolute;
                    width: 4.5rem;
                    height: 4.5rem;
                    background: transparent svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="75" height="39"><g fill="none" fill-rule="evenodd"><path d="M-384-2149h1441v4149H-384z"/><path fill="#EF7D28" d="M5.398 37.998a4.95 4.95 0 0 1-3.2.029C1.18 37.7.604 36.764.844 35.737c.124-.528.257-1.072.502-1.547 1.02-1.973 1.402-4.114 1.747-6.273.74-4.63 1.474-9.261 2.248-13.886.269-1.612.6-3.216.981-4.804.342-1.423 1.233-1.912 2.7-1.581.313.07.622.206.901.367.503.288.832.738.777 1.33-.059.65-.22 1.288-.33 1.932a16348.15 16348.15 0 0 0-3.21 18.7c-.055.318-.05.65-.057.976-.002.105.028.287.083.303.19.058.46.153.587.066 1.522-1.039 3.046-2.078 4.526-3.177 3.314-2.46 6.622-4.929 9.891-7.45 1.202-.926 2.36-.928 3.627-.242.785.425.981 1.319.37 1.893-.473.446-.966.875-1.485 1.267-3.814 2.878-7.647 5.732-11.452 8.623-1.642 1.247-3.217 2.585-4.849 3.846-.94.73-1.831 1.55-3.004 1.918zM34.86 2.808C39.532 1.24 44.341.47 49.267.364c5.77-.122 11.525.129 17.26.74 1.94.207 3.857.667 5.768 1.087.5.109.974.452 1.393.774.367.282.436.739.292 1.19-.153.483-.557.683-.998.673-.538-.013-1.075-.13-1.61-.228-1.39-.252-2.77-.607-4.171-.765-6.385-.717-12.791-1.087-19.208-.69-8.84.545-16.702 3.692-23.617 9.199-1.442 1.15-2.766 2.45-4.14 3.685a21.639 21.639 0 0 0-4.121 5.038c-.335.56-.653 1.136-1.027 1.67-.658.935-1.724 1.156-2.91.646-.799-.342-1.152-1.13-.786-1.864.879-1.76 1.855-3.481 3.198-4.917 4.181-4.473 8.742-8.474 14.292-11.2 1.953-.96 3.889-1.952 5.98-2.594z"/></g></svg>') center/contain no-repeat;
                }

                &.left {
                    left: -5.1rem;
                    top: 29%;
                    transform: translateY(-50%);
                    text-align: center;
                    line-height: 1;
                    transform: translateY(-50%) rotate(-20deg);

                    @media #{mq(lg)} {
                        left: -0.9rem;
                        top: 30%;

                    }

                    &::after {
                        content: '';
                        transform: rotate(250deg) scaleY(1);
                        left: 0.9rem;
                        top: 2.7rem;

                        @media #{mq(lg)} {
                            left: 2rem;
                            top: 2.8rem;
                        }
                    }
                }

                &.right {
                    right: -5rem;
                    top: 56%;
                    transform: translateY(-50%) rotate(-24deg);

                    @media #{mq(lg)} {
                        right: 0;
                    }

                    &::before {
                        content: '';
                        right: 0;
                        transform: rotate(70deg);
                        padding-top: 2rem;
                        left: -1rem;
                        top: -5rem;
                    }
                }
            }
        }
    }


    .saldo-checker-transactions-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(62,72,144,.19);
        backdrop-filter: blur(0.2rem);
        left: 0;

        .modal-inner-wrap {
            margin: 0 auto;
            background-color: $color-on-theme !important;

            @media (max-width: 767px) {
                min-height: 400px !important;
                width: 95%;
                max-height: 90%;
            }
        }

        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2.6rem 2.6rem 0;

            @media (max-width: 767px) {
                padding: 2rem 2rem 0;
            }

            .modal-title {
                padding: 0;
                border: none;
            }

            .action-close {
                position: unset;
                background-color: $color-theme;
                padding: 0;
                border-radius: 10rem;
                height: 4rem;
                width: 4rem;

                &::before {
                    content: "\f00d";
                    font-family: "FontAwesome";
                    color: $white;
                    font-size: 1.4rem;
                }
            }
        }

        .modal-content {
            padding-top: 1rem;
            padding-bottom: 2.6rem;

            @media (max-width: 767px) {
                padding: 0 2rem 2rem;
            }

            p {
                margin: 0;
            }
        }

        .modal-body {
            &-heading {
                p {
                    color: $color-text;
                    font-size: 1.2rem;
                    font-weight: 700;
                    line-height: 1rem;
                }
            }

            &-value {
                p {
                    display: flex;
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: $color-theme;
                    align-items: center;
                    justify-content: space-between;
                    background-color: $color-body;
                    border-radius: 1.2rem;
                    margin: 1rem 0 1.6rem;
                    padding: 0.5rem 1rem;

                    @media (max-width: 767px) {
                        margin: 2rem 0 1.6rem;
                    }
                }
            }

            &-content {
                max-height: 300px;
                overflow: hidden;

                &.has-scrollbar {
                    overflow-y: scroll;
                }

                .transaction-wrapper {
                    margin-top: 1.6rem;
                    padding: 0 1rem;
                }

                .transaction {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    &:not(:last-of-type) {
                        margin-bottom: 2rem;
                        padding-bottom: 1rem;
                        border-bottom: 1px solid $color-body;
                    }
                }

                .transaction-info {
                    .spend-date {
                        font-size: 1.2rem;
                        color: $color-text;
                        line-height: 1rem;
                    }

                    .business-name {
                        color: $color-theme;
                        font-size: 1.4rem;
                        font-weight: 700;
                    }
                }

                .transaction-cost {
                    .amount {
                        font-size: 1.2rem;
                        color: $color-text;
                        min-width: 7rem;
                        text-align: right;
                    }

                    .start-balance {
                        color: $color-theme;
                        font-size: 1.4rem;
                        font-weight: 700;
                    }
                }
            }
        }

        .modal-footer {
            display: none;
        }
    }
}
