.backtotop {
    position: fixed;
    z-index: 99;
    right: 4.25rem;
    bottom: 12rem;
    width: 5rem;
    height: 5rem;
    transition: getTrans(opacity);
    opacity: 0;
    pointer-events: none;
    color: white;
    border: 2px solid white;
    border-radius: 5rem;
    background: $color-theme;

    &:before {
        @include fa-icon();
        font-size: 4rem;
        font-weight: $font-weight__bold;
        position: absolute;
        top: 0.15rem;
        left: 1.2rem;
        content: $fa-var-angle-up;
    }

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    &:hover {
        cursor: pointer;
        background: $color-theme-dark;
        box-shadow: $dropdown-box-shadow;
    }
}
