// NOTE: keep the amount of code to a minimal or use it inline

body.customer-address-form {
    .form-address-edit {
        .field.street label {
            position: relative !important;
            height: auto !important;
        }

        .field.choice.set.shipping label {
            padding-left: 3.6rem;
        }
    }

    .nested-street {
        &, .control.addition {
            align-items: start !important;
        }
        .control.addition {
            flex-direction: column !important;
        }

        #street_3-error {
            order: 1;
        }
    }
}
