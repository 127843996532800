//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.nav-sections {
    @include lib-css(background, $navigation__background);
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size        : 28px,
        $_icon-font-color       : $header-icons-color,
        $_icon-font-color-hover : $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xls) {
    .navigation {
        padding: 0;
    }
    .nav-sections {
        @include lib-css(transition, left 0.3s, 1);
        position: fixed;
        top: 0;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        width: calc(100% - #{$active-nav-indent});
        height: 100%;
        overflow: auto;
        overscroll-behavior-y: contain;
        -webkit-overflow-scrolling: touch;
        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: .8rem 3.5rem .8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.7rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: .8rem $indent__base;
                }

                > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a {
                &,
                &:hover {
                    @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                    display: block;
                    font-weight: 400;
                    padding: .8rem $indent__base;
                }
            }

            .header.links {
                border: 0;
            }
        }
    }

    // NOTE: (1) disabled scroll-lock untill js has been added to m2 menu
    // This js should return the scroll position when menu is closed
    .nav-before-open {
        // height: 100%; *1
        overflow-x: hidden;
        width: 100%;
        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }
        body {
            // height: 100%; *1
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }

        .page-main {
            z-index: -1;
            position: relative;
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        background: darken($navigation__background, 5%);
        border: solid darken($navigation__background, 10%);
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%;

        &.active {
            background: transparent;
            border-bottom: 0;
        }

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;

        &.active {
            display: block;
        }

        padding: $indent__m 0;
    }

    .sub_menu_buy_giftcards {
        min-height: 4rem;

        div[data-content-type="button-item"] {
            margin-bottom: 0 !important;
        }

        .pagebuilder-button-primary.button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0 14px;
        }
    }

    @include lib-main-navigation();
}

@media #{mq(lg, max)} {
    .sub_menu_buy_giftcards .pagebuilder-button-primary.button span[data-element="link_text"] {
        font-size: 14px !important;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__xls) {
    .nav-toggle, .sub_menu_buy_giftcards {
        display: none !important;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;


    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content > .navigation {
        display: block;

        & > ul > li.first {
            & > a {
                border-radius: 10rem !important;
                background-color: $color-secondary;
                background-image: linear-gradient(to left, $color-secondary-light, $color-secondary);
                color: $color-on-theme;
                padding-top: 1rem !important;
                padding-bottom: 1rem !important;
                top: .8rem;

                &::after {
                    content: '\f0dd' !important;
                    background: unset !important;
                    font-family: 'FontAwesome';
                    top: -3px !important;
                    font-size: 1.1rem;
                    width: 0 !important;
                    height: 0 !important;
                    right: -.5rem !important;
                    position: relative !important;
                    transform: unset !important;
                    margin-right: 1rem !important;
                }
            }

            &.dropdown-open > a {
                color: $color-on-theme !important;

                &::after {
                    content: '\f0de' !important;
                    top: 1px !important;
                }
            }
        }
    }
}

@media #{mq(md, max)} {
    .close-menu-button {
        display: block;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 10px;
        top: 10px;

        &::after {
            content: '\f00d';
            font-weight: bold;
            font-family: 'FontAwesome';
            font-size: 1.5rem;
        }
    }
}

