// Fylgja v0.2.4 (getfylgja.com)
// Licensed under MIT Open Source

@import
"helper",
"form-field";

// 1) Prevent form collapse for form fields that are not fully supported.
// e.g. Safari and date fields
%form-field-style {
    display: $form-display;
    width: 100%;
    min-height: $form-min-height; // 1
    margin: 0;
    padding: $form-padding;
    border: $form-border-width $form-border-style $form-border-color;
    border-radius: $form-border-radius;
    box-shadow: $form-shadow;
    background-color: $form-color-bg;
    color: $form-color;
    appearance: none;
    transition: $form-transition-speed $form-transition-effect;
    transition-property: $form-transition-property;
    &:focus {
        border-color: $form-focus-color;
        box-shadow: $form-focus if($form-shadow != none, $form-shadow, null);
        outline: none;
        caret-color: $form-caret-color;
    }
}

// color set with rgba value to act the same as color black with opacity .7
// opacity set to 1 to prevent Edge from not displaying the placeholder text
::placeholder {
    color: $form-placeholder-color;
    opacity: 1;
}

label {
    display: inline-block;
    margin: $label-margin;
}

fieldset {
    min-width: 0;
    margin: $fieldset-margin;
    padding: $fieldset-padding;
    border: $fieldset-border;
}

// 1. Enable text wrapping in Edge
legend {
    display: block;
    max-width: 100%; // 1
    margin: 0;
    padding: 0;
    white-space: normal; // 1
}

optgroup {
    font-weight: bold;
    font-style: normal;
}

// Build styles for each form field type
@each $field-type in $form-inputs {
    @if ($field-type == textarea) or ($field-type == select) {
        #{$field-type} {
            @extend %form-field-style;
            @if $field-type == textarea {
                overflow: auto;
                resize: vertical;
            }
            @if $field-type == select {
                padding-right: (1.2em + strip-unit(if(length($form-padding) == 2, nth($form-padding, 2), $form-padding)));
                background-image: $icon-arrow-select;
                background-repeat: no-repeat;
                background-size: 1.2em;
                background-position:
                    top 50%
                    right if(length($form-padding) == 2, nth($form-padding, 2), $form-padding);
                &::-ms-expand {
                    // disables the select-arrow for IE browsers
                    display: none;
                }
            }
        }
    } @else if $field-type == date {
        input[type*="#{$field-type}"] {
            @extend %form-field-style;
        }
    } @else {
        input[type="#{$field-type}"] {
            @extend %form-field-style;
            @if $field-type == color {
                // unset extra padding by webkit browsers
                &::-webkit-color-swatch-wrapper {
                    padding: 0;
                }
            }
            @if $field-type == search {
                &::-webkit-search-decoration {
                    // Removes the extra space in older webkit browsers
                    // For webkit-search icon
                    -webkit-appearance: none;
                }
            }
        }
    }
}

input {
    &::-webkit-calendar-picker-indicator {
        // Set default arrow for Chrome/Safari
        width: 1em;
        height: 1em;
        padding: 0;
        background-image: $icon-arrow-select;
        background-repeat: no-repeat;
        background-size: 1.2em;
        background-position: top 50% right 50%;
        color: transparent; // Hidding the default arrow
    }
    &:disabled {
        border-style: dotted;
        box-shadow: none;
        color: $color-text-disabled;
    }
}
