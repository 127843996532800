//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size        : 15px !default;
$checkout-step-title__font-weight      : $font-weight__light !default;
$checkout-step-title__padding          : $indent__s !default;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-cart-index {
    .message.message-success.success,
    [data-ui-id="checkout-cart-validationmessages-message-success"] {
        display: none;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        @media (min-width: 1041px) {
            margin: 0 0 1.5rem;
        }
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;

    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}

.opc-wrapper {
    border: none;

}
.opc-block-summary > .title {
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $color-theme;
}

.back-to-shopping {
    position: relative;
    margin-left: 10px;
    margin-top: 2px;
    a {
        font-size: 10px;
        font-weight: 700;
        color: $color-theme;
    }
    &:after {
        content: "";
        width: 0;
        height: 0;
        -webkit-transform: rotate(-86deg);
        transform: rotate(95deg);
        border-radius: 2px 1px 1px 0px;
        display: block;
        border: 4px solid $color-theme;
        border-bottom: 4px solid transparent;
        border-left: 3px solid transparent;
        border-right: 2px solid transparent;
        position: absolute;
        left: -9px;
        top: 5px;
    }
}

.text-small {
    font-size: 14px;
}

.opc-wrapper .opc .fieldset > .field.prefix, .opc-wrapper .opc .fieldset > .fields > .field.prefix
{
    margin-bottom: 20px !important;
    margin-top: 0px !important;
}

.newsletter-subscribe {
    width: 100% !important;
    label {
        display: flex !important;
        align-items: center !important;
    }
}

#ops_iDeal_issuer_id {
    border: 2px solid #3e4890;
    color: rgba(#3e4890, 0.6);

    &[data-has-value="true"] {
        color: #000;
        font-weight: 600;
    }
}
