// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  * Port from Magento LESS files, not yet available in snowdog/theme-blank-sass
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='dynamic_block'] {
    figure[data-content-type='image'] img {
        display: inline;
    }

    .block-banners .banner-item-content,
    .block-banners-inline .banner-item-content {
        margin-bottom: auto;
    }
}
