// Store switcher module


.header .content {
  padding-top: 0 !important;
}

.switcher-container {
  margin: 0;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem 0 0;
  width: 100%;
  max-width: 100%;
  background: #f5f6f8;
  box-sizing: border-box;

  .container.container-top {
    display: flex;
    justify-content: space-between;

  }
  .container.container-top.justify-center {
    justify-content: center !important;
  }

  .switcher-dropdown {
    margin: 0 !important;
    display: flex;
    justify-content: flex-end;
    list-style: none;

    & > * {
      padding: 0 0.3rem;
    }

    .switcher-option  {
      margin-bottom: 0;
      display: flex;
      align-items: flex-end;

      a {
        border-radius: 0.3rem 0.3rem 0 0;
        text-decoration: none;
        font-size: 1.3rem;
        padding: 0 1.563rem;
        line-height: 3.2rem;
        height: 3.2rem;
        color: #435063;
      }
    }

    li.switcher-option.view.active a {
      background: #fff;
      color: #495397;
    }

    li.switcher-option.view:not(.active){
      transition: all 300ms ease-in;
      &:hover {
        border-radius: 0.3rem 0.3rem 0 0;
        background: #e4e3e3;
      }
    }
  }
}

.switcher-mobile {
  display: none;
  justify-content: flex-end;
  position: relative;
  line-height: 3.2rem;

  .mobile-switcher-p {
    display: flex;
    margin: 0;
    font-size: 1.3rem;
    border-radius: 0.3rem 0.3rem 0 0;
    background: white;
    padding: 0 1.563rem;
    line-height: 3.2rem;

    &::after {
      content: '';
      display: flex;
      width: 1em;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3Cpath d='M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z' fill='%233e4890'/%3E%3C/svg%3E") center/125% no-repeat;
    }
  }

  .mobile-switcher-p:focus + .mobile-options {
    display: flex;
  }

  .mobile-options {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 3rem;
    background: white;
    padding: 0.5rem 4rem;
    border-radius: 0.7rem;
    border: 1px solid #ccc;
    z-index: 3;

    .mobile-option {
      display: flex;
      flex-direction: column;
      a {
        text-decoration: none;
      }
    }
    .mobile-option.active a {
      border-bottom: 1px solid #ccc;
    }
  }

}



@media only screen and (max-width: 768px) {
  .switcher-mobile {
    display: flex;
  }

  .switcher-dropdown {
    display: none !important;
  }
}
