span.char-count {
    height: 1.5rem;
    display: block;
    text-align: right;
    padding-top: .5rem;
    position: relative;
}

.validation-success::before,
.validation-failed::before {
    @include fa-icon();
    display: inline-block;
    font-size: 1.6rem;
    line-height: 1.6rem;
    position: absolute;
    right: 0;
    top: -1rem;
    width: 1.6rem;
    height: 1.6rem;
    background-color: getColor(bg, white);
    border-radius: 50%;
}

.validation-success {
    &::before {
        content: $fa-var-check-circle;
        color: getColor(status, success);
    }
}

.validation-failed {
    &::before {
        content: $fa-var-times-circle;
        color: getColor(status, danger);
    }
}
