//
//  Variables
//  _____________________________________________

.fieldset {
    @include lib-form-fieldset();

    &:last-child {
        margin-bottom: 0;
    }

    > .field,
    > .fields > .field {
        @include lib-form-field();

        &.no-label {
            > .label {
                @extend .abs-visually-hidden;
            }
        }

        &.choice .label {
            font-weight: normal;
        }

        .label {
            .column:not(.main) & {
                font-weight: normal;
            }
        }

        .field.choice {
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        input[type=file] {
            margin: $indent__xs 0;
        }
    }

    .legend {
        @extend .abs-margin-for-forms-desktop;
    }

    > .field {
        @include min-screen($screen__m) {
            @include lib-form-field-type-revert();
            margin: 0 0 $form-field__vertical-indent__desktop;
        }
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}

.legend {
    strong {
        margin-left: $indent__xs;
    }
}

fieldset.field {
    border: 0;
    padding: 0;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"],
textarea,
select {
    font-size: map-get($input, font-size);
    appearance: none;

    &:hover {
        border-color: map-deep-get($input, hover, border);
    }

    &:focus {
        font-size: map-get($input, font-size);
        border-color: map-deep-get($input, focus, border);
    }

    &:disabled {
        background-color: map-deep-get($input, disabled, bg);
        border-color: map-deep-get($input, disabled, border);
        opacity: map-deep-get($input, disabled, opacity);
    }

    &.mage-error,
    &[aria-describedby^="error-"],
    &.valid[aria-email-valid="false"] {
        border-color: map-deep-get($input, error, border);
        background-color: map-deep-get($input, error, bg);
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
    &.mage-error,
    &[aria-describedby^="error-"],
    &.valid[aria-email-valid="false"] {
        padding-right: if(length($form-padding) == 2, (nth($form-padding, 2) + 1rem), ($form-paddings + 1rem));
        background-image: $icon-error;
        background-repeat: no-repeat;
        background-size: 1.2rem;
        background-position: top 50% right 10px;
    }

    &[aria-valid="true"] {
        padding-right: if(length($form-padding) == 2, (nth($form-padding, 2) + 1rem), ($form-paddings + 1rem));
        background-image: url(../images/check-solid.svg);
        background-repeat: no-repeat;
        background-size: 12px 12px;
        background-position: top 50% right 10px;
    }
}

select {
    padding-right: if(length($form-padding) == 2, (nth($form-padding, 2) + 1rem), ($form-paddings + 1rem));
    background-image: $icon-arrow-select;
    background-repeat: no-repeat;
    background-size: 1.6rem;
    background-position: top 50% right 10px;

    &::-ms-expand {
        // Disables the select-arrow for IE browsers
        display: none;
    }

    &[multiple] {
        // Reset styles set by Magento-ui
        height: auto;
        overflow: auto;
    }
}

/* Show arrow on IE for selects */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    select {
        background-size: auto !important;
        background-position: 40px !important;
    }
}

form {
    .field {
        &.date {
            @extend .abs-field-date;

            .time-picker {
                display: inline-block;
                margin-top: $indent__s;
                white-space: nowrap;
            }
        }

        .message {
            &.warning {
                margin-top: $indent__s;
            }
        }

        &._warn {
            .control {
                input[type="text"],
                input[type="password"],
                input[type="url"],
                input[type="tel"],
                input[type="search"],
                input[type="number"],
                input[type="datetime"],
                input[type="email"] {
                    background: url(../images/warn@2x.png);
                    padding-right: 2.6rem;
                    background-repeat: no-repeat;
                    background-size: 1.2rem;
                    background-position: top 50% right 10px;
                    border-color: #ef7d28;
                    background-color: #fdf6f2;
                }
            }

            .message.warning {
                background: transparent;
                padding: 0;
                color: #ef7d28;
                font-size: 10px;
                margin: 2px 0 0 5px;

                span {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        ._has-datepicker {
            ~ .ui-datepicker-trigger {
                &:before {
                    line-height: 28px;
                }
            }
        }
    }
}

.fieldset.address {
    .field legend.label {
        > span {
            padding-bottom: 0.5rem;

            &:last-child:first-child {
                margin-top: 0;
            }
        }

        .label.additional {
            padding-bottom: 0.5rem;
            padding-top: 0.6rem;
            margin-top: 2.4rem;
        }
    }

    .field.street legend.label {
        > span {
            margin-top: 0.3rem;
        }
    }

    .field.street .control .field {
        margin: 0;

        .control {
            margin: 0 0 2rem;
        }
    }

    .field.street .control .field:last-child .control {
        margin: 0;
    }
}

$form-checkbox-size: 21px;
input[type="checkbox"]:not([class^=Cybot]) {
    @include sr-only;
    $form-checkbox-stroke: 2px;

    & + label {
        position: relative;
        display: inline-block;
        min-height: $form-checkbox-size;
        font-family: $family-heading;
        font-size: 1.2rem;
        line-height: $form-checkbox-size;
        color: $color-text;
        padding-left: ($form-checkbox-size + 15px);

        &::before {
            content: $fa-var-check;
            position: absolute;
            top: 0;
            left: 0;
            width: ($form-checkbox-size - ($form-checkbox-stroke * 2));
            height: ($form-checkbox-size - ($form-checkbox-stroke * 2));
            border-radius: 5px;
            border: $form-checkbox-stroke solid getColor(gray, light);
            background-color: white;
            color: getColor(theme, base);
            font-family: FontAwesome;
            font-size: 0;
            line-height: ($form-checkbox-size - ($form-checkbox-stroke * 2));
            text-align: center;
        }

        &:hover::before {
            border-color: $color-theme;
        }
    }

    &:checked + label::before {
        border-color: $color-theme;
        font-size: 15px;
    }

    &:disabled {
        & + label {
            &::before {
                color: gray;
            }

            &:hover::before {
                border-color: gray;
            }
        }

        &:checked + label::before {
            border-color: gray;
        }
    }
}

input.radio {
    @include sr-only;
    $form-radio-size: 21px;
    $form-radio-stroke: 2px;

    & + label {
        position: relative;
        display: inline-block;
        min-height: $form-radio-size;
        font-family: $family-heading;
        font-size: 1.2rem;
        line-height: $form-radio-size;
        color: $color-text;
        padding-left: ($form-radio-size + 15px);

        &::before {
            content: $fa-var-circle-o;
            position: absolute;
            top: 0;
            left: 0;
            color: getColor(gray, light);
            font-family: FontAwesome;
            font-size: 20px;
        }

        &:hover::before {
            color: getColor(theme, base);
        }
    }

    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(255, 255, 0, .7);
    }

    &:checked + label::before {
        content: $fa-var-dot-circle-o;
        color: $color-theme;
    }
}

.field-error,
.error[generated],
.mage-error,
div.mage-error[generated] {
    margin-top: 0px;
    font-size: 10px;
    color: #ff0000;
}

.field-error,
.error[generated] {
    @include lib-form-validation-note();
}

.field .tooltip {
    @include lib-tooltip(right);

    .tooltip-content {
        min-width: 200px;
        white-space: normal;
    }
}

input,
select {
    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

._has-datepicker {
    ~ .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content: $icon-calendar,
            $_icon-font-color: $primary__color__lighter,
            $_icon-font-size: $icon-calendar__font-size,
            $_icon-font-line-height: $icon-calendar__font-size,
            $_icon-font-display: block,
            $_icon-font-text-hide: true
        );
        display: inline-block;
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

.form-row {
    display: flex;
    align-items: center;
}

//
//  Sidebar forms
//  -----------------------------------------

.sidebar {
    .fieldset {
        margin: 0;

        & > .field:not(.choice),
        .fields > .field {
            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 4px;
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}

//
//  Field validation check
//  -----------------------------------------

.email.valid::after {
    @include fa-icon();
    content: $fa-var-check;
    color: getColor(secondary, light);
    position: absolute;
    right: 2rem;
    bottom: 1.75rem;
    width: 1rem;
    height: 1rem;
    line-height: 2.2rem;
}
