// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Full Width row appearance styles
//  ________________________________

[data-content-type='row'][data-appearance='full-width'] {
    box-sizing: border-box;
    width: 100vw;

    > .row-full-width-inner {
        box-sizing: border-box;
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: calc(#{$layout__max-width} - #{$layout-indent__width});
        width: 100%;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $screen__m) {
    [data-content-type='row'][data-appearance='full-width'] {
        background-attachment: scroll !important;
    }
}
