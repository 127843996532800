.swal2-container {
    .swal2 {
        &-popup {
            padding: 2.6rem;
            border-radius: 1.5rem;
        }

        &-close {
            background-color: $color-theme;
            border-radius: 10rem;
            height: 5rem;
            width: 5rem;
            color: #fff !important;
            font-size: 3.5rem;
            font-weight: 600;
        }

        &-icon {
            display: none !important;
        }

        &-html-container {
            margin: 0;
            padding: 6rem 0 2rem;

            .alert-message {
                margin: 0;
            }

            ul {
                padding: 0;
                margin: 0;
            }

            li {
                list-style-type: none;
                margin: 0;
            }
        }

        &-actions {
            margin: 0;
        }

        &-confirm {
            background-color: $color-accent !important;
            background-image: $gradient-cta !important;
            color: $color-on-theme;
            border-radius: 10rem;
            min-width: 14.8rem;
            font-size: 1.6rem !important;
            font-weight: 600;
            box-shadow: none !important;

            &:hover {
                box-shadow: 0 0.4rem 0.7rem rgba($color-accent, .5) !important;
            }
        }
    }
}
