.footer.content {
    padding: 0 0 3rem;
    color: white;
    background-image: getColor(gradient);

    @media #{mq(sm, max)} {
        padding-top: 0;
    }

    @media #{mq(sm)} {
        padding-top: 6rem;
    }

    @media #{mq(md)} {
        padding-bottom: 6rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    .trigger {
        font-weight: getWeight(semibold);
        display: inline-block;
        margin: 0 0 1.2rem;
        color: $color-on-accent;

        @media #{mq(sm, max)} {
            position: relative;
            display: block;
            margin: 0;
            padding-top: 0;
            padding-bottom: 1.4rem;

            &::after {
                @include fa-icon();
                font-size: 1rem;
                line-height: 2.4rem;
                float: right;
                content: $fa-var-chevron-down;
                transition: getTrans(transform);
            }

            &.active::after {
                transform: rotate(180deg);
            }

            &:not(.active) {
                margin-bottom: 0;
                padding: 0;

                & ~ * {
                    display: none;
                }
            }
        }

        a {
            font-size: inherit;
            text-decoration: none;

            &:active,
            &:focus,
            &:hover {
                text-decoration: underline;
            }
        }
    }

    a {
        color: $color-on-accent;

        &,
        &:visited {
            color: $color-on-accent;
        }

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
            color: $color-on-accent;
        }
    }

    ul a,
    ol a {
        font-size: getSize(p);
        font-weight: getWeight(regular);
        text-decoration: none;

        @media only screen and (min-width: 992px) and (max-width: 1075px) {
            font-size: 1.4rem;
        }
    }

    .invert-margin {
        max-width: 25rem;
        margin: -60% auto 0.8rem;
    }

    .pagebuilder-column-group,
    .pagebuilder-column-group .pagebuilder-column-line {
        @media #{mq(lg, max)} {
            flex-wrap: wrap;

            .pagebuilder-column {
                width: calc(100% / 3) !important; //override element style
            }
        }

        @media #{mq(lg)} {
            flex-wrap: nowrap;
        }

        .pagebuilder-column {
            position: relative;
        }
    }

    .sitting-giftcard {
        position: relative;
        top: 0;

        @media #{mq(md, max)} {
            display: flex;
            flex-direction: column;
        }

        @media #{mq(md)} {
            position: absolute;
            top: -230px;

            @for $i from 1 through 6 {
                h#{$i}:not(:first-child),
                div p {
                    text-align: center;
                }
            }
        }

        p a img {
            padding: 0 4%;
        }
    }
}
