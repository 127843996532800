.pn-banner {
    position: relative;
    z-index: 0;
    width: 100%;
    background: $gradient-theme;
    padding:  .2rem 0;

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-right: 3.5rem;
    }

    &-desktop {
        transform: translateY(2rem);

        @media (max-width: 767px) {
            display: none;
        }
    }

    &-mobile {
        @media (min-width: 767px) {
            display: none;
        }
    }

    &-text {
        font-size: 1.4rem;
        font-weight: 700;
        color: $color-on-theme;
        margin: 0;

        a {
            color: inherit !important;
            text-decoration: underline;
        }
    }

    &-icon {
        position: absolute;
        max-width: 10px;
        max-height: 10px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin-right: 1rem;
        cursor: pointer;
    }
}
