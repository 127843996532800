//
//  Layout variables
//  _____________________________________________

//  Widths
$layout__width                      : '' !default; // for the fixed width layout
$layout__max-width                  : 1270px !default;
$layout-indent__width               : 20px !default;
$layout__width-xs-indent            : 15px !default;

//  Classnames defining different layouts
$layout-class-1column               : page-layout-1column !default;
$layout-class-2columns__left        : page-layout-2columns-left !default;
$layout-class-2columns__right       : page-layout-2columns-right !default;
$layout-class-3columns              : page-layout-3columns !default;

//  Variables used for layout grid
$total-columns                      : 12 !default;
$gutter-width                       : 0 !default;

//  Variables for layout columns
$layout-column__width               : $total-columns !default;
$layout-column__sidebar-width       : 2 !default;
$layout-column__left-width          : $layout-column__sidebar-width !default;
$layout-column__right-width         : $layout-column__sidebar-width !default;

//  Variables for layout columns depending on layout used
$layout-column-main__width-1        : 100% !default;
$layout-column-main__width-2-left   : $layout-column__width - $layout-column__left-width !default;
$layout-column-main__width-2-right  : $layout-column__width - $layout-column__right-width !default;
$layout-column-main__width-3        : $layout-column__width - $layout-column__left-width - $layout-column__right-width !default;

//  Checkout columns width
$layout-column-checkout__width-left : 3 !default;
$layout-column-checkout__width-main : $layout-column__width - $layout-column-checkout__width-left !default;
