// Fylgja v0.3.0 (getfylgja.com)
// Licensed under MIT Open Source

/// Calculate the luminance for a color.
/// @require {linear-color-channel} - map
/// @param {Color} - Color to base luminace on
/// @return {Number} - luminance number
@function luminance($color) {
    $red: nth($linear-color-channel, red($color) + 1);
    $green: nth($linear-color-channel, green($color) + 1);
    $blue: nth($linear-color-channel, blue($color) + 1);
    @return .2126 * $red + .7152 * $green + .0722 * $blue;
}

/// Calculate the contrast ratio between two colors.
/// @require {luminance} - function
/// @param {Color} $back - base color
/// @param {Color} $front - color to check against
/// @return {Number} - light/dark ratio number
@function contrast-ratio($back, $front) {
    $backLum: luminance($back) + .05;
    $foreLum: luminance($front) + .05;
    @return max($backLum, $foreLum) / min($backLum, $foreLum);
}

$min-contrast: 4.51 !default;
/// Determine whether to use black or white
/// @require {contrast-ratio} - function
/// @param {color} $color - Color to base contrast on
/// @param {Color} $dark - (Optional) dark color to return
/// @param {Color} $light - (Optional) light color to return
/// @param {Number} $min-contrast - Minamal contrast value
/// @return {Color} - return contrasting color
@function contrast($color, $dark: rgba(black, .87), $light: white, $min-contrast: $min-contrast) {
    $light-contrast: contrast-ratio($color, white);
    $dark-contrast: contrast-ratio($color, black);
    @if ($light-contrast < $min-contrast) and ($dark-contrast > $light-contrast) {
        @return $dark;
    } @else {
        @return $light;
    }
}
