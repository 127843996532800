.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    @include lib-css(background, $sidebar__background-color);
    margin-bottom: $indent__m;
    padding: 1px 15px $indent__m;

    > .title {
        display: block;
        @include lib-heading(h3);
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 1.5rem 0 $indent__m $indent__xs;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods .field > .label {
                display: inline;
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }
    }

    .actions-toolbar > .primary button {
        @extend .abs-revert-secondary-color;
    }
}

.cart-totals {
    @extend .abs-sidebar-totals;

    .table-wrapper {
        overflow: inherit;
        margin-bottom: 0;
    }
}

.cart.item {
    .item-info {
        td {
            span {
                text-transform: capitalize;
            }
        }
    }
}

.cart.table-wrapper {
    .items {
        thead + .item {
            border-top: 0;
        }

        > .item {
            position: relative;
            border-bottom: 0;
        }
    }

    .col {
        padding-top: $indent__base;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -0.5rem;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .col.item {
            position: relative;
            display: block;
            min-height: 10rem;
            padding: 0.7rem 0 1.5rem 13rem;
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        > .action {
            @extend button;
            @include lib-link-as-button();
            margin-right: $indent__s;
            margin-bottom: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action.help.map {
        @extend .abs-action-button-as-link;
        font-weight: $font-weight__regular;
    }

    .product {
        &-item-photo {
            position: absolute;
            top: 15px;
            left: 0;
            display: block;
            width: 100%;
            max-width: 11rem;
            padding: 0;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            font-weight: $font-weight__regular;
            display: inline-block;
            margin-top: -0.6rem;
        }
    }

    .gift-registry-name-label::after {
        content: ':';
    }

    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: getSize(small);
        color: getColor(text, dark);
    }

    .product-item-name + .item-options {
        margin-top: 1rem;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: 1.5rem;
            }
        }
    }
}

.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 1.5rem;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

.block.crosssell {
    margin-top: 7rem;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        box-sizing: border-box;
        padding-top: 0.4rem;
        text-align: left;
        vertical-align: middle;
    }
}

.checkout-cart-index {
    .page-main.container {
        position: relative;
        box-sizing: border-box;
        padding: 0 1rem;

        .columns .column.main {
            padding-bottom: 8rem;
        }
    }

    .cart-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;

        .cart-summary {
            width: 302px;
            padding: 1.5rem;
            border-radius: 1.5rem;
            background-color: white;

            > .title {
                @extend %h6;
            }

            .mark,
            .amount {
                font-family: $family-body;
                font-size: getSize(small);
                padding: 0.8rem 0;
                color: getColor(gray, dark);

                strong {
                    font-weight: bold;
                }
            }

            .mark {
                @include lib-css(color, $link__color);

                span.qty {
                    color: getColor(gray, dark);
                }
            }

            .product {
                .mark,
                .amount {
                    padding: 0.3rem 0;
                }
            }

            .amount {
                text-align: right;
            }

            .grand.totals {
                .mark,
                .amount {
                    font-family: $family-body;
                    font-size: getSize(p);
                    padding-top: 1rem;
                    color: getColor(theme, base);
                }
            }

            .sub.totals {
                .mark,
                .amount {
                    padding-top: 3rem;
                }
            }

            .totals.shipping,
            .totals-tax {
                display: none;

                &-summary span.price {
                    padding-right: 0;
                }
            }

            .checkout-methods-items {
                margin-top: 1.2rem;

                .item a.continue {
                    @extend .button, .color-blank;
                    width: 100%;

                    &:hover {
                        text-decoration: none;
                        color: getColor(text, base);
                    }
                }
            }
        }

        .form-wrap {
            font-family: $family-heading;
            order: -1;
            box-sizing: border-box;
            width: calc(100% - 317px);
            padding: 2.8rem 6.3rem 2.8rem 2.8rem;
            border-radius: 15px;
            background-color: white;

            .form-cart {
                .cart.table-wrapper {
                    tr th {
                        padding-top: 0;
                        padding-bottom: 1rem;
                        text-align: left;

                        span {
                            text-transform: capitalize;
                            font-family: $family-body;
                            font-size: getSize(p);
                            color: getColor(theme, base);
                        }

                        &.item {
                            padding-bottom: 1rem;
                            padding-left: 0;
                        }
                    }

                    .col {
                        .price-excluding-tax {
                            font-family: $family-heading;
                            font-size: getSize(p);
                            text-align: center;
                            color: getColor(theme, base);
                        }

                        .qty {
                            margin-top: 2px;
                            text-align: center;

                            input {
                                font-size: getSize(small);
                                padding: 0.5rem;
                                border: solid 1px getColor(gray, light);
                                border-radius: 10px;
                                background-color: white;
                                -moz-appearance: textfield;
                            }
                        }

                        &.remove {
                            width: 2.6rem;
                            padding-top: 0.4rem;
                            padding-right: 0;
                            vertical-align: middle;

                            .action-edit {
                                display: none;
                            }

                            .action-delete {
                                display: block;
                                float: right;
                                text-align: right;
                            }

                            & > div.action {
                                display: none;
                            }
                        }
                    }

                    .product-item-photo {
                        .product-item-zoom {
                            position: relative;
                            z-index: 9;

                            &::before {
                                font-family: FontAwesome;
                                font-size: getSize(p);
                                position: absolute;
                                top: -4px;
                                left: 2px;
                                display: block;
                                content: '\f00e';
                                color: getColor(theme, base);
                            }
                        }

                        .product-image-wrapper {
                            img {
                                width: auto;
                                max-height: 10rem;
                            }
                        }
                    }

                    .product-item-name {
                        margin-bottom: 1rem;

                        a {
                            font-family: $family-heading;
                            font-size: getSize(p);
                            color: getColor(theme, base);
                        }
                    }

                    .item-options {
                        margin: 0 0 0.7rem;

                        dt {
                            display: none;
                        }

                        dd {
                            font-family: $family-body;
                            font-size: getSize(small);
                            line-height: 2rem;
                            position: relative;
                            clear: both;
                            margin: 0;
                            padding-left: 1rem;
                            color: getColor(gray, dark);

                            .price,
                            .recipient {
                                font-weight: bold;
                                color: getColor(theme, base);
                            }

                            &::before {
                                position: absolute;
                                top: -1px;
                                left: 0;
                                content: '+';
                            }
                        }
                    }

                    .item {
                        .action-delete,
                        .action-towishlist {
                            display: none;
                        }
                    }

                    .action-edit {
                        font-family: $family-heading;
                        font-size: getSize(small);
                        text-decoration: underline;
                        color: getColor(theme, base);
                    }
                }

                .data-th {
                    display: none;
                }

                .action-delete {
                    background: transparent;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    span::before {
                        font-family: FontAwesome;
                        font-size: getSize(p-small);
                        position: relative;
                        top: 1px;
                        right: -7px;
                        content: '\f1f8';
                        color: getColor(gray, dark);
                    }
                }
            }

            .block.discount {
                position: relative;
                margin-top: 2rem;

                &::before {
                    position: relative;
                    top: -2px;
                    display: block;
                    width: 100%;
                    height: 1px;
                    content: '';
                    background-color: getColor(gray, light);
                }

                .title {
                    font-size: getSize(p);
                    margin: 1.7rem 0 0.9rem;
                    color: getColor(theme, base);

                    strong {
                        font-size: inherit;
                    }
                }

                .field {
                    float: left;
                    margin-bottom: 1.5rem;

                    label {
                        display: none;
                    }

                    input {
                        font-family: $family-heading;
                        font-size: getSize(small);
                        width: auto;
                        min-width: 182px;
                        margin-right: 1rem;
                        border: solid 1px getColor(gray, light);
                    }
                }

                .actions-toolbar {
                    float: left;
                    margin-left: 0;

                    .apply {
                        font-family: $family-heading;
                        font-size: getSize(p-small);
                        min-width: 118px;
                        transition: background 0.6s cubic-bezier(0, 0.18, 0.21, 1), box-shadow 0.6s cubic-bezier(0, 0.18, 0.21, 1);
                        border-radius: 25px;
                        background-color: getColor(theme, base);

                        &:hover {
                            background-color: getColor(theme, base);
                            box-shadow: 0 0.4rem 0.7rem rgba(62, 72, 144, 0.5);
                        }
                    }
                }

                @media #{mq(md, max)} {
                    display: none;
                }
            }
        }

        #gift-options-cart {
            width: 100%;
        }
    }

    @media #{mq(md, max)} {
        .footer.newsletter,
        .footer,
        .header-minicart .minicart-wrapper,
        .breadcrumbs.container {
            display: none;
        }
    }
}

@include max-screen(1024px) {
    .checkout-cart-index {
        .page-main.container {
            .columns {
                .column.main {
                    padding-bottom: 5rem;
                }
            }
        }

        .cart-container {
            flex-direction: row-reverse;

            .form-wrap {
                width: 100%;
                margin-bottom: 2rem;
                padding: 2rem;

                .form-cart .cart.table-wrapper .col {
                    &.remove {
                        width: 2rem;
                    }

                    @media #{mq(md, max)}{
                        &.price,
                        &.qty,
                        &.subtotal,
                        &.msrp {
                            max-width: 7.5rem;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(768px) {
    .checkout-cart-index {
        .breadcrumbs .items {
            padding-top: 1rem;
        }

        .page-title {
            font-size: 1.7rem;
            margin-bottom: 2rem;
        }

        .page-main.container .cart-container {
            .form-wrap {
                padding: 1rem 1.5rem;

                .form-cart .cart.table-wrapper {
                    tr th {
                        display: none;

                        &.item {
                            display: block;
                            padding-bottom: 0;

                            span {
                                font-size: getSize(p-small);
                            }
                        }
                    }

                    .product-item-photo .product-image-wrapper img {
                        width: auto;
                        max-height: 8.5rem;
                    }
                }

                .item + .item {
                    display: block;
                    margin-top: 0.8rem;
                    padding-top: 0.4rem;
                    border-top: 1px solid getColor(gray, light);
                }

                .item-info {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .col {
                        position: relative;

                        &.item {
                            width: 100%;
                            min-height: 8.5rem;
                            padding: 0.7rem 0 1.6rem 10.9rem;
                        }

                        .data-th {
                            font-family: $family-heading;
                            font-size: getSize(small);
                            position: absolute;
                            top: -5px;
                            left: 1px;
                            display: block;
                            color: getColor(theme, base);
                        }

                        &.price,
                        &.qty,
                        &.subtotal,
                        &.msrp {
                            padding-top: 2.3rem;
                            padding-right: 0;
                            padding-left: 0;
                        }

                        &.remove {
                            width: 0;
                            padding: 1.3rem 0 0;

                            a span::before {
                                right: 0;
                            }
                        }

                        &.qty {
                            padding-right: 2.2rem;
                        }

                        .qty {
                            margin-top: -0.4rem;
                        }
                    }

                    .product-item-photo {
                        max-width: 8.5rem;
                    }
                }

                .block.discount {
                    margin-top: -1rem;

                    .field input {
                        width: 17rem;
                        min-width: 17rem;
                        margin-right: 1.4rem;
                    }

                    .actions-toolbar .apply {
                        width: 9.6rem;
                        min-width: 9.6rem;
                    }
                }
            }

            .cart-summary {
                width: 100%;
            }
        }
    }
}



@include min-screen(1200px) {
    .checkout-cart-index {
        .breadcrumbs .items {
            max-width: 1270px;
        }

        .page-main.container {
            max-width: 1270px;

            .product-item-details {
                min-width: 161px;
            }

            .cart-container .form-wrap .form-cart .action-delete span::before {
                right: -3px;
            }
        }
    }
}

.trigger-custom-popup-cart {
    text-decoration: none !important;
    font-weight: unset !important;
}

.custom-popup-cart {
    display: none;
    position: absolute;
    width: 50%;
    height: fit-content;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding: 50px 0;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.23);
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.23);
    @media #{mq(md, max)} {
        width: 80%;
    }
    p {
        margin-bottom: 30px;
    }

    &.active {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .button-wrapper {
        display: flex;
        justify-content: space-around;

        .custom-popup-cart-button {
            background: #3e4890;
            padding: 10px 20px;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            text-transform: unset !important;
            &::before {
                display: none;
            }
        }

        .action-delete {
            position: relative;
            top: 5px;
            display: block !important;
        }
    }
}

.product-item-details {
    .trigger-custom-popup-cart {
        display: none;

        @media #{mq(md, max)} {
            display: block;
        }
    }
}

// Payment methods

.cart-payment-methods {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &-images {
        width: 302px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 0.5rem;
    }

    @include max-screen(768px) {
        display: none;
    }
}
