$enable-container-box-sizing: true;

$config-container: (
    gutters: (
        xs: 10px,
        xl: 10px
    ),
    max-widths: (
        xs: null,
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1270px
    )
);
