// Fylgja
// getfylgja.com
// Licensed under MIT Open Source

// Body var's
$root-color: black !default;
$color-bg: white !default;
$color-text: rgba(black, .87) !default;

// Elevation var's
$load-card-elevation: false !default;
@if (function-exists(elevation) == false) and ($load-card-elevation) {
    @error "Fylgja Elevation package missing";
}

// Card var's
$card-transition: all .15s linear !default;

$card: (
radius: 4px,
border: if($load-card-elevation, none, 1px solid rgba(black, .12)),
elevation: if($load-card-elevation, #{elevation(1)}, none),
bg-color: lighten($color-bg, 10%),
color: $color-text,
) !default;

$card-media: (
padding: 0,
bg-color: rgba($root-color, .12),
color: $color-text,
) !default;

$card-content: (
padding: 1rem,
bg-color: transparent,
color: $color-text,
) !default;

$card-action: (
padding: (1rem / 1.5) 1rem,
bg-color: rgba($root-color, .03),
color: $color-text,
) !default;

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: map-get($card, radius);
    border: map-get($card, border);
    box-shadow: map-get($card, elevation);
    background-color: map-get($card, bg-color);
    color: map-get($card, color);
    overflow: hidden;
    transition: $card-transition;
    // Card type for media content
    &-media {
        flex: 0 0 auto;
        padding: map-get($card-media, padding);
        background: map-get($card-media, bg-color) center/cover no-repeat;
        color: map-get($card-media, color);
    }
    // Card type for any form of content
    &-content {
        flex: 1 1 auto;
        padding: map-get($card-content, padding);
        background-color: map-get($card-content, bg-color);
        color: map-get($card-content, color);
        > :last-child {
            margin-bottom: 0;
        }
    }
    // Card type specialy action elements like buttons
    &-action {
        flex: 0 1 auto;
        padding: map-get($card-action, padding);
        background-color: map-get($card-action, bg-color);
        color: map-get($card-action, color);
    }
}
