// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Poster appearance styles
//  _____________________________________________

.pagebuilder-banner-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}
