//
//  Actions-toolbar variables
//  _____________________________________________

$actions-toolbar__margin: false !default;
$actions-toolbar__padding: false !default;

$actions-toolbar-actions__position: justify !default; // [left|right|center|justify]
$actions-toolbar-actions__reverse: false !default; // [true|false]
$actions-toolbar-actions__margin: false !default;

$actions-toolbar-actions-primary__margin: 0 $indent__xs 0 0 !default;
$actions-toolbar-actions-secondary__margin: false !default;

$actions-toolbar-actions-links__margin-top: false !default;
$actions-toolbar-actions-links-primary__margin-top: false !default;
$actions-toolbar-actions-links-secondary__margin-top: 6px !default;
