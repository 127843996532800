.webforms .recaptcha {
    float: none;
    margin: 0 0 5rem;
    text-align: right;

    .g-recaptcha {
        display: inline-block;
        width: auto;
    }
}

.contact .webforms .recaptcha {
    margin-top:  -4.3rem;
}

a#g-token-submit {
    text-decoration: none;
}
