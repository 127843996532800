// Body var's
$root-color: black !default;
$color-bg: white !default;
$color-text: rgba(black, .87) !default;
$card-border: 1px solid $color-gray-middle1;
$overlay-gradient: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));

// Elevation var's
$load-card-elevation: false !default;

// Card var's
$card-transition: all .15s linear !default;

$card: (
    radius: 15px,
    border: $card-border,
    elevation: none,
    bg-color: lighten($color-bg, 10%),
    color: $color-text
);

$card-media: (
    padding: 0,
    bg-color: transparent,
    color: $color-text
);

$card-content: (
    padding: 0,
    bg-color: lighten($color-bg, 10%),
    color: $color-text
);

$card-action: (
    padding: 4.5rem 0 0,
    bg-color: lighten($color-bg, 10%),
    color: $color-text
);
