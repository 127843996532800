// 1) IE needs a static width/height for flex alingment

%style-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: $color-theme;
    color: $color-on-theme;
}

.hero {
    $hero-mq: (sm, md, lg, xl);

    &.style-main {
        $hero-bg: transparent;
        $hero-grid: 2;
        $hero-grid-gap: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $hero-bg;
        font-size: 1.6rem;

        @media #{mq(nth($hero-mq, 2))} {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 7px;
            margin-bottom: 60px;
            margin-left: -#{$hero-grid-gap};
        }

        @media #{mq(nth($hero-mq, 3))} {
            margin-top: 50px;
            margin-bottom: 80px;
        }

        .cell {
            box-sizing: inherit;

            @media #{mq(nth($hero-mq, 2))} {
                padding-left: $hero-grid-gap;
            }
        }

        .media {
            max-width: 270px;
            margin-right: -32px;

            @media #{mq(nth($hero-mq, 1))} {
                margin-right: 0;
            }

            @media #{mq(nth($hero-mq, 2))} {
                max-width: 632px;
                order: 2;
                width: 50%;
            }

            @media #{mq(nth($hero-mq, 3))} {
                width: 55%;
            }
        }

        .content {
            max-width: 495px;
            align-self: center;

            @media #{mq(nth($hero-mq, 2))} {
                max-width: 355px;
                width: 50%;
                margin-top: 100px;
            }

            @media #{mq(nth($hero-mq, 3))} {
                max-width: 495px;
                width: 45%;
                margin-top: 0;
            }
        }

        .title {
            margin-top: 20px;
            margin-bottom: 20px;
            color: $color-theme;
            font-size: 1.375em;
            line-height: 1.3;
            font-weight: 700;

            @media #{mq(nth($hero-mq, 1))} {
                margin-top: 0;
                margin-bottom: 35px;
                font-size: 1.69em;
            }

            @media #{mq(nth($hero-mq, 3))} {
                font-size: 2.25em;
            }
        }

        .description {
            margin-bottom: 35px;
            color: $color-theme;
            font-size: getSize(h3);
            font-weight: 700;

            @media #{mq(nth($hero-mq, 3))} {
                font-size: 1.25em;
            }
        }

        .actions {
            display: flex;
            align-items: center;

            .button {
                &:nth-of-type(1) {
                    flex-grow: 1;
                    max-width: 190px;
                }

                &:nth-of-type(2) {
                    flex-grow: 2;
                    max-width: 215px;
                }
            }
        }

        .separator {
            position: relative;
            margin-right: 8px;
            margin-left: 8px;
            color: $color-theme;
            font-weight: 700;

            @media #{mq(nth($hero-mq, 1))} {
                margin-right: 10px;
                margin-left: 10px;
            }

            &-arrow {
                $arrow-icon-size: 62px; // size based on icon + rotate deg
                position: relative;
                font-family: $family-special;
                margin-top: 55px;
                margin-left: calc(#{$arrow-icon-size} + 13%);
                color: $color-accent-dark;
                user-select: none;

                @media #{mq(nth($hero-mq, 1))} {
                    margin-top: 35px;
                    margin-left: calc(#{$arrow-icon-size} + 36.5%);
                }

                @media #{mq(nth($hero-mq, 2))} {
                    margin-left: calc(#{$arrow-icon-size} + 52%);
                    font-size: 1.125em;
                }

                @media #{mq(nth($hero-mq, 3))} {
                    margin-left: calc(#{$arrow-icon-size} + 40.5%);
                }

                @media #{mq(nth($hero-mq, 4))} {
                    margin-left: calc(#{$arrow-icon-size} + 38%);
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 10px;
                    right: 100%;
                    width: 45px;
                    height: 45px;
                    background: transparent svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="75" height="39"><g fill="none" fill-rule="evenodd"><path d="M-384-2149h1441v4149H-384z"/><path fill="#EF7D28" d="M5.398 37.998a4.95 4.95 0 0 1-3.2.029C1.18 37.7.604 36.764.844 35.737c.124-.528.257-1.072.502-1.547 1.02-1.973 1.402-4.114 1.747-6.273.74-4.63 1.474-9.261 2.248-13.886.269-1.612.6-3.216.981-4.804.342-1.423 1.233-1.912 2.7-1.581.313.07.622.206.901.367.503.288.832.738.777 1.33-.059.65-.22 1.288-.33 1.932a16348.15 16348.15 0 0 0-3.21 18.7c-.055.318-.05.65-.057.976-.002.105.028.287.083.303.19.058.46.153.587.066 1.522-1.039 3.046-2.078 4.526-3.177 3.314-2.46 6.622-4.929 9.891-7.45 1.202-.926 2.36-.928 3.627-.242.785.425.981 1.319.37 1.893-.473.446-.966.875-1.485 1.267-3.814 2.878-7.647 5.732-11.452 8.623-1.642 1.247-3.217 2.585-4.849 3.846-.94.73-1.831 1.55-3.004 1.918zM34.86 2.808C39.532 1.24 44.341.47 49.267.364c5.77-.122 11.525.129 17.26.74 1.94.207 3.857.667 5.768 1.087.5.109.974.452 1.393.774.367.282.436.739.292 1.19-.153.483-.557.683-.998.673-.538-.013-1.075-.13-1.61-.228-1.39-.252-2.77-.607-4.171-.765-6.385-.717-12.791-1.087-19.208-.69-8.84.545-16.702 3.692-23.617 9.199-1.442 1.15-2.766 2.45-4.14 3.685a21.639 21.639 0 0 0-4.121 5.038c-.335.56-.653 1.136-1.027 1.67-.658.935-1.724 1.156-2.91.646-.799-.342-1.152-1.13-.786-1.864.879-1.76 1.855-3.481 3.198-4.917 4.181-4.473 8.742-8.474 14.292-11.2 1.953-.96 3.889-1.952 5.98-2.594z"/></g></svg>') center/contain no-repeat;
                    transform: rotate(30deg) scaleY(-1);
                }
            }
        }

        &.hide-description-mobile {
            @media #{mq(nth($hero-mq, 1), max)} {
                .description {
                    font-size: getSize(h4);
                }
            }
        }
    }

    &.style-cta {
        @extend %style-banner;
        height: 125px; // 1
        min-height: 125px;
        margin-top: 20px;
        margin-bottom: 20px;
        background-position: bottom center;
        font-size: 1.7rem;

        @media #{mq(nth($hero-mq, 1))} {
            height: 145px; // 1
            min-height: 145px;
            background-position: center;
            font-size: 2rem;
            line-height: 1.08;
        }

        @media #{mq(nth($hero-mq, 3))} {
            height: 175px; // 1
            min-height: 175px;
            margin-top: 50px;
            margin-bottom: 50px;
            font-size: 2.6rem;
        }

        .title {
            font-size: 1em;
            line-height: inherit;
            font-weight: 700;
            text-align: center;
        }

        .content {
            max-width: 18em;
            padding: 10px;

            @media #{mq(nth($hero-mq, 2))} {
                padding: 20px;
            }
        }
    }

    &.style-banner {
        @extend %style-banner;
        justify-content: flex-start;
        align-items: flex-end;
        height: 306px; // 1
        min-height: 306px;
        margin-bottom: 20px;
        padding: 25px 20px;
        font-size: 1.4rem;

        @media #{mq(nth($hero-mq, 3))} {
            min-height: 503px;
            margin-bottom: 2rem;
            padding: 40px;
            font-size: 1.6rem;
        }

        .cell {
            display: flex;
            flex-direction: column;
            width: 100%; // 1
            max-width: 460px;
        }

        .title,
        .subtitle {
            font-weight: 700;
        }

        .title {
            margin-top: 0;
            margin-bottom: 15px;
            font-size: 1.357em;
            line-height: 1.08;

            @media #{mq(nth($hero-mq, 1))} {
                font-size: 1.43em;
            }

            @media #{mq(nth($hero-mq, 3))} {
                margin-bottom: 30px;
                font-size: 1.6em;
            }
        }

        .subtitle {
            display: block;
            margin-top: 10px;
            margin-bottom: 3px;

            @media #{mq(nth($hero-mq, 2))} {
                margin-top: 0;
            }

            :last-child {
                margin-bottom: 0;

                @media #{mq(nth($hero-mq, 3))} {
                    margin-bottom: 3px;
                }
            }
        }

        &.layout-wide {
            align-items: stretch;
            height: 400px; // 1
            min-height: 400px;
            padding: 15px;

            @media #{mq(nth($hero-mq, 1))} {
                height: 365px; // 1
                min-height: 365px;
            }

            @media #{mq(nth($hero-mq, 2))} {
                align-items: flex-end;
                padding-top: 40px;
                padding-bottom: 40px;
            }

            @media #{mq(nth($hero-mq, 3))} {
                height: 425px; // 1
                min-height: 425px;
                padding-top: 60px;
                padding-bottom: 60px;
            }

            .cell {
                display: flex;
                flex-direction: column;
                max-width: 370px;

                @media #{mq(nth($hero-mq, 1)) 'and' mq(nth($hero-mq, 2), max)} {
                    max-width: 500px;
                }

                @media #{mq(nth($hero-mq, 3))} {
                    max-width: 420px;
                }

                .actions {
                    margin-top: auto;

                    @media #{mq(nth($hero-mq, 2))} {
                        margin-top: 0;
                    }
                }
            }

            .title {
                font-size: 1.215em;
                line-height: 1.24;

                @media #{mq(nth($hero-mq, 1))} {
                    font-size: 1.43em;
                    line-height: 1.08;
                }

                @media #{mq(nth($hero-mq, 3))} {
                    margin-bottom: 30px;
                    font-size: 1.6em;
                }
            }

            .actions {
                display: flex;
                flex-direction: column;
                align-self: center;
                max-width: 175px;

                @media #{mq(nth($hero-mq, 1))} {
                    flex-direction: row;
                    align-self: auto;
                    max-width: none;
                }

                .button:not(:last-child) {
                    margin-bottom: 10px;

                    @media #{mq(nth($hero-mq, 1))} {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }

                    @media #{mq(nth($hero-mq, 3))} {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
