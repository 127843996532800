.header-logo {
    position: relative;
    align-self: center;
    margin-right: auto;
    padding-right: 1rem;

    @media #{mq(xl)} {
        padding-right: 2rem;
    }

    .nav-toggle {
        position: static;
        display: inline-block;
        margin-left: 1.4rem;
        color: $color-theme;
        text-align: center;
        vertical-align: middle;

        @include min-screen($screen__xls) {
            display: none;
        }

        &::before {
            @include fa-icon();
            content: $fa-var-bars;
            position: relative;
            top: .1rem;
            font-size: 1.9rem;
            color: $color-theme;
            @media #{mq(sm)} {
                font-size: 1.5rem;
                margin-right: 1rem;
            }
        }
        > div {
            display: block;
            font-size: 1rem;
            @media #{mq(sm)} {
                display: inline;
                font-size: 1.7rem;
                font-weight: 600;
                vertical-align: middle;
            }
        }
    }
    .logo {
        display: inline-block;
        width: 100%;
        max-width: 3.7rem;
        margin: 0 auto;
        vertical-align: middle;
        img {
            display: block;
            max-width: 100%;
        }
        @media #{mq(sm)} {
            max-width: 6rem;
        }
    }
}
