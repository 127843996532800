.tabs.style-card {
    border: 0;
    background-color: transparent;
    .nav-tabs {
        display: flex;
    }
    .nav-item {
        flex: 1 1 auto;
        text-align: center;
        > a {
            padding-top: 1.9rem;
            padding-bottom: 1.9rem;
            border-radius: 15px 15px 0 0;
            border: 0;
            background-color: getColor(gray, 300);
            color: getColor(gray, 500);
            font-weight: 700;
            &:focus,
            &[aria-selected='true'] {
                background-color: $color-on-theme;
                color: $color-theme;
            }
        }
    }
    .tab-content > .tab-pane {
        padding: 1rem;
        border-radius: 0 0 15px 15px;
        border: 0;
        background-color: $color-on-theme;
    }
}

.tooltip {
    font-size: 1.2rem;
    &-inner {
        box-sizing: border-box;
        max-width: 300px;
        border-radius: 7px;
        padding: 1.8rem 2.3rem;
        background-color: $color-theme;
        color: $color-on-theme;
        text-align: left;
        text-align: initial;
        a {
            color: inherit;
            text-decoration: underline;
            &:hover,
            &:focus {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    &.show {
        opacity: 1;
    }
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: $color-theme;
}
