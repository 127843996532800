// Works both as wrapper and directly on input
// Only the type modifier needs this style as an wrapper for the input field

$large-qty-font-size: 2rem !default;
$large-qty-font-weight: 700 !default;
$large-qty-line-height: 40px !default; // = 60 with border and padding
$large-qty-padding: map-get($input, padding) !default;
$large-qty-padding-y: nth($large-qty-padding, 1);
$large-qty-padding-x: if(length($large-qty-padding) > 1, nth($large-qty-padding, 2), $large-qty-padding);
$large-qty-icon-padding: ($large-qty-padding-x * 2) !default;
$large-qty-icon-offset: $large-qty-padding-x !default;

.input-large {
    > input,
    > input:focus,
    input::placeholder {
        font-size: $large-qty-font-size;
        font-weight: $large-qty-font-weight;
        line-height: $large-qty-line-height;
    }

    > input,
    > input:focus {
        width: 100%;
        max-width: 120px;
        height: auto;
        padding: $large-qty-padding;
        border-radius: 13px;

        &[type="number"] {
            -moz-appearance: number-input;

            &::-webkit-inner-spin-button {
                appearance: inner-spin-button;
            }
        }
    }

    &.type-euro {
        @extend %input-type-currency;

        &::before {
            content: "€";
        }
    }
}

%input-type-currency {
    position: relative;

    > input {
        padding-left: $large-qty-icon-padding;
    }

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: $large-qty-icon-offset;
        padding: $large-qty-padding-y 0;
        color: getColor(text, muted);
        font-size: $large-qty-font-size;
        font-weight: $large-qty-font-weight;
        line-height: 42px;
    }
}
