.order-information {
    padding: 1.2rem 1.5rem;
    text-align: right;

    &-title {
        color: $color-secondary-light;
        font-weight: 600;
        margin-bottom: 0;

        @media (min-width:991px) {
            display: inline-flex;
        }

        @media (max-width:1024px) {
            font-size: 1.4rem;
            text-align: center;
        }
    }

    &-icon {
        cursor: pointer;

        @media (min-width:991px) {
            margin-left: .5rem;
            display: flex;
            align-items: flex-start;
        }

        img {
            width: 1.8rem;

            @media (min-width:991px) {
                height: 2.6rem;
            }
        }
    }

    &-tooltip {
        position: relative;
        transition: .3s;
        z-index: 1;

        &[aria-visible="false"] {
            opacity: 0;
            pointer-events: none;
        }

        .tooltip {
            position: absolute;
            right: 0;
            top: 0;

            &-inner {
                padding: 1rem;
                max-width: 40rem;
            }

            p {
                margin-bottom: 0;
            }

            .arrow {
                top: 0.1rem;
                right: 1rem;
                transform: rotate(180deg);
            }
        }
    }
}
