// Makes sure there is no delay on touch devices for clickable elements
a,
area,
button,
summary,
input,
select,
textarea,
label {
    touch-action: manipulation;
}
