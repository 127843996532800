.header-navigation {
    align-self: center;

    @include min-screen($screen__xls) {
        padding-right: 3rem;
    }

    @media #{mq(md)} {
        padding-right: 1rem;
    }
    @media #{mq(lg)} {
        padding-right: 3rem;
    }
    .navigation {
        a {
            color: $color-theme;
            &,
            &:focus,
            &:hover {
                // Make sure no underline is used
                text-decoration: none;
            }
            @media #{mq(lg)} {
                white-space: nowrap;
            }
        }
        > ul {
            margin: 0;
            padding: 0;
            @media #{mq(lg)} {
                display: flex;
            }
            > li {
                position: static;
                flex: 0 1 auto;
                margin: 0;
                padding-right: 0;
                &.parent > a {
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        top: calc(50% - #{(18px / 2)});
                        right: 7px;
                        display: inline-block;
                        width: 1em;
                        height: 1em;
                        background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z" fill="#{$color-theme}"/></svg>') center / 125% no-repeat;
                        @media #{mq(lg)} {
                            position: relative;
                            top: 3px;
                            right: auto;
                            margin-left: 3px;
                            line-height: 1;
                        }
                    }
                    &.ui-state-active::after {
                        transform: scaleY(-1);
                    }
                }
                &.menu-mobile-only {
                    @media #{mq(lg)} {
                        display: none;
                    }
                }
                > a,
                > a:visited {
                    font-size: getSize(p);
                    font-weight: 600;
                    @media #{mq(xl)} {
                        font-size: 1.7rem;
                    }
                }
            }
        }
        .level0 {
            &.submenu {
                @media #{mq(lg)} {
                    position: absolute;
                    display: none;
                    margin-top: 1.4rem;
                    padding: 2rem;
                }
                > li:last-child {
                    margin: 0;
                }
            }
            > .level-top {
                text-transform: none;
            }
        }
        .submenu {
            background-color: #fff;
        }
    }
}
