// Based on pagination from Fylgja v0.2
// And some styles from Bootstrap v4.1
// To meat the vue templates needs

$pagination-styles: (
    flow: row wrap,
    margin: 8px 0,
    padding: 0,
    border-style: 2px solid,
    radius: 50px,
    bg: transparent,
    bg-focus: rgba(black, .1),
    bg-active: null,
    stroke: transparent,
    stroke-focus: null,
    stroke-active: getColor(theme, base),
    color: $color-text,
    color-focus: $color-text,
    color-active: getColor(text, dark),
) !default;

.pagination {
    display: flex;
    align-items: center;
    flex-flow: map-get($pagination-styles, flow);
    box-sizing: border-box;
    margin: map-get($pagination-styles, margin);
    padding: map-get($pagination-styles, padding);
    list-style-type: none;

    li {
        box-sizing: inherit;
        margin-bottom: 0;

        &:first-child span,
        &:first-child a,
        &:last-child span,
        &:last-child a {

            font-weight: bold;
            padding: 0 0.5rem;
        }

        &.active a {
            font-weight: 700;
            color: map-get($pagination-styles, color-active);
            border-color: map-get($pagination-styles, stroke-active);
            background-color: map-get($pagination-styles, bg-active);
        }

        &.disabled {
            cursor: not-allowed;
            opacity: .6;
        }

        &[role="separator"] {
            display: none;
        }
    }

    a {
        &:hover {
            text-decoration: none;
            color: map-get($pagination-styles, color-focus);
            border-color: map-get($pagination-styles, stroke-focus);
            outline: none;
            background-color: map-get($pagination-styles, bg-focus);
        }

        &[aria-current="page"] {
            color: map-get($pagination-styles, color-active);
            border-color: map-get($pagination-styles, stroke-active);
            background-color: map-get($pagination-styles, bg-active);
        }
    }

    a,
    .page-link {
        font-size: 1.2rem;
        display: inline-block;
        box-sizing: inherit;
        min-width: 30px;
        height: 30px;
        user-select: none;
        text-align: center;
        text-decoration: none;
        color: map-get($pagination-styles, color);
        border: map-get($pagination-styles, border-style) map-get($pagination-styles, stroke);
        border-radius: map-get($pagination-styles, radius);
        background-color: map-get($pagination-styles, bg);
    }

    b {
        color: $color-theme;
    }
}
