// Fylgja v0.2.4 (getfylgja.com)
// Licensed under MIT Open Source

$root-color: black !default;
$item-spacer: 10px !default;
$color-text: if(function-exists(color-text), color-text($root-color), rgba(black, .87)) !default;
$color-text-secondary: if(function-exists(color-text), color-text($root-color, secondary), rgba(black, .6)) !default;
$color-text-muted: if(function-exists(color-text), color-text($root-color, muted), rgba(black, .38)) !default;
$color-text-disabled: if(function-exists(color-text), color-text($root-color, disabled), rgba(black, .38)) !default;
$color-theme: #2196F3 !default;

$form-display: block !default; // Its better to use block and use a wrapper for layouting
$form-min-height: 2rem !default;
$form-color: $color-text !default;
$form-focus-color: $color-theme !default;
$form-caret-color: $form-focus-color !default;
$form-placeholder-color: $color-text-muted !default;

$form-transition-property: border-color, box-shadow !default;
$form-transition-speed: 150ms !default;
$form-transition-effect: linear !default;

$label-margin: 0;
$fieldset-margin: 0 0 $item-spacer !default;
$fieldset-padding: 0 !default;
$fieldset-border: 0 !default;

$form-style: default !default;
@if ($form-style != default)
and ($form-style != field)
and ($form-style != box) {
    @error '#{$form-style} is not part of the base form-styles, (options: field, fieldbox, box)';
}

// Style builds
$form-styles: (
    field: (
        padding: .375rem 0,
        border-width: 0 0 1px,
        border-style: solid,
        border-color: currentColor,
        radius: 0,
        shadow: none,
        focus: inset 0 -1px 0 0 $form-focus-color,
        bg: transparent,
        color: inherit,
    ),
    box: (
        padding: .375rem .625rem,
        border-width: 0 0 1px,
        border-style: solid,
        border-color: currentColor,
        radius: 5px 5px 0 0,
        shadow: none,
        focus: inset 0 -1px 0 0 $form-focus-color,
        bg: rgba($root-color, .03),
        color: $color-text,
    ),
    default: (
        padding: .375rem .625rem,
        border-width: 1px,
        border-style: solid,
        border-color: currentColor,
        radius: 3px,
        shadow: none,
        focus: inset 0 0 0 1px $form-focus-color,
        bg: transparent,
        color: $color-text,
    )
);

// Set styles var's
$form-padding: map-get(map-get($form-styles, $form-style), padding) !default;
$form-border-width: map-get(map-get($form-styles, $form-style), border-width) !default;
$form-border-style: map-get(map-get($form-styles, $form-style), border-style) !default;
$form-border-color: map-get(map-get($form-styles, $form-style), border-color) !default;
$form-border-radius: map-get(map-get($form-styles, $form-style), radius) !default;
$form-shadow: map-get(map-get($form-styles, $form-style), shadow) !default;
$form-focus: map-get(map-get($form-styles, $form-style), focus) !default;
$form-color-bg: map-get(map-get($form-styles, $form-style), bg) !default;
$form-color: map-get(map-get($form-styles, $form-style), color) !default;

// Form fields to load the styling on by default
$form-inputs: (
    text,
    number,
    email,
    tel,
    password,
    search,
    url,
    date,
    month,
    week,
    time,
    // file,
    // color,
    textarea,
    select
) !default;

// Form select arrow
$icon-arrow-select: null;
$icon-arrow-select-color: $color-text-secondary !default;
@if function-exists(svg-url) {
    $icon-arrow-select: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#{$icon-arrow-select-color}" d="M7 10l5 5 5-5z"/><path fill="none" d="M0 0h24v24H0z"/></svg>') !default;
} @else {
    $icon-arrow-select: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(0, 0, 0, .54)' d='M7 10l5 5 5-5z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E") !default;
}

// set reset if there is no Fylgja Body
@if not variable-exists('fylgja-body') {
    input,
    select,
    textarea,
    button,
    label,
    legend {
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
    }
    // Makes sure there is no delay on touch devices for clickable elements
    button,
    input,
    select,
    textarea,
    label {
        touch-action: manipulation;
    }
}
