.card {
    &,
    &-media,
    &-content,
    &-action {
        box-sizing: border-box;
    }
}

//Page Builder gradient overlay for columns and rows
[gradient-overlay="true"] {
    position: relative;
    z-index: 1;

    > * {
        z-index: 3;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: $overlay-gradient;
        border-radius: inherit;
        z-index: 2;
    }
}

[data-content-type="featured_item"] {
    transition: $transition-base;

    &:hover {
        box-shadow: getShadow(theme);
        transform: translate3d(0, -1rem, 0);
    }
}
