// Fylgja
// getfylgja.com
// Licensed under MIT Open Source
// v0.2.0

@mixin flex-gap-size($values) {
    $x: nth($values, 1);
    $y: if(length($values) == 2, nth($values, 2), null);
    margin-right: -($x / 2);
    margin-left: -($x / 2);
    > .cell {
        padding-right: ($x / 2);
        padding-left: ($x / 2);
        @if $y != null {
            margin-bottom: $y;
        }
    }
}

@mixin build-flex-gaps($mq: true, $map-gaps...) {
    .flex-grid.gap {
        @if $mq == true {
            @include build-breakpoints {
                @each $gap in $map-gaps {
                    &-#{$gap} {
                        @include flex-gap-size($gap);
                    }
                }
            }
        }
        @if $mq == false {
            @each $gap in $map-gaps {
                &-#{$gap} {
                    @include flex-gap-size($gap);
                }
            }
        }
    }
}

@function grid-item-size($size) {
    @return ($size * 100% / 12);
}
